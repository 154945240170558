import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { AutoComplete, Button, Datepicker } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelSearchPassengerCount } from "../hotel-search-passenger-count";
import {
  useDate,
  useDestination,
  useHistory,
  usePassengerCount,
  useSubmit,
  useValidation
} from "./hooks";
import { HotelSearchPanelPropertiesType } from "./hotel-search-panel.types";

const Component: FC<HotelSearchPanelPropertiesType> = (properties) => {
  const {
    size,
    searchType,
    initialCityData,
    onClose,
    datePickerInitialValues,
    handleSearchSubmit,
    searchSuggestion,
    searchAutocompleteFetcher,
    validCount
  } = properties;
  const {
    onSubmitPassengerCount,
    passengerCount,
    passengerCountList,
    passengerCountTitle,
    passengerCountResult,
    temporaryPassengerCount,
    onChangeAdult,
    onChangeChild,
    onChangeChildAge,
    onAddRoom,
    onRemoveRoom
  } = usePassengerCount(validCount);

  const {
    selectedDestinationItem,
    selectedDestinationText,
    destinationQuery,
    onChangeDestination,
    onChangeDestinationQuery,
    onSelectDestination,
    destinationLoading,
    destinationListNormalized,
    DestinationNoResult
  } = useDestination({
    cityTitleInitialValues: initialCityData?.title,
    searchSuggestion,
    searchAutocompleteFetcher
  });
  const { date, onChangeDate } = useDate(datePickerInitialValues);
  const { historyListNormalized, addToHistory, onSelectHistory } =
    useHistory(onChangeDestination);
  const { destinationError, dateError, submitValidationCheck } = useValidation({
    selectedDestinationItem,
    date,
    initialDestinationItem: initialCityData,
    searchType
  });
  const { onSubmitClick, submitLoading } = useSubmit(
    selectedDestinationItem,
    date,
    passengerCount,
    addToHistory,
    submitValidationCheck,
    handleSearchSubmit,
    initialCityData
  );
  const { t } = useTranslation();

  return (
    <div
      className={`${
        searchType === "hotel" ? "gap-8" : "gap-6"
      } relative mb-4 flex h-full flex-col lg:mb-0 lg:h-auto`}
    >
      <h3 className="text-headline-6 lg:hidden">
        {searchType === "hotel"
          ? t("search.period_and_passengers_determination")
          : t("global.hotel_state")}
      </h3>
      {(searchType === "city" || searchType === "category") && (
        <div className="flex h-8 flex-row justify-between gap-2">
          <HotelSearchPassengerCount
            list={passengerCountList}
            onAddRoom={onAddRoom}
            onChangeAdult={onChangeAdult}
            onChangeChild={onChangeChild}
            onChangeChildAge={onChangeChildAge}
            onRemoveRoom={onRemoveRoom}
            onSubmit={onSubmitPassengerCount}
            passengerCount={temporaryPassengerCount}
            result={passengerCountResult}
            title={passengerCountTitle}
            validCount={validCount}
          />
        </div>
      )}
      <div
        className={`flex flex-col gap-4 xl:flex-row ${
          searchType === "hotel" ? "w-full" : ""
        }`}
      >
        <div
          className={`flex flex-col gap-4 lg:flex-row ${
            searchType === "city" ? "flex-1" : "w-full lg:items-center"
          }`}
        >
          {searchType !== "hotel" && searchType !== "category" && (
            <div className="flex-[3]">
              <AutoComplete
                error={destinationError}
                fullWidth
                parts={[
                  {
                    label: t("search.city_or_hotel_destination"),
                    list: DestinationNoResult ? [] : destinationListNormalized,
                    historyList:
                      !DestinationNoResult && !destinationLoading
                        ? historyListNormalized
                        : [],
                    historyTitle: t("search.recent_searches"),
                    text: selectedDestinationText || "",
                    onSelect: onSelectDestination,
                    onSelectHistory,
                    onChangeQuery: onChangeDestinationQuery,
                    query: destinationQuery,
                    loading: destinationLoading,
                    NoResult: DestinationNoResult
                  }
                ]}
                size={size}
              />
            </div>
          )}
          <div
            className={`${
              searchType === "hotel" ? "flex-auto lg:max-w-[312px]" : "flex-[2]"
            }`}
          >
            <Datepicker
              error={dateError.length > 0}
              fromTitle={t("search.checkin_date")}
              fullWidth
              gapHorizontal={searchType === "hotel" ? "0px" : "-16px"}
              gapVertical={searchType === "hotel" ? "42px" : "-16px"}
              helperText={dateError}
              mode="range"
              onChange={onChangeDate}
              position={searchType === "hotel" ? "start" : "end"}
              size={searchType === "hotel" ? "dense" : "default"}
              toTitle={t("search.checkout_date")}
              value={date ?? []}
            />
          </div>
          {searchType === "hotel" && (
            <div className="flex-auto lg:max-w-[288px]">
              <HotelSearchPassengerCount
                list={passengerCountList}
                onAddRoom={onAddRoom}
                onChangeAdult={onChangeAdult}
                onChangeChild={onChangeChild}
                onChangeChildAge={onChangeChildAge}
                onRemoveRoom={onRemoveRoom}
                onSubmit={onSubmitPassengerCount}
                passengerCount={temporaryPassengerCount}
                result={passengerCountResult}
                searchType={searchType}
                size={searchType === "hotel" ? "dense" : "default"}
                title={passengerCountTitle}
                validCount={validCount}
              />
            </div>
          )}
          <div className="absolute bottom-0 w-full lg:relative lg:w-[134px]">
            <Button
              color={searchType === "hotel" ? "secondary" : "primary"}
              fullWidth
              loading={submitLoading}
              onClick={onSubmitClick}
              size={searchType === "hotel" ? "medium" : "x-large"}
            >
              {t("search.search_update")}
            </Button>
          </div>
          <div
            className={`absolute left-0 top-0 mr-auto hidden text-center lg:block ltr:ml-auto ${
              searchType === "hotel" ? "xl:mt-0" : ""
            }`}
          >
            <Button
              StartIcon={<MaterialIcon name="close" />}
              color="neutral"
              onClick={onClose}
              size="small"
              variant="text"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const HotelSearchPanel = memo(Component, deepCompare);
