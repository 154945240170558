import { applicationStore } from "@website/store";
import { USER_TRACKING_EVENTS } from "@website/tracking";
import { FormEvent, useCallback, useEffect } from "react";
import { HotelDraftPrepaymentGatewaysPropertiesType } from "./hotel-draft-prepayment-gateways.types";

export const useHotelDraftPrepaymentGateways = (
  properties: HotelDraftPrepaymentGatewaysPropertiesType
) => {
  const { value, onChange, onSnappPayUserTrack } = properties;

  useEffect(() => {
    if (value === "snapppay") {
      onSnappPayUserTrack?.(USER_TRACKING_EVENTS.SNAPPPAY_SELECTED);
    }
    applicationStore.bankName.set(value);
  }, [onSnappPayUserTrack, value]);

  const radioOnChange = useCallback(
    (event_: FormEvent<HTMLInputElement>) => {
      onChange(event_.currentTarget.value);
      applicationStore.bankName.set(event_.currentTarget.value);
    },
    [onChange]
  );

  return {
    checkedRadio: value,
    radioOnChange
  };
};
