import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { VillaSearchSummaryContent } from "./villa-search-summary-content/villa-search-summary-content";
import { useVillaSearchSummary } from "./villa-search-summary.hook";
import { VillaSearchSummaryPropertiesType } from "./villa-search-summary.types";
const Component: FC<VillaSearchSummaryPropertiesType> = (properties) => {
  const viewProperties = useVillaSearchSummary(properties);
  return <VillaSearchSummaryContent {...viewProperties} />;
};

export const VillaSearchSummary = memo(Component, deepCompare);
