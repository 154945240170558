import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import {
  Button,
  CounterButton,
  MiniChips,
  Modal,
  RadioGroup
} from "@website/ui";
import { deepCompare, numberConvert, numberSeparator } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { useHotelReserveRacks } from "./hotel-reserve-card-racks.hooks";
import { HotelReserveCardRacksPropertiesTypes } from "./hotel-reserve-card-racks.types";

const Component = (properties: HotelReserveCardRacksPropertiesTypes) => {
  const { title, open, onClose } = properties;
  const router = useRouter();
  const {
    items,
    onChange,
    packageSelect,
    roomCount,
    setRoomCount,
    onHandleClick,
    loading
  } = useHotelReserveRacks(properties);

  const { t } = useTranslation();

  return (
    <Modal className="p-0" onClose={onClose} open={open}>
      <div className="flex h-full flex-col gap-4 pt-8">
        <div className="text-subtitle-1 flex flex-row justify-between px-8">
          <div>{t("hotel.room.special_package")}</div>
          <MaterialIcon
            className="cursor-pointer"
            name="close"
            onClick={onClose}
          />
        </div>
        <div className="px-8 pb-6">
          <div className="text-on-surface-medium-emphasis text-caption pb-4">
            {title}
          </div>
          <div className="text-body-2">{t("hotel.room.package_text")}</div>
          <RadioGroup
            items={items}
            name="packages"
            onChange={onChange}
            vertical
          />
        </div>
        {packageSelect && (
          <>
            <div className="bg-outline-thin block h-px w-full" />
            <div className="flex h-full flex-col justify-between px-8 pb-8 lg:gap-4">
              <div className="text-subtitle-1 flex flex-row items-center justify-between px-4">
                <div>
                  {Boolean(packageSelect?.price - packageSelect?.priceOff) && (
                    <div className="flex flex-row items-center gap-2">
                      <span className="text-body-2 text-on-surface-medium-emphasis line-through">
                        {numberConvert(numberSeparator(packageSelect?.price), {
                          locale: router.locale
                        })}
                      </span>
                      <MiniChips
                        color="primary"
                        text={`${numberConvert(
                          (
                            ((packageSelect?.price - packageSelect?.priceOff) /
                              packageSelect?.price) *
                            100
                          ).toFixed(0),
                          {
                            locale: router.locale
                          }
                        )}%`}
                        variant="contained"
                      />
                    </div>
                  )}
                  {numberConvert(
                    numberSeparator(
                      packageSelect?.price - packageSelect?.priceOff
                        ? packageSelect?.priceOff
                        : packageSelect?.price
                    ),
                    {
                      locale: router.locale
                    }
                  )}
                  <span className="text-caption text-on-surface-medium-emphasis px-1">
                    {t("hotel.room.currency_for_night", {
                      count: numberConvert(packageSelect.nightCount, {
                        locale: router.locale
                      })
                    })}
                  </span>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <div className="text-caption">{t("global.rooms_count")}</div>
                  <CounterButton
                    className="gap-0"
                    color="secondary"
                    decreaseDisabled={roomCount <= 1}
                    increaseDisabled={roomCount >= packageSelect?.availableRoom}
                    locale="fa"
                    onChange={setRoomCount}
                    size="small"
                    value={roomCount}
                    variant="outline"
                  />
                </div>
              </div>
              <Button loading={loading} onClick={onHandleClick}>
                {t("hotel.room.reserve_room", {
                  count: numberConvert(roomCount, {
                    locale: router.locale
                  })
                })}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
export const HotelReserveCardRacks = memo(Component, deepCompare);
