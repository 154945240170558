import { Datepicker } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useRoomCalendar } from "./hotel-room-calendar.hook";
import { HotelRoomCalendarPropertiesType } from "./hotel-room-calendar.types";

const Component: FC<HotelRoomCalendarPropertiesType> = (properties) => {
  const { selectedDate, calnedarData, disableLimit, onCalendarChange } =
    useRoomCalendar(properties);
  const { view = "default" } = properties;
  return (
    <div className="md:p-3" id="hotel_calendar">
      <Datepicker
        calendarData={calnedarData}
        disableLimit={disableLimit}
        mode="range"
        onChange={onCalendarChange}
        type="flat"
        value={selectedDate}
        view={view}
      />
    </div>
  );
};

export const HotelRoomCalendar = memo(Component, deepCompare);
