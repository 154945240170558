import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { LazyImage, MiniChips } from "@website/ui-old";
import { dateFormat, numberConvert } from "@website/utils";
import { inRange } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useMemo } from "react";
import styles from "./hotel-last-second-item.module.scss";
import { HotelLastSecondPropertiesType } from "./hotel-last-second-item.types";

export const HotelLastSecondItem: FC<HotelLastSecondPropertiesType> = (
  properties
) => {
  const {
    cover_media,
    title,
    hotel,
    price,
    price_off,
    showMoreItem,
    adults,
    breakfast_included,
    cityUrl,
    available_rooms
  } = properties;
  const { t, locale } = useTranslation();
  const finalPrice = price_off < price ? price_off : price;
  const percentOff = ((price - price_off) * 100) / price;
  const router = useRouter();

  const date = new Date();

  const dateFrom = dateFormat(date, {
    locale: "en",
    template: "yyyy-MM-dd"
  });

  const dateTo = dateFormat(date.setDate(date.getDate() + 1), {
    locale: "en",
    template: "yyyy-MM-dd"
  });

  const date_today = new Date();
  const data_tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

  const today = `${date_today?.getFullYear()}-${(date_today?.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date_today?.getDate()?.toString()?.padStart(2, "0")}`;
  const tomorrow = `${data_tomorrow?.getFullYear()}-${(
    data_tomorrow?.getMonth() + 1
  )
    ?.toString()
    ?.padStart(2, "0")}-${data_tomorrow
    ?.getDate()
    ?.toString()
    ?.padStart(2, "0")}`;

  const linkToCityPage = useMemo(
    () => ({
      pathname: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/رزرو-هتل/${cityUrl}`,
      query: {
        ...router.query,
        date_from: today,
        date_to: tomorrow,
        promotion: "last-minute",
        utm_internal_source: "last_second_home_page"
      }
    }),
    [cityUrl, router.query, today, tomorrow]
  );

  const linkToHotelPage = useMemo(
    () => ({
      pathname: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/رزرو-هتل/${cityUrl}/${hotel.fa_url}`,
      query: {
        ...router.query,
        date_from: dateFrom,
        date_to: router.query.date_to || dateTo,
        promotion: "last-minute",
        utm_internal_source: "last_second_home_page"
      }
    }),
    [cityUrl, dateFrom, dateTo, hotel.fa_url, router.query]
  );

  return (
    <section className={styles["container"]}>
      <article className={styles["box"]}>
        {showMoreItem ? (
          <Link className={styles["show-more"]} href={linkToCityPage}>
            <figure className={styles["show-more-icon-wrapper"]}>
              <MaterialIcon className={styles["icon"]} name="chevron_left" />
            </figure>
            <p className={styles["show-more-text"]}>
              {t("homepage.hotel.last_second.view_more")}
            </p>
          </Link>
        ) : (
          <Link className={styles["card"]} href={linkToHotelPage}>
            <section>
              <figure className={styles["image-container"]}>
                <LazyImage
                  alt={
                    cover_media?.title ||
                    t("homepage.hotel.last_second.hotel_card.pictures")
                  }
                  className={styles["image"]}
                  fallback={cover_media?.media}
                  loading="eager"
                  src={cover_media?.media}
                  title={title}
                />
              </figure>
              <article className={styles["body"]}>
                <div className={styles["details"]}>
                  <h3 className={styles["hotel-title"]}>{hotel?.title}</h3>
                  {hotel?.stars !== 0 && (
                    <div className={styles["rates"]}>
                      <MiniChips
                        EndIcon={
                          <MaterialIcon
                            className={styles["mini-chip-icon"]}
                            name="stars"
                          />
                        }
                        color="success"
                        size="medium"
                        text={numberConvert(
                          t(
                            "homepage.hotel.last_second.hotel_card.star_count",
                            {
                              count: hotel?.stars
                            }
                          ),
                          {
                            locale
                          }
                        )}
                        textClassName={styles["mini-chip-text"]}
                        variant="accent"
                      />
                    </div>
                  )}
                </div>
                <h2 className={styles["main-title"]}>{title}</h2>
                <div className={styles["options"]}>
                  {Boolean(adults) && (
                    <MiniChips
                      StartIcon={
                        <MaterialIcon
                          className={styles["mini-chip-icon"]}
                          name="person"
                        />
                      }
                      size="medium"
                      text={t("homepage.hotel.last_second.adults_count", {
                        adults
                      })}
                      textClassName={styles["mini-chip-text"]}
                      variant="accent"
                    />
                  )}

                  {breakfast_included && (
                    <MiniChips
                      StartIcon={
                        <MaterialIcon
                          className={styles["mini-chip-icon"]}
                          name="local_cafe"
                        />
                      }
                      size="medium"
                      text={t(
                        "homepage.hotel.last_second.hotel_card.breakfast"
                      )}
                      textClassName={styles["mini-chip-text"]}
                      variant="accent"
                    />
                  )}
                </div>
                <div className={styles["title-wrapper"]}>
                  {inRange(available_rooms ?? 0, 1, 3) && (
                    <>
                      <MaterialIcon
                        className={styles["icon"]}
                        name="info"
                        variant="outlined"
                      />
                      <h2 className={styles["title"]}>
                        {t(
                          "homepage.hotel.last_second.only_left_number_of_rooms",
                          {
                            count: available_rooms
                          }
                        )}
                      </h2>
                    </>
                  )}
                </div>
              </article>
            </section>
            <section className={styles["footer"]}>
              <span className={styles["text"]}>
                {t("homepage.hotel.last_second.tonight_price")}
              </span>
              <div className={styles["price-wrapper"]}>
                {price_off < price ? (
                  <div className={styles["old-price-wrapper"]}>
                    <span className={styles["old-price"]}>
                      {numberConvert(Math.trunc(price || 0)?.toLocaleString(), {
                        locale
                      })}
                    </span>
                    <MiniChips
                      color="primary"
                      text={`٪${numberConvert(Math.trunc(percentOff || 0), {
                        locale
                      })}`}
                      textClassName={styles["mini-chip-text"]}
                      variant="contained"
                    />
                  </div>
                ) : (
                  ""
                )}
                <span className={styles["price"]}>
                  {numberConvert(Math.trunc(finalPrice || 0).toLocaleString(), {
                    locale
                  })}{" "}
                  {t("homepage.hotel.last_second.tooman_currency")}
                </span>
              </div>
            </section>
          </Link>
        )}
      </article>
    </section>
  );
};
