import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../../utils";
import { PopularCitiesResponseType } from "./types";

export const PopularCitiesUrl = `${
  process.env.NEXT_PUBLIC_BASE_URL_CDN || "https://cdnsnapptrip.com"
}/shared/top-cities/top-cities.json`;

export const PopularCitiesService = (): Promise<PopularCitiesResponseType> =>
  AxiosNoAuth.get(PopularCitiesUrl).then(
    ({ data }: AxiosResponse<PopularCitiesResponseType>) => data
  );
