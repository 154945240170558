import { useQuery } from "react-query";
import {
  MarketingBannerService,
  MarketingBannerUrl
} from "../../../services/fp/banners/banner/active";

export const useMarketingBannerService = () => {
  const { isError, isLoading, data } = useQuery(MarketingBannerUrl, () =>
    MarketingBannerService({
      channel: process.env["NEXT_PUBLIC_CHANNEL_TYPE"] || "web",
      product: "hotel-domestic",
      page: "homepage"
    })
  );

  return { marketingBanner: data, isError, isLoading };
};
