import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { toString } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { HotelSortViewPropertiesType } from "./hotel-sort-view.types";

export const useHotelSortView = (properties: HotelSortViewPropertiesType) => {
  const { t } = useTranslation();
  const { list, initialValue, onChange } = properties;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [result, setResult] = useState<
    | {
        label: string;
        value: string;
      }
    | undefined
  >();

  useEffect(() => {
    setResult(list.find((item) => item.value === (initialValue || "selling")));
  }, [initialValue, list]);

  const onClickChips = useCallback(() => {
    setOpen(true);
  }, []);
  const onClosePopover = useCallback(() => {
    setOpen(false);
  }, []);
  const onChangeRadio = useCallback(
    (response: any) => {
      const item = list.find(
        (element) => element.value === response.target.value
      );
      setIsDirty(true);
      setResult(item);
      onChange?.(item?.value);
      onClosePopover();
    },
    [onClosePopover, list, onChange]
  );

  const getLabelText = useCallback(
    () =>
      toString(
        !isDirty && !initialValue
          ? t("global.sort")
          : (!isDirty && initialValue) || isDirty
            ? result?.label
            : ""
      ),
    [isDirty, initialValue, result?.label, t]
  );

  return {
    buttonProps: {
      size: "small" as const,
      children: getLabelText(),
      variant: "round-outline" as const,
      StartIcon: <MaterialIcon className="text-sm" name="sort" />,
      color: "neutral" as const,
      onClick: onClickChips
    },
    popoverProps: {
      onClose: onClosePopover,
      open,
      title: t("global.sort"),
      defaultHorizontalPosition: "end" as const
    },
    radioProps: {
      list,
      checkedRadio: result?.value,
      onChangeRadio
    }
  };
};
