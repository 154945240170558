import { isFilled, numberConvert } from "@website/utils";
import { RatingDefaults } from "@website/widget";
import { clamp, pick } from "lodash";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { HotelCardPropertiesType } from "../../hotel-card.types";
import {
  HotelCardRatingPropertiesType,
  RatingResults
} from "./hotel-card-rating.types";

const ratingDefaults: RatingDefaults = [
  { minRate: 0, text: "", color: "error" },
  { minRate: 2, text: "", color: "warning" },
  { minRate: 3, text: "", color: "default" },
  { minRate: 3.5, text: "خوب", color: "default" },
  { minRate: 4, text: "خیلی خوب", color: "success" },
  { minRate: 4.5, text: "عالی", color: "success" }
];
const isNewLimit = 5;

export const getTextAndColorFromHotelRating = (
  rating: number,
  reviewCount: number,
  size: HotelCardPropertiesType["size"]
): RatingResults => {
  const clampedRate = clamp(rating, 0, 5);
  if (isFilled(reviewCount) && reviewCount < isNewLimit && size !== "xSmall") {
    return {
      clampedRate,
      text: "جدید",
      color: "secondary",
      isNew: true
    } as const;
  }
  const results = {
    clampedRate,
    isNew: false,
    ...pick(
      ratingDefaults.filter((item) => clampedRate >= item.minRate).pop() ||
        ratingDefaults[0],
      ["text", "color"]
    ),
    ...(size === "xSmall" ? { text: "" } : {})
  };
  return results;
};

export const useHotelCardRating = (
  properties: HotelCardRatingPropertiesType
) => {
  const router = useRouter();
  const { rating, reviewCount } = properties;
  const {
    shouldRender,
    color: badgeColor,
    text: badgeText
  } = useMemo(() => {
    const { clampedRate, isNew, color, text } = getTextAndColorFromHotelRating(
      rating as number,
      reviewCount as number,
      properties.size
    );
    return {
      shouldRender: clampedRate > 0,
      color,
      text: isNew
        ? text
        : `${numberConvert(clampedRate, {
            locale: router.locale
          })} ${text}`
    };
  }, [properties.size, rating, reviewCount, router.locale]);

  return {
    shouldRender,
    reviewCount,
    badgeColor,
    badgeText
  };
};
