import { useTranslation } from "@website/locale";
import { useCallback } from "react";

export const useVillaTranslation = () => {
  const { t } = useTranslation();

  const translateEntity = useCallback(
    (entity: string): string => {
      switch (entity) {
        case "STATE": {
          return t("global.state");
        }
        case "CITY": {
          return t("global.city");
        }
        case "VILLAGE": {
          return t("global.village");
        }
        case "AREA": {
          return t("global.area");
        }
        case "COUNTRY": {
          return t("global.country");
        }
        default: {
          return "";
        }
      }
    },
    [t]
  );

  return {
    translateEntity
  };
};
