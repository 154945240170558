import { useState } from "react";
import { SuggestionsPropertiesType } from "./suggestions.types";

export const useSuggestions = (properties: SuggestionsPropertiesType) => {
  const { list } = properties;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return {
    selectedTab,
    list: list.map((item, index) => ({
      ...item,
      onClick: () => setSelectedTab(index)
    }))
  };
};
