import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelAfterPaymentStatus } from "./hotel-after-payment-status";
import { HotelBookingVoucherPropertiesType } from "./hotel-booking-voucher.types";

const Component: FC<HotelBookingVoucherPropertiesType> = ({
  hotelAfterPaymentStatusProps
}) => (
  <div
    className="md:min-h-[calc(100vh-601px)]"
    hidden={!hotelAfterPaymentStatusProps?.paymentStatus}
  >
    <div className="relative flex min-h-[calc(100vh-64px)] w-full flex-col bg-white px-4 pb-[92px] pt-6 md:min-h-[unset] md:pb-[240px] lg:gap-6 lg:px-6 xl:mb-0 xl:px-10 2xl:mx-auto 2xl:w-[1200px] 2xl:px-0 rtl:xl:flex-row-reverse">
      <HotelAfterPaymentStatus {...hotelAfterPaymentStatusProps} />
    </div>
  </div>
);

export const HotelBookingVoucher = memo(Component, deepCompare);
