import { Modal, Transition } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelSearchWrapperViewPropertiesType } from "./hotel-search-wrapper.types";
import { useHotelSearchWrapperContent } from "./hotel-serach-wrapper.hook";

const HotelSearchWrapperComponent: FC<HotelSearchWrapperViewPropertiesType> = (
  properties
) => {
  const { SummaryElement, PanelElement, searchType } = properties;
  const {
    scrolledReference,
    isSearchPanelOpen,
    isSearchContainerScrolled,
    openSearchPanel,
    closeSearchPanel
  } = useHotelSearchWrapperContent(properties);

  return (
    <>
      {searchType !== "hotel" && (
        <Transition in={isSearchPanelOpen} timeout={200}>
          {(state) =>
            state === "exited" ? null : (
              <div
                className={`bg-on-surface-disable fixed left-0 top-[73px] z-10 box-border hidden h-screen w-full opacity-100 transition-all duration-200 ease-in-out lg:block ${
                  state === "exiting" ? "opacity-0" : ""
                }`}
                onClick={closeSearchPanel}
              />
            )
          }
        </Transition>
      )}
      <div ref={scrolledReference} />
      <div
        className={`sticky top-0 z-20 w-full transition-shadow duration-200 ease-in-out ${
          searchType === "hotel" ? "z-[1] h-[48px] lg:h-[40px]" : "lg:h-[72px]"
        } ${
          isSearchContainerScrolled && searchType !== "hotel"
            ? "shadow-4 sticky right-0 top-0"
            : ""
        }`}
      >
        <Transition in={isSearchPanelOpen} timeout={200}>
          {(state) => (
            <div
              className={`overflow-hidden bg-white transition-all duration-200 ease-in-out lg:absolute lg:top-0 lg:z-40 lg:w-full ${
                searchType === "hotel" ? "h-[48px] lg:h-[72px]" : "h-[72px]"
              } ${
                isSearchPanelOpen &&
                (searchType === "city" || searchType === "category")
                  ? "h-[236px] lg:h-[180px]"
                  : "flex w-full justify-center"
              } ${state === "entered" ? "overflow-visible" : ""} ${
                isSearchPanelOpen && searchType === "hotel"
                  ? "border-secondary shadow-6 h-[144px] rounded-xl border-2 xl:h-[104px]"
                  : ""
              } `}
            >
              <div
                className={`flex size-full items-center justify-center transition-all duration-200 ease-in-out ${
                  searchType === "hotel" ? "bg-dim-background" : "bg-white px-4"
                } ${
                  state !== "entering" && state !== "entered"
                    ? "translate-y-0 scale-100 opacity-100"
                    : "pointer-events-none hidden -translate-y-32 scale-75 opacity-0"
                }`}
              >
                <div
                  className="w-full cursor-pointer"
                  onClick={openSearchPanel}
                >
                  {SummaryElement}
                </div>
              </div>
              <div
                className={`absolute hidden size-full items-center justify-center transition-all duration-200 ease-in-out lg:flex ${
                  state !== "exiting" && state !== "exited"
                    ? "translate-y-0 scale-100 opacity-100"
                    : "translate-y-32 scale-150 opacity-0"
                }`}
              >
                {state !== "exited" && (
                  <div
                    className={`w-full p-6 xl:mx-auto xl:w-[1224px] ${
                      searchType === "hotel" ? "xl:w-[1104px]" : "xl:w-[1224px]"
                    }`}
                  >
                    {PanelElement}
                  </div>
                )}
              </div>
            </div>
          )}
        </Transition>
      </div>
      <div className="lg:hidden">
        <Modal
          closeIcon={searchType === "hotel" ? "arrow_forward" : "close"}
          headerStyle={searchType === "hotel" ? "block h-[64px]" : ""}
          onClose={closeSearchPanel}
          open={isSearchPanelOpen || false}
          showCloseButton
        >
          {PanelElement}
        </Modal>
      </div>
    </>
  );
};

export const HotelSearchWrapper = memo(
  HotelSearchWrapperComponent,
  deepCompare
);
