import { useRouter } from "next/router";
import { useCallback, useState } from "react";

export const useHotelAfterPaymentStatus = () => {
  const router = useRouter();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const onTogglePopover = useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
  }, [isPopoverOpen]);

  const onRetry = useCallback(
    () =>
      router.push({
        pathname: "/shopping/[shopId]/payment",
        query: router.query
      }),
    [router]
  );

  return {
    isPopoverOpen,
    onTogglePopover,
    onRetry
  };
};
