import { useIsVisible } from "@website/hooks";
import { debounce } from "lodash";
import { HotelReservedBundleCardPropertiesType } from "./hotel-reserve-bundle-card.types";

export const useHotelReserveBundelCard = (
  properties: HotelReservedBundleCardPropertiesType
) => {
  const {
    hasBreakfast,
    price,
    img,
    nightCount,
    priceOff,
    description,
    onClick: onHandleClick,
    freeCancellation,
    cancellationPolicies,
    rooms,
    boardType,
    onVisibleHotelCard
  } = properties;

  const handleVisibilityChange = debounce(
    (inView: boolean) => onVisibleHotelCard?.(inView),
    500
  );

  const { reference } = useIsVisible({
    onChange: handleVisibilityChange
  });

  return {
    hotelReserveCardContentProps: {
      hasBreakfast,
      img,
      description,
      freeCancellation,
      cancellationPolicies,
      rooms,
      boardType
    },
    hotelReserveCardFooterProps: {
      price,
      onClick: onHandleClick,
      nightCount,
      priceOff,
      roomsCount: rooms.length,
      freeCancellation,
      cancellationPolicies
    },
    reference
  };
};
