import { FC } from "react";
import { HotelInboundLinkContentPropertiesType } from "./hotel-inbound-links.types";

export const HotelInboundLinkContent: FC<
  HotelInboundLinkContentPropertiesType
> = (properties) => {
  const { list } = properties;

  return (
    <div className="flex flex-col items-start space-y-2">
      {list?.map((item) => (
        <a href={item.url} key={item.url}>
          {item.title}
        </a>
      ))}
    </div>
  );
};
