import { Skeleton } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelDraftPrepaymentRegulationAccept } from "../hotel-draft-prepayment-regulation-accept";
import { HotelDraftPrepaymentDetails } from "./hotel-draft-prepayment-details";
import { HotelDraftPrepaymentDiscount } from "./hotel-draft-prepayment-discount";
import { HotelDraftPrepaymentFreeCab } from "./hotel-draft-prepayment-free-cab";
import { HotelDraftPrepaymentGateways } from "./hotel-draft-prepayment-gateways";
import { HotelDraftPrepaymentRules } from "./hotel-draft-prepayment-rules";
import { HotelDraftPrepaymentPropertiesType } from "./hotel-draft-prepayment.types";

const Component: FC<HotelDraftPrepaymentPropertiesType> = (properties) => {
  const {
    HotelDraftPrepaymentDiscountProps,
    hotelDraftPrepaymentGatewaysProps,
    HotelDraftPrepaymentDetailsProps,
    HotelDraftPrepaymentRulesProps,
    hotelDraftPrepaymentFreeCab,
    specialRequests
  } = properties;
  return (
    <div className="bg-dim-background flex flex-col gap-2 lg:gap-6 lg:bg-white lg:px-0">
      <HotelDraftPrepaymentDetails {...HotelDraftPrepaymentDetailsProps} />
      {specialRequests === "" ? null : (
        <div className="lg:border-outline-thin bg-white px-6 py-4 lg:rounded-lg lg:border lg:p-6">
          {properties?.HotelDraftPrepaymentDetailsProps?.isRefetching ||
          !specialRequests ? (
            <Skeleton height={100} />
          ) : (
            <>
              <div className="lg:text-headline-6 text-subtitle-1 mb-2 py-1 lg:mb-5 lg:p-0">
                درخواست از هتل
              </div>
              <div className="lg:text-body-2 text-caption text-on-surface-medium-emphasis">
                {specialRequests}
              </div>
            </>
          )}
        </div>
      )}
      {hotelDraftPrepaymentFreeCab ? (
        <HotelDraftPrepaymentFreeCab {...hotelDraftPrepaymentFreeCab} />
      ) : null}

      {HotelDraftPrepaymentRulesProps?.rules &&
      HotelDraftPrepaymentRulesProps.rules.length > 0 ? (
        <HotelDraftPrepaymentRules {...HotelDraftPrepaymentRulesProps} />
      ) : (
        ""
      )}

      {HotelDraftPrepaymentDiscountProps && (
        <HotelDraftPrepaymentDiscount {...HotelDraftPrepaymentDiscountProps} />
      )}

      {hotelDraftPrepaymentGatewaysProps && (
        <HotelDraftPrepaymentGateways {...hotelDraftPrepaymentGatewaysProps} />
      )}
      {process.env.NEXT_PUBLIC_APP_NAME !== "villa" && (
        <div className="xl:hidden">
          <HotelDraftPrepaymentRegulationAccept />
        </div>
      )}
    </div>
  );
};

export const HotelDraftPrepayment = memo(Component, deepCompare);
