import { DatepickerSelectedDateType } from "@website/ui";
import { useCallback, useState } from "react";
import { CityDataType } from "../../hotel-search.types";
import { HandleSearchSubmitType } from "../hotel-search-panel.types";

export const useSubmit = (
  selectedDestinationItem: any,
  date: DatepickerSelectedDateType,
  passengerNumber: { adult: number; child: number; room: number },
  addToHistory: (
    destination: any,
    startDate: any,
    endDate: any,
    passengerNumber: any
  ) => void,
  submitValidationCheck: () => boolean,
  handleSearchSubmit?: HandleSearchSubmitType,
  initialDestinationItem?: CityDataType
) => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const onSubmitClick = useCallback(async () => {
    setSubmitLoading(true);
    if (submitValidationCheck()) {
      const startDate = date[0]?.dateTime;
      const endDate = date[1]?.dateTime;
      const destinationItem = selectedDestinationItem || initialDestinationItem;
      if (destinationItem && startDate && endDate) {
        addToHistory(destinationItem, startDate, endDate, passengerNumber);
        await handleSearchSubmit?.(
          destinationItem.url,
          startDate,
          endDate,
          `${passengerNumber.adult}`,
          `${passengerNumber.child}`,
          `${passengerNumber.room}`,
          destinationItem.type,
          destinationItem?.id,
          destinationItem?.title
        );

        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } else {
      setSubmitLoading(false);
    }
  }, [
    submitValidationCheck,
    date,
    selectedDestinationItem,
    initialDestinationItem,
    addToHistory,
    passengerNumber,
    handleSearchSubmit
  ]);

  return { onSubmitClick, submitLoading };
};
