import { numberConvert } from "@website/utils";
import { padStart, toString } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const useCountDownTimer = (properties: {
  onTimeEnd?: () => void;
  differenceFrom?: EpochTimeStamp;
  stopTimer?: boolean;
  expandTimeOnEndReach?: number;
  resetOnEnd?: boolean;
}) => {
  const [now, setNow] = useState<EpochTimeStamp>(Date.now());

  const interval = useRef<NodeJS.Timeout>();

  const router = useRouter();
  const locale = router.locale ?? "fa";

  const calcNumber = useCallback(
    (number: number) =>
      numberConvert(padStart(toString(number), 2, "0"), {
        locale
      }),
    [locale]
  );

  const difference = useMemo(
    () =>
      (properties.differenceFrom ?? new Date(now).setHours(24, 0, 0)) / 1000,
    [now, properties.differenceFrom]
  );

  const deltaTime = useMemo(
    () =>
      properties.resetOnEnd && difference - now / 1000 < 0
        ? ((properties.differenceFrom ?? new Date(now).setHours(24, 0, 0)) -
            now) /
          1000
        : difference - now / 1000,
    [difference, now, properties.differenceFrom, properties.resetOnEnd]
  );

  useEffect(() => {
    if (!(deltaTime >= 1)) {
      !properties.resetOnEnd && clearInterval?.(interval?.current);
      interval.current = undefined;
      properties.onTimeEnd?.();
    }
  }, [deltaTime, properties]);

  useEffect(() => {
    if (!interval.current && !properties.stopTimer) {
      interval.current = setInterval(() => setNow(Date.now()), 1000);
    }
    return () => {
      clearInterval?.(interval?.current);
    };
  }, [properties.stopTimer]);

  return {
    timeLeft:
      deltaTime >= 1
        ? {
            hours: calcNumber(
              deltaTime ? Math.floor((deltaTime / (60 * 60)) % 24) : 0
            ),
            minutes: calcNumber(
              deltaTime ? Math.floor((deltaTime / 60) % 60) : 0
            ),
            seconds: calcNumber(deltaTime ? Math.floor(deltaTime % 60) : 0),
            originalTime: deltaTime || 0
          }
        : null
  };
};
