import { CountDownTimer } from "@website/hotel-ui";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { CarouselV2, TabButton } from "@website/ui-old";
import { deepCompare } from "@website/utils";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { HotelLastSecondPropertiesType } from "../../../../services/hotel/last-second";
import { HotelLastSecondItem } from "./hotel-last-second-item";
import { useHotelLastSecond } from "./hotel-last-second.hook";
import styles from "./hotel-last-second.module.scss";

const Component: FC<HotelLastSecondPropertiesType> = (properties) => {
  const {
    selectedTab,
    setSelectedTab,
    list = []
  } = useHotelLastSecond(properties);
  const [showSection, setShowSection] = useState(true);
  const [selectedTabData, setSelectedTabData] = useState(null);
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    if (list.length > 0 && selectedTabData === null)
      for (const [index_, item] of list.entries()) {
        if (item.rooms !== null) {
          setSelectedTab(index_);
          setSelectedTabData(item);
          break;
        }
      }
  }, [list, selectedTabData, setSelectedTab]);

  const linkToCityPage = useMemo(() => {
    const date_today = new Date();
    const data_tomorrow = new Date(
      new Date().setDate(new Date().getDate() + 1)
    );

    const today = `${date_today?.getFullYear()}-${(date_today?.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date_today
      ?.getDate()
      ?.toString()
      ?.padStart(2, "0")}`;

    const tomorrow = `${data_tomorrow?.getFullYear()}-${(
      data_tomorrow?.getMonth() + 1
    )
      ?.toString()
      ?.padStart(2, "0")}-${data_tomorrow
      ?.getDate()
      ?.toString()
      ?.padStart(2, "0")}`;

    return {
      pathname: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/رزرو-هتل/${selectedTabData?.fa_url}`,
      query: {
        ...router.query,
        date_from: today,
        date_to: tomorrow,
        promotion: "last-minute",
        utm_internal_source: "last_second_home_page"
      }
    };
  }, [router.query, selectedTabData?.fa_url]);

  if (
    list?.some((item) => item?.rooms && item?.rooms.length > 0) &&
    showSection
  ) {
    return (
      <section className={styles["container-fluid"]}>
        <article className={styles["container"]}>
          <header className={styles["header"]}>
            <div className={styles["title-wrapper"]}>
              <MaterialIcon
                className={styles["icon"]}
                name="timer"
                variant="outlined"
              />
              <h2 className={styles["title"]}>
                {t("homepage.hotel.last_second.main_title")}
              </h2>
            </div>
            <CountDownTimer
              chipClassName={styles["chip"]}
              className={styles["timer"]}
              onTimeEnd={() => setShowSection(false)}
              textWrapperClassName={styles["timer-text-wrapper"]}
              wrapperClassName={styles["timer-wrapper"]}
            />
          </header>
          <section className={styles["main-content"]}>
            <header className={styles["tabs-wrapper"]}>
              <div className={styles["tabs"]} role="tablist">
                {list?.map(
                  (item, index) =>
                    item?.rooms && (
                      <TabButton
                        color="secondary"
                        index={index}
                        key={item?.fa_title}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => {
                          item?.onClick();
                          setSelectedTabData(
                            list.find((_, index_) => index_ === index)
                          );
                        }}
                        selected={index === selectedTab}
                        size="fill"
                        title={item?.fa_title}
                        variant="round"
                      />
                    )
                )}
              </div>
              <div className={styles["show-all"]}>
                <Link href={linkToCityPage}>
                  {t("homepage.hotel.last_second.all_last_seconds_of", {
                    city: selectedTabData?.fa_title
                  })}
                </Link>
                <div>
                  <MaterialIcon name="chevron_left" />
                </div>
              </div>
            </header>
            <div
              aria-labelledby={`tab-button-${selectedTab}`}
              className={styles["list"]}
              role="tabpanel"
            >
              <CarouselV2
                itemClass={styles["item"]}
                key={list?.[selectedTab]?.en_state_title}
                keyCarusel="LastSecond"
                list={list?.[selectedTab]?.hotels?.map((hotel, index__) => (
                  <HotelLastSecondItem
                    key={hotel.hotel_id}
                    {...hotel}
                    cityUrl={list?.[selectedTab]?.fa_url}
                    showMoreItem={index__ === 7}
                  />
                ))}
                mode="free"
                options={{
                  slides: {
                    perView: "auto",
                    spacing: 16
                  }
                }}
              />
            </div>
          </section>
        </article>
      </section>
    );
  }
  return null;
};
export const HotelLastSecond = memo(Component, deepCompare);
