import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { ReserveBookingCardWrapperType } from "./hotel-reserve-booking-card-wrapper.type";

const Component: FC<ReserveBookingCardWrapperType> = (properties) => {
  const { classes = "", children } = properties;
  return (
    <div
      className={`border-outline-thin size-full rounded-xl border bg-white lg:block ${classes}`}
    >
      {children}
    </div>
  );
};

export const HotelReserveBookingCardWrapper = memo(Component, deepCompare);
