import { LazyImage, Radio } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useHotelDraftPrepaymentGateways } from "./hotel-draft-prepayment-gateways.hook";
import {
  GatewayType,
  HotelDraftPrepaymentGatewaysPropertiesType
} from "./hotel-draft-prepayment-gateways.types";

const Component: FC<HotelDraftPrepaymentGatewaysPropertiesType> = (
  properties
) => {
  const { gateways } = properties;
  const { checkedRadio, radioOnChange } =
    useHotelDraftPrepaymentGateways(properties);

  const getLabel = (gateway: GatewayType) => (
    <div className="flex items-center gap-4 lg:gap-5">
      <LazyImage
        alt={gateway.gateway}
        className="w-6"
        loading="eager"
        src={gateway.iconUrl}
        title={gateway?.gatewayFa}
      />
      <div className="text-subtitle-2 text-on-surface-high-emphasis flex flex-col">
        <span>{gateway.gatewayFa}</span>
        {Boolean(gateway.description) && (
          <span className="text-on-surface-medium-emphasis text-body-2 line-clamp-1">
            {gateway.description}
          </span>
        )}
      </div>
    </div>
  );

  return (
    <div className="lg:border-outline-thin bg-white px-6 py-4 lg:rounded-lg lg:border lg:p-6">
      <div className="lg:text-headline-6 text-subtitle-1 mb-3 py-1 lg:mb-6 lg:p-0">
        انتخاب روش پرداخت
      </div>
      <div className="text-on-surface-medium-emphasis lg:text-body-2 text-caption mb-4 lg:mb-6">
        لطفا یکی از روش های زیر را برای پرداخت انتخاب کنید.
      </div>
      <div className="flex flex-col gap-4">
        {gateways?.map((gateway) => (
          <Radio
            checked={gateway.gateway === checkedRadio}
            className={`border-outline-thin flex h-[72px] w-full flex-row-reverse items-center justify-between gap-4 rounded-lg border px-4 py-2 lg:gap-5 ${
              gateway.gateway === checkedRadio && "border-secondary border-2"
            } ${
              gateway?.gateway === "snapppay" &&
              gateway?.disabled &&
              "opacity-50"
            }`}
            color="secondary"
            disabled={gateway?.gateway === "snapppay" && gateway?.disabled}
            key={gateway.gateway}
            label={getLabel(gateway)}
            name="radiobutton"
            onChange={radioOnChange}
            value={gateway.gateway}
          />
        ))}
      </div>
    </div>
  );
};

export const HotelDraftPrepaymentGateways = memo(Component, deepCompare);
