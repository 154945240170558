import { isInvalidCheckin } from "@website/utils";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { HotelReservedCardFooterTypesPropertiesType } from "./hotel-reserved-card-footer.types";

export const useHotelReserveCardFooter = ({
  availableRooms,
  id,
  onClick
}: HotelReservedCardFooterTypesPropertiesType) => {
  const router = useRouter();
  const [roomCount, setRoomCount] = useState(
    Number.isNaN(Number(router.query["no_rooms"]))
      ? 1
      : Number(router.query["no_rooms"]) <= availableRooms
        ? Number(router.query["no_rooms"])
        : availableRooms
  );
  const [roomSelect, setRoomSelect] = useState<number | string>();
  const [loading, setLoading] = useState<boolean>(false);

  const isInvalid = isInvalidCheckin(
    router.query.date_from,
    router.query.date_to,
    false
  );
  const openDatePicker = useCallback(() => {
    const search_date_range = document.querySelector<HTMLDivElement>(
      `[data-testid="search_date_range"]`
    );
    search_date_range?.scrollIntoView({ block: "center" });
    search_date_range?.click();
    setTimeout(
      () =>
        document
          .querySelector<HTMLInputElement>(
            `[data-testid="date_picker_start_input"]`
          )
          ?.click(),
      200
    );
  }, []);
  const onHandleClick = useCallback(() => {
    if (isInvalid) {
      openDatePicker();
    } else {
      setRoomSelect(id);
      setLoading(true);
      onClick?.()?.finally?.(() => setLoading(false));
    }
  }, [id, isInvalid, onClick, openDatePicker]);
  const handleRoomCount = useCallback(
    (count: number) => {
      setRoomCount(count);
      return router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            no_rooms: count
          }
        },
        undefined,
        { scroll: false, shallow: true }
      );
    },
    [router]
  );

  const handleRecommendedHotel = useCallback(
    () =>
      document
        .querySelector("#hotel_recomendation")
        ?.scrollIntoView({ block: "center" }),
    []
  );

  return {
    roomCount,
    setRoomCount: handleRoomCount,
    roomSelect,
    onHandleClick,
    loading,
    openDatePicker,
    handleRecommendedHotel
  };
};
