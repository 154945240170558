import { useFetcher } from "@website/fetcher";
import { useDebounce } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { pick } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { HotelSearchNoResult } from "../hotel-search-no-result";
import { HotelSearchPanelPropertiesType } from "../hotel-search-panel.types";

export const useDestination = ({
  cityTitleInitialValues,
  searchAutocompleteFetcher,
  searchSuggestion
}: { cityTitleInitialValues?: string } & Pick<
  HotelSearchPanelPropertiesType,
  "searchAutocompleteFetcher" | "searchSuggestion"
>) => {
  const [destinationQuery, setDestinationQuery] = useState<string>("");
  const [selectedDestination, setSelectedDestination] = useState<string>("");
  const [selectedDestinationItem, setSelectedDestinationItem] =
    useState<any>(null);
  const query = useDebounce(destinationQuery, 250);

  const { isFetching, data } = useFetcher({
    queryKey: ["searchAutocomplete", query],
    enabled: query.length > 1,
    queryFn: ({ queryKey }) => searchAutocompleteFetcher?.(queryKey[1])
  });

  const destinationList = useMemo(
    () =>
      query && data && data.length > 0
        ? data
        : searchSuggestion && !isFetching
          ? searchSuggestion
          : [],
    [query, data, searchSuggestion, isFetching]
  );

  const onChangeDestinationQuery = useCallback((value: string) => {
    setDestinationQuery(value);
  }, []);

  const destinationListNormalized = useMemo(
    () =>
      destinationList?.map((item: any) => ({
        ...pick(item, ["id", "title", "subtitle"]),
        StartIcon: item.isHotel ? (
          <MaterialIcon name="hotel" variant="outlined" />
        ) : (
          <MaterialIcon name="trip_origin" variant="outlined" />
        ),
        Meta: (
          <div className="text-on-surface-high-emphasis text-caption">
            {item.info}
          </div>
        )
      })),
    [destinationList]
  );
  const onSelectDestination = useCallback(
    (id: string) => {
      setSelectedDestination(id);
      const destinationItem = destinationList.find(
        (destination: any) => `${destination.id}` === `${id}`
      );
      setDestinationQuery("");
      setSelectedDestinationItem(destinationItem);
    },
    [destinationList]
  );

  const onChangeDestination = useCallback((item: any) => {
    setSelectedDestination(item.id);
    setDestinationQuery("");
    setSelectedDestinationItem(item);
  }, []);

  return {
    selectedDestinationItem,
    selectedDestinationText:
      selectedDestinationItem?.title || cityTitleInitialValues,
    destinationQuery,
    selectedDestination,
    onChangeDestination,
    onSelectDestination,
    onChangeDestinationQuery,
    destinationList,
    destinationLoading: isFetching,
    destinationListNormalized,
    DestinationNoResult:
      destinationQuery?.length >= 2 &&
      !isFetching &&
      data &&
      data.length === 0 ? (
        <HotelSearchNoResult query={destinationQuery} />
      ) : undefined
  };
};
