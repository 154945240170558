import { useState } from "react";
import { HotelLastSecondPropertiesType } from "../../../../services/hotel/last-second";

export const useHotelLastSecond = (
  properties: HotelLastSecondPropertiesType
) => {
  const { list } = properties;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return {
    selectedTab,
    setSelectedTab,
    list: list?.map((item, index) => ({
      ...item,
      hotels: item?.rooms?.slice(0, 8),
      onClick: () => setSelectedTab(index)
    }))
  };
};
