import { useTranslation } from "@website/locale";
import { MiniChips } from "@website/ui";
import { deepCompare, numberConvert, numberSeparator } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { HotelCardPricingPropertiesType } from "./hotel-card-pricing.types";

const Component: FC<HotelCardPricingPropertiesType> = (properties) => {
  const router = useRouter();
  const {
    price,
    priceOff,
    discountPercentage,
    size = "responsive"
  } = properties;
  const { t } = useTranslation();

  return (
    <div
      className={`${
        size === "small"
          ? "flex flex-col items-end justify-center"
          : `flex flex-col justify-center ${
              size === "xSmall"
                ? "items-start gap-1"
                : "items-end md:mt-4 md:items-center md:gap-3"
            } `
      } `}
    >
      {discountPercentage && priceOff && price > priceOff ? (
        <div
          className={`flex items-center justify-between ${
            size === "xSmall" ? "gap-1" : "gap-2"
          }`}
        >
          {priceOff !== price && (
            <span
              className={`text-on-surface-disable line-through ${
                size === "xSmall" ? "text-overline" : "text-body-2"
              }`}
            >
              {numberConvert(numberSeparator(price), {
                locale: router.locale
              })}
            </span>
          )}
          <MiniChips
            color={size === "xSmall" ? "error-fill" : "primary"}
            size={size === "xSmall" ? "small" : "medium"}
            text={`%${numberConvert(discountPercentage.toFixed(0), {
              locale: router.locale
            })}`}
            variant={size === "xSmall" ? "accent" : "contained"}
          />
        </div>
      ) : null}
      <span
        className={`${
          size === "xSmall"
            ? "text-body-2"
            : "text-on-surface-high-emphasis text-subtitle-1"
        }`}
        itemProp="priceRange"
      >
        {price ? (
          <>
            {numberConvert(
              numberSeparator(priceOff && price > priceOff ? priceOff : price),
              {
                locale: router.locale
              }
            )}
            <span
              className={`px-1 ${
                size === "xSmall" ? "text-body-2" : "text-subtitle-2"
              }`}
            >
              {t("global.tooman_currency")}
            </span>
          </>
        ) : (
          <>{t("global.unavailable")}</>
        )}
      </span>
    </div>
  );
};

export const HotelCardPricing = memo(Component, deepCompare);
