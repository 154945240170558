import { MaterialIcon } from "@website/icons";
import { Accordion, Button } from "@website/ui";
import { deepCompare } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo, useCallback } from "react";
import { HotelDraftPrepaymentPopup } from "../hotel-draft-prepayment-popup";
import { HotelDraftPrepaymentRulesPropertiesType } from "./hotel-draft-prepayment-rules.types";

const Component: FC<HotelDraftPrepaymentRulesPropertiesType> = (properties) => {
  const router = useRouter();
  const { rules } = properties;

  const modal_key = "modal";
  const open = router.query[modal_key] === "rules";

  const toggleModal = useCallback(
    (statue: boolean) =>
      statue
        ? router.push({
            pathname: router.pathname,
            query: { ...router.query, [modal_key]: "rules" }
          })
        : router.back(),
    [router]
  );

  const rulesContent = (
    <div className={`flex flex-col gap-4 lg:mt-5 ${!open && "hidden lg:flex"}`}>
      {rules?.map((item) => (
        <Accordion
          EndIcon={<MaterialIcon name="keyboard_arrow_down" />}
          EndIconExpanded={<MaterialIcon name="keyboard_arrow_up" />}
          content={
            <>
              {item.content.map((element) => (
                <div
                  dangerouslySetInnerHTML={{ __html: element }}
                  key={element}
                />
              ))}
            </>
          }
          initialExpanded={open}
          key={item.title}
          scroll="long"
          title={item.title}
        />
      ))}
    </div>
  );

  return (
    <div className="lg:border-outline-thin bg-white px-6 py-4 lg:rounded-lg lg:border lg:p-6">
      <div className="lg:text-headline-6 text-subtitle-1 flex items-center justify-between">
        شرایط و مقررات هتل
        <Button
          EndIcon={<MaterialIcon name="chevron_left" />}
          children="جزئیات"
          className="flex lg:hidden"
          color="secondary"
          onClick={toggleModal.bind(null, true)}
          size="medium"
          variant="text"
        />
      </div>
      <div className="hidden lg:block">{rulesContent}</div>

      <HotelDraftPrepaymentPopup
        content={rulesContent}
        onClose={toggleModal.bind(null, false)}
        open={open}
        title="شرایط و مقررات هتل"
      />
    </div>
  );
};

export const HotelDraftPrepaymentRules = memo(Component, deepCompare);
