import { MaterialIcon } from "@website/icons";
import { Accordion } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { HotelFaqPropertiesType } from "./hotel-faq.types";

const Component: FC<HotelFaqPropertiesType> = (properties) => {
  const { title, list, className } = properties;
  const router = useRouter();
  if (list && list.length > 0) {
    return (
      <div
        className={`${className} my-12 flex flex-col`}
        itemScope
        itemType="https://schema.org/FAQPage"
      >
        <div className="mb-4 flex h-8 flex-row items-center gap-2">
          <div className="text-on-surface-medium-emphasis size-5 overflow-hidden text-xl leading-5">
            <MaterialIcon name="help_outline" />
          </div>
          <h3 className="text-on-surface-high-emphasis text-headline-6">
            {title}
          </h3>
        </div>
        <div className="flex flex-col space-y-4">
          {list.map((item) => (
            <Accordion
              EndIcon={<MaterialIcon name="keyboard_arrow_down" />}
              EndIconExpanded={<MaterialIcon name="keyboard_arrow_up" />}
              content={numberConvert(item.answer, { locale: router.locale })}
              key={numberConvert(item.question, { locale: router.locale })}
              title={numberConvert(item.question, { locale: router.locale })}
            />
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export const HotelFaq = memo(Component, deepCompare);
