import {
  default as HotelPage,
  getStaticProps as hotelGetStaticProperties
} from "../views/hotel";
import {
  default as IntlHotelPage,
  getStaticProps as intlHotelGetStaticProperties
} from "../views/international-hotel";

const isIntlHotelAdsType = process.env["NEXT_PUBLIC_ADS_TYPE"] === "intl-hotel";
const isAdsChannel = process.env["NEXT_PUBLIC_CHANNEL_TYPE"] === "ads";
const isIntlHotelAds = isAdsChannel && isIntlHotelAdsType;

const App = (properties) => {
  if (isIntlHotelAds) {
    return <IntlHotelPage {...properties} />;
  }
  return <HotelPage {...properties} />;
};

const getStaticProps = isIntlHotelAds
  ? intlHotelGetStaticProperties
  : hotelGetStaticProperties;

export { getStaticProps };

export default App;
