import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Carousel } from "@website/ui";
import { deepCompare } from "@website/utils";
import { memo, useCallback, useState } from "react";
import { HotelCard } from "../../hotel-card";
import { HotelCardSuggestionCarouselPropertiesTypes } from "./hotel-suggestion-carrousel.types";

const Component = (properties: HotelCardSuggestionCarouselPropertiesTypes) => {
  const itemsToShow = 6;
  const { hotelsSuggested } = properties;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(hotelsSuggested?.length > 0);
  const handleClick = useCallback(() => setIsOpen(false), []);
  return (
    <div
      className="bg-transparent-secondary lg:border-outline-thin mx-[-24px] h-[188px] min-w-[200px] pb-6 pl-6 pt-[18px] md:min-w-[360px] lg:mx-0 lg:h-[184px] lg:rounded-[12px] lg:border xl:max-w-[864px] rtl:pl-0 rtl:pr-6"
      hidden={!isOpen}
    >
      <div className="text-subtitle-2 end-6 flex h-[34px] flex-row items-center justify-between pb-3 pe-6 lg:h-[28px]">
        <div className="text-secondary">{t("suggestion.suggested_hotels")}</div>
        <MaterialIcon
          className="text-secondary cursor-pointer"
          name="clear"
          onClick={handleClick}
        />
      </div>
      <Carousel
        itemsToShow={itemsToShow}
        list={
          hotelsSuggested?.map((hotelsSuggestionProperties) => (
            <HotelCard
              hotelInfo={hotelsSuggestionProperties}
              key={hotelsSuggestionProperties.id}
              size="xSmall"
            />
          )) || []
        }
      />
    </div>
  );
};
export const HotelSuggestionCarousel = memo(Component, deepCompare);
