import { useViewPort } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { CollapsibleText } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { HotelCardStars } from "../hotel-card/hotel-card-stars";
import { HotelReserveDetailsPropertiesTypes } from "./hotel-reserve-details.types";

const Component = (properties: HotelReserveDetailsPropertiesTypes) => {
  const { hotelTitle, stars, address, description } = properties;
  const router = useRouter();
  const { isMobile } = useViewPort();
  const { t } = useTranslation();

  return (
    <div className="max-w-[1200px] pt-6">
      <h1 className="pb-1 text-base font-semibold leading-6 lg:pb-4 lg:text-lg xl:text-2xl">
        {numberConvert(hotelTitle, { locale: router.locale })}
      </h1>
      <div className="flex flex-col gap-6">
        <div className="text-on-surface-medium-emphasis text-caption lg:text-body-1 flex flex-col items-start gap-1 lg:flex-row lg:items-center lg:gap-4">
          {Boolean(stars) && (
            <>
              <div className="hidden lg:block" itemScope>
                <HotelCardStars
                  color="neutral"
                  starSize="medium"
                  stars={stars}
                />
              </div>
              <div className="block lg:hidden" itemScope>
                <HotelCardStars
                  color="neutral"
                  starSize="small"
                  stars={stars}
                />
              </div>
            </>
          )}
          {address && (
            <div className="flex flex-row gap-1">
              <MaterialIcon name="location_on" variant="outlined" />
              <div>
                {numberConvert(address.replaceAll(".", ""), {
                  locale: router.locale
                })}
              </div>
            </div>
          )}
        </div>
        <div>
          <CollapsibleText
            ariaLabel="details"
            content={
              <p
                className="text-on-surface-high-emphasis text-body-2 lg:text-body-1 text-justify"
                dangerouslySetInnerHTML={{
                  __html: numberConvert(description, { locale: router.locale })
                }}
              />
            }
            height={isMobile ? 40 : 50}
            lessText={t("global.close")}
            lessTextIcon={<MaterialIcon name="arrow_drop_up" />}
            moreText={t("global.view_more")}
            moreTextIcon={<MaterialIcon name="arrow_drop_down" />}
          />
        </div>
      </div>
    </div>
  );
};
export const HotelReserveDetails = memo(Component, deepCompare);
