import { MaterialIcon } from "@website/icons";
import { useMemo } from "react";
import { HotelCardStarsPropertiesType } from "./hotel-card-stars.types";

export const useHotelCardStars = (properties: HotelCardStarsPropertiesType) => {
  const { stars, color, starSize = "small" } = properties;

  const hotelStars = useMemo(
    () =>
      Array.from({ length: stars }, (_: number, index: number) => (
        <MaterialIcon
          className={`${starSize === "medium" ? "!text-[24px]" : "text-xs"} ${
            color === "neutral"
              ? "text-on-surface-medium-emphasis"
              : "text-on-surface-high-emphasis"
          }`}
          key={`star-${index}`}
          name="star"
        />
      )),
    [color, starSize, stars]
  );
  return { hotelStars };
};
