import { useTranslation } from "@website/locale";
import { DatepickerSelectedDateType } from "@website/ui";
import {
  dateFormat,
  deepOmit,
  isFilled,
  numberConvert,
  numberSeparator
} from "@website/utils";
import { keyBy, toString } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import { HotelRoomCalendarPropertiesType } from "./hotel-room-calendar.types";

export const useRoomCalendar = (
  properties: HotelRoomCalendarPropertiesType
) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { calendar } = properties;
  const { date_to, date_from } = router.query as Record<string, string>;

  const onCalendarChange = useCallback(
    (date: any) => {
      setSelectedDate(date);
      const date_from = date[0]?.dateTime;
      const date_to = date[1]?.dateTime;

      if (date_to) {
        return router.replace(
          {
            pathname: router.pathname,
            query: deepOmit(
              {
                ...router.query,
                date_from,
                date_to
              },
              isFilled
            )
          },
          undefined,
          { scroll: false, shallow: true }
        );
      }
    },
    [router]
  );

  const datePickerInitialValues: DatepickerSelectedDateType = useMemo(
    () => [
      {
        year: Number(
          dateFormat(date_from, {
            locale: router.locale,
            numberConvert: false,
            template: "yyyy"
          })
        ),
        month: Number(
          dateFormat(date_from, {
            locale: router.locale,
            numberConvert: false,
            template: "MM"
          })
        ),
        day: Number(
          dateFormat(date_from, {
            locale: router.locale,
            numberConvert: false,
            template: "dd"
          })
        ),
        dateTime: toString(
          dateFormat(date_from, {
            locale: "en",
            template: "yyyy-MM-dd"
          })
        )
      },
      {
        year: Number(
          dateFormat(date_to, {
            locale: router.locale,
            numberConvert: false,
            template: "yyyy"
          })
        ),
        month: Number(
          dateFormat(date_to, {
            locale: router.locale,
            numberConvert: false,
            template: "MM"
          })
        ),
        day: Number(
          dateFormat(date_to, {
            locale: router.locale,
            numberConvert: false,
            template: "dd"
          })
        ),
        dateTime: toString(
          dateFormat(date_to, {
            locale: "en",
            template: "yyyy-MM-dd"
          })
        )
      }
    ],
    [date_from, date_to, router]
  );

  const [selectedDate, setSelectedDate] = useState<DatepickerSelectedDateType>(
    datePickerInitialValues
  );

  const startDateIndex = useMemo(
    () => calendar?.findIndex(({ date }) => date === selectedDate[0]?.dateTime),
    [calendar, selectedDate]
  );

  const endDateIndex = useMemo(
    () => calendar?.findIndex(({ date }) => date === selectedDate[1]?.dateTime),
    [calendar, selectedDate]
  );

  const transferredCalenderData = useMemo(
    () =>
      keyBy(
        calendar?.map(
          ({ date, isAvailable, price = 0, priceOff = 0 }, index) => {
            const canSelect =
              index >= startDateIndex &&
              startDateIndex !== -1 &&
              [index, -1].includes(endDateIndex)
                ? calendar
                    .slice(startDateIndex, index)
                    .every(({ isAvailable }) => isAvailable)
                : isAvailable;
            const convertedPrice = price
              ? numberConvert(numberSeparator(price / 1000), {
                  locale: router.locale,
                  numberOnly: false
                })
              : 0;
            return {
              date,
              state: canSelect ? undefined : ("disabled" as const),
              content: isAvailable ? (
                price > priceOff ? (
                  <div className="flex flex-col items-center">
                    <span className="line-through opacity-75">
                      {convertedPrice}
                    </span>
                    <span>
                      {priceOff
                        ? numberConvert(numberSeparator(priceOff / 1000), {
                            locale: router.locale,
                            numberOnly: false
                          })
                        : 0}
                    </span>
                  </div>
                ) : (
                  convertedPrice
                )
              ) : (
                t("global.full_capacity")
              )
            };
          }
        ),
        "date"
      ),
    [calendar, endDateIndex, router.locale, startDateIndex, t]
  );

  return {
    selectedDate,
    calnedarData: transferredCalenderData,
    disableLimit: {
      disableBeforeDate: calendar?.[0].date,
      disableAfterDate: calendar?.[calendar.length - 1].date
    },
    onCalendarChange
  };
};
