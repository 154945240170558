import { LazyImage } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import SearchNoResult from "../images/search-no-result.png";
import { useHotelSearchNoResult } from "./hotel-search-no-result.hook";
import { HotelSearchNoResultPropertiesType } from "./hotel-search-no-result.types";

const HotelSearchNoResult: FC<HotelSearchNoResultPropertiesType> = (
  properties
) => {
  const { resultTitle, notFoundtitle, firstDescription, secondDescription } =
    useHotelSearchNoResult(properties);

  return (
    <div className="my-6 flex flex-col items-center px-6">
      <div className="text-subtitle-2 text-on-surface-medium-emphasis w-full text-right">
        {resultTitle}
      </div>
      <LazyImage
        alt="no result"
        className="w-[188px]"
        loading="eager"
        src={SearchNoResult.src}
      />
      <div className="text-subtitle-1 text-on-surface-high-emphasis mt-6">
        {notFoundtitle}
      </div>
      <div className="text-caption text-on-surface-medium-emphasis mt-2">
        {firstDescription}
      </div>
      <div className="text-caption text-on-surface-medium-emphasis mt-2">
        {secondDescription}
      </div>
    </div>
  );
};

const HotelSearchNoResultMemoized = memo(HotelSearchNoResult, deepCompare);
export { HotelSearchNoResultMemoized as HotelSearchNoResult };
