import { useTranslation } from "@website/locale";
import { numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { UseHotelFiltersFormProperties } from "./hotel-filter.types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useCityFilterState } from "@website/widget";

export const useHotelFiltersForm = (
  properties: UseHotelFiltersFormProperties
) => {
  const router = useRouter();
  const { hotelListResultsCount, loading, clearAllFilters } = properties;
  const { t } = useTranslation();

  const { onCloseFilters } = useCityFilterState();

  return {
    title: t("global.filters"),
    resetButtonProps: {
      size: "small" as const,
      color: "secondary" as const,
      variant: "text" as const
    },
    onResetFilters: clearAllFilters,
    footerProps: {
      loading: hotelListResultsCount === "loading" || loading,
      handleFiltersAction: onCloseFilters,
      filtersActionTitle: t("global.view_result_count", {
        count: numberConvert(hotelListResultsCount ?? 0, {
          locale: router.locale
        })
      })
    }
  };
};
