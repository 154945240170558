import { dateFormat, daysBetweenDates, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { HotelSearchSummaryPropertiesType } from "./hotel-search-summary.types";

export const useHotelSearchSummary = (
  properties: HotelSearchSummaryPropertiesType
) => {
  const router = useRouter();
  const { searchType, cityTitle, dateFrom, dateTo, passengersCount, noRooms } =
    properties;
  return {
    searchType,
    cityTitle,
    dateFrom: dateFormat(dateFrom, {
      locale: router.locale,
      template: "dd MMMM"
    }) as string,
    dateTo: dateFormat(dateTo, {
      locale: router.locale,
      template: "dd MMMM"
    }) as string,
    passengersCount:
      router.query?.occupants &&
      numberConvert(passengersCount, {
        locale: router.locale
      }),
    roomsCount: numberConvert(noRooms, {
      locale: router.locale
    }),
    nightCount: daysBetweenDates(dateFrom, dateTo)
  };
};
