import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Carousel, TabButton } from "@website/ui";
import { dateFormat, deepCompare } from "@website/utils";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, memo, useMemo, useState } from "react";
import { CountDownTimer } from "../count-down-timer";
import { HotelLastSecondItem } from "./hotel-last-second-item";
import { useHotelLastSecond } from "./hotel-last-second.hook";
import { HotelLastSecondPropertiesType } from "./hotel-last-second.type";

const Component: FC<HotelLastSecondPropertiesType> = (properties) => {
  const { selectedTab, list } = useHotelLastSecond(properties);
  const [showSection, setShowSection] = useState(true);
  const { t } = useTranslation();
  const router = useRouter();

  const selectedTabData =
    list[list.findIndex((_, index) => index === selectedTab)];

  const linkToCityPage = useMemo(() => {
    const date = new Date();

    return {
      pathname: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/رزرو-هتل/${selectedTabData?.fa_url}`,
      query: {
        ...router.query,
        date_from: dateFormat(date, {
          locale: "en",
          template: "yyyy-MM-dd",
          numberConvert: false
        }),
        date_to:
          router.query.date_to ||
          dateFormat(date.setDate(date.getDate() + 1), {
            locale: "en",
            template: "yyyy-MM-dd",
            numberConvert: false
          })
      }
    };
  }, [router.query, selectedTabData?.fa_url]);

  if (list && showSection) {
    return (
      <div className="bg-transparent-primary border-transparent-primary flex flex-col gap-4 pb-[56px] pt-[28px] md:border-y">
        <div className="flex flex-col gap-4 px-6 md:flex-row md:items-center md:justify-between">
          <div className="flex h-8 flex-row items-center gap-2 lg:mb-1">
            <MaterialIcon
              className="text-on-surface-primary-medium-emphasis overflow-hidden text-[24px] leading-6"
              name="timer"
              variant="outlined"
            />
            <h2 className="text-subtitle-1 text-on-surface-primary-high-emphasis md:text-headline-6">
              {t("last_second.main_title")}
            </h2>
          </div>
          <CountDownTimer
            chipClassName="h-[32px]"
            className="flex gap-1"
            onTimeEnd={() => setShowSection(false)}
            textWrapperClassName="text-body-1 text-on-color-high-emphasis gap-3"
            wrapperClassName="md:w-[188px]"
          />
        </div>
        <div className="ps-6">
          <div className="mb-2 flex items-center justify-between gap-3 overflow-auto pb-2 md:overflow-auto">
            <div className="flex flex-row gap-2 px-[2px]" role="tablist">
              {list.map((item, index) => (
                <TabButton
                  color="secondary"
                  index={index}
                  key={item.fa_title}
                  onClick={item.onClick}
                  selected={index === selectedTab}
                  size="fill"
                  title={item.fa_title}
                  variant="round"
                />
              ))}
            </div>
            <div className="text-subtitle-2 text-secondary flex shrink-0 gap-2 pe-6">
              <Link href={linkToCityPage}>
                {t("last_second.all_last_seconds_of", {
                  city: selectedTabData?.fa_title
                })}
              </Link>
              <div>
                <MaterialIcon name="chevron_left" />
              </div>
            </div>
          </div>
          {list.map((item, index) => (
            <div
              aria-labelledby={`tab-button-${index}`}
              hidden={index !== selectedTab}
              id={`tab-${index}`}
              key={item.fa_title}
              role="tabpanel"
            >
              <Carousel
                itemsToShow={4}
                list={item.hotels.map((hotel, index_) => (
                  <HotelLastSecondItem
                    key={hotel.title}
                    {...hotel}
                    cityUrl={item.fa_url}
                    showMoreItem={index_ === 7}
                  />
                ))}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};
export const HotelLastSecond = memo(Component, deepCompare);
