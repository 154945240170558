import { useState } from "react";
import { HotelLastSecondPropertiesType } from "./hotel-last-second.type";

export const useHotelLastSecond = (
  properties: HotelLastSecondPropertiesType
) => {
  const { list } = properties;

  const [selectedTab, setSelectedTab] = useState<number>(0);

  return {
    selectedTab,
    list: list.map((item, index) => ({
      ...item,
      hotels: item.rooms.slice(0, 8),
      onClick: () => setSelectedTab(index)
    }))
  };
};
