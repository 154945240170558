export enum HOTEL_NON_INSTANT_BOOKING_STATES {
  AVAILABILITY_PENDING = "availability_pending",
  AVAILABILITY_EXPIRED = "availability_expired",
  AVAILABILITY_CONFIRMED = "availability_confirmed",
  AVAILABILITY_REJECTED = "availability_rejected",
  USER_CONFIRMED_INFO = "user_confirmed_info",
  TRASH = "trash"
}

export type NonInstantBookingPropertiesType = {
  currentState?: HOTEL_NON_INSTANT_BOOKING_STATES;
  endTime?: number;
  totalDuration: number;
  onTimeEnd?: () => void;
  onTimeChange?: (time?: string) => void;
  trackingCode: number;
  onButtonClick: () => void;
  handlePaymentRedirect?: () => Promise<void>;
};

export type StatesType = Omit<
  Record<
    HOTEL_NON_INSTANT_BOOKING_STATES,
    {
      title: string;
      description1: string;
      description2?: string;
      description3?: string;
      iconSrc: string;
    }
  >,
  "trash"
>;
