import dynamic from "next/dynamic";

export * from "./hotel-booking-step-detail.types";
export * from "./hotel-draft-free-transfer/hotel-draft-free-transfer.types";
export * from "./hotel-draft-passengers-info/hotel-draft-passengers-info.types";

export const HotelBookingStepDetail = dynamic(() =>
  import("./hotel-booking-step-detail").then(
    ({ HotelBookingStepDetail }) => HotelBookingStepDetail
  )
);
