import dynamic from "next/dynamic";

export * from "./hotel-booking-step-payment";
export * from "./hotel-booking-step-payment.types";

export const HotelBookingStepPayment = dynamic(() =>
  import("./hotel-booking-step-payment").then(
    ({ HotelBookingStepPayment }) => HotelBookingStepPayment
  )
);
