import { MaterialIcon } from "@website/icons";
import { Accordion } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { HotelInboundLinkContent } from "./hotel-inbound-link-content";
import { useHotelInboundLinks } from "./hotel-inbound-links.hook";
import { HotelInboundLinksPropertiesType } from "./hotel-inbound-links.types";

const Component: FC<HotelInboundLinksPropertiesType> = (properties) => {
  const { title, list, className } = properties;
  const { masonryList } = useHotelInboundLinks(properties);
  const router = useRouter();

  if (list && list.length > 0) {
    return (
      <div className={`${className} my-12 flex flex-col`}>
        <div className="mb-4 flex h-8 flex-row items-center gap-2">
          <MaterialIcon
            className="text-on-surface-medium-emphasis text-xl"
            name="insert_link"
          />
          <h2 className="text-on-surface-high-emphasis text-headline-6">
            {title}
          </h2>
        </div>
        <div className="grid items-start gap-4 xl:grid-cols-3">
          {masonryList.map((columns, index) => (
            <div
              className="grid gap-4"
              itemScope
              key={`HotelInboundLinks-1-${++index}`}
            >
              {columns.map((item, index) => (
                <Accordion
                  EndIcon={<MaterialIcon name="keyboard_arrow_down" />}
                  EndIconExpanded={<MaterialIcon name="keyboard_arrow_up" />}
                  content={<HotelInboundLinkContent list={item.list} />}
                  key={`HotelInboundLinks-2-${++index}`}
                  scroll="long"
                  title={numberConvert(item.groupTitle, {
                    locale: router.locale
                  })}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export const HotelInboundLinks = memo(Component, deepCompare);
