import { deepCompare } from "@website/utils";
import { memo } from "react";
import { HotelReserveCardContent } from "./hotel-reserve-card-content";
import { HotelReserveCardFooter } from "./hotel-reserve-card-footer";
import { HotelReserveCardFullCapacity } from "./hotel-reserve-card-full-capacity";
import { HotelReserveCardRacks } from "./hotel-reserve-card-racks";
import { useHotelReserveCard } from "./hotel-reserve-card.hook";
import { HotelReservedCardPropertiesType } from "./hotel-reserve-card.types";

const Component = (properties: HotelReservedCardPropertiesType) => {
  const { availableRooms, rackIncluded } = properties;
  const {
    hotelReserveCardContentProps,
    hotelReserveCardFooterProps,
    hotelReserveCardFullCapacityProps,
    hotelReserveCardRacksProps,
    reference
  } = useHotelReserveCard(properties);

  return (
    <div
      className="bg-on-color-high-emphasis shadow-1 flex min-w-[218px] flex-col overflow-hidden rounded-xl xl:max-w-[1104px]"
      ref={reference}
    >
      <div className="flex flex-row">
        {/*Boolean(img) && (
          <div className={"hidden w-[124px] xl:block"}>
            <LazyImage className="h-[100%] w-[100%] object-cover" alt="room" loading="lazy" src={toString(img)} />
        )*/}
        <div className="w-min grow">
          <HotelReserveCardContent {...hotelReserveCardContentProps} />
          <div className="bg-outline-thin h-px w-full" />
          {availableRooms || rackIncluded ? (
            <HotelReserveCardFooter {...hotelReserveCardFooterProps} />
          ) : (
            <HotelReserveCardFullCapacity
              {...hotelReserveCardFullCapacityProps}
            />
          )}
        </div>
      </div>
      <HotelReserveCardRacks {...hotelReserveCardRacksProps} />
    </div>
  );
};
export const HotelReserveCard = memo(Component, deepCompare);
