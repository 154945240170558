import { useQuery } from "react-query";
import {
  LastSecondService,
  LastSecondUrl
} from "../../../services/hotel/last-second";
export const useLastSecondService = () => {
  const { isError, data } = useQuery(LastSecondUrl, LastSecondService);

  return {
    lastSecondData: {
      list: data?.list?.map((item) => ({
        ...item,
        rooms: item?.rooms
          ? item?.rooms?.map((room) => ({
              ...room,
              cover_media: {
                ...room?.cover_media,
                media: `${process.env.NEXT_PUBLIC_BASE_URL_CDN}${room?.cover_media?.media}`
              }
            }))
          : null
      }))
    },
    isError
  };
};
