import bestHotel from "../../public/images/hotel-blog/best-hotel.jpg";
import mashhadHotel from "../../public/images/hotel-blog/mashhad-hotel.jpeg";
import roomOfHotel from "../../public/images/hotel-blog/room-of-hotel.jpg";
import snappTripHotel from "../../public/images/hotel-blog/snapptrip-hotel.jpg";
import traditionalHotel from "../../public/images/hotel-blog/traditional-hotel.jpg";
import exchange from "../../public/images/snapp-blog/exchange.jpg";
import hotelCanceling from "../../public/images/snapp-blog/hotel-canceling.webp";
import hotelsImam from "../../public/images/snapp-blog/hotels-imam.webp";
import khorramabad from "../../public/images/snapp-blog/khorramabad.jpg";
import lahijan from "../../public/images/snapp-blog/lahijan.webp";

export const HOTEL_SEARCH_HISTORY_LOCAL_STORAGE_KEY = "hotel-search-history";
export const HOTEL_BANNER_POPUP_UPDATED_AT_LOCAL_STORAGE_KEY =
  "hotel-banner-popup-updated-at";

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL_B2C;

export const specialContents = [
  {
    title: "راهنمای رزرو هتل از اسنپ تریپ",
    description:
      "اگر تابه‌حال سفر و پرواز طولانی داشته‌اید، با حس خستگی و خواب‌آلودگی بعد از آن آشنا هستید. جت لگ (Jet Lag) یا پرواززدگی را هر مسافری که از مناطق زمانی متفاوت گذشته باشد، تجربه کرده است.",
    image: snappTripHotel.src,
    width: snappTripHotel.width,
    height: snappTripHotel.height,
    alt: "snapptrip hotel",
    url: `${baseUrl}/blog/%D8%B1%D8%B2%D8%B1%D9%88-%D9%87%D8%AA%D9%84-%D8%A7%D8%B3%D9%86%D9%BE-%D8%AA%D8%B1%DB%8C%D9%BE/`,
    date: "28 فروردین 1401"
  },
  {
    title: "لیست هتل‌های نزدیک حرم امام رضا (ع) به‌همراه جزئیات و عکس",
    description:
      "هتل‌های نزدیک حرم بارگاه امام هشتم شیعیان همیشه از پرطرفدارترین‌ها هستند. هر وقت اراده کنید می‌توانید در حرم باشید و به علاوه متحمل هزینه‌های جانبی مثل هزینه رفت و آمد برای رسیدن به مرکز شهر نمی‌شوید.",
    image: mashhadHotel.src,
    width: mashhadHotel.width,
    height: mashhadHotel.height,
    alt: "mashhad hotel",
    url: `${baseUrl}/blog/%D9%84%DB%8C%D8%B3%D8%AA-%D9%87%D8%AA%D9%84-%D9%87%D8%A7%DB%8C-%D9%86%D8%B2%D8%AF%DB%8C%DA%A9-%D8%AD%D8%B1%D9%85-%D8%A7%D9%85%D8%A7%D9%85-%D8%B1%D8%B6%D8%A7/`,
    date: "25 اردیبهشت 1396"
  },
  {
    title: "بهترین هتل‌های سنتی ایران را بشناسید!",
    description:
      "گاهی وقت‌ها دلمان می‌خواهد مدل‌های مختلفی از سفر رفتن را امتحان کنیم؛ از تغییر دادن وسیله‌ی مسافرت و امتحان کردن مقاصد گوناگون گرفته، تا رفتن به هتل‌هایی که حس و حال مخصوص به خودشان را دارند.",
    image: traditionalHotel.src,
    width: traditionalHotel.width,
    height: traditionalHotel.height,
    alt: "traditional hotel",
    url: `${baseUrl}/blog/%d8%a8%d9%87%d8%aa%d8%b1%db%8c%d9%86-%d9%87%d8%aa%d9%84-%d9%87%d8%a7%db%8c-%d8%b3%d9%86%d8%aa%db%8c-%d8%a7%db%8c%d8%b1%d8%a7%d9%86/`,
    date: "21 فروردین 1401"
  },
  {
    title: "بهترین هتل‌های ایران را بشناسید!",
    description:
      "گوشه به گوشه ایران، از قلعه رودخان گیلان تا دخمه‌ باستانی سیراف در بوشهر، از شله مشهدی تا قطاب‌های معروف یزد، از خوابیدن زیر آسمان پرستاره کویر لوت تا ساحل خلیج فارس، همه و همه شور و شوق سفر را در دل مسافران بیدار می‌کند.",
    image: bestHotel.src,
    width: bestHotel.width,
    height: bestHotel.height,
    alt: "best hotel",
    url: `${baseUrl}/blog/%D8%A8%D9%87%D8%AA%D8%B1%DB%8C%D9%86-%D9%87%D8%AA%D9%84%D9%87%D8%A7%DB%8C-%D8%A7%DB%8C%D8%B1%D8%A7%D9%86-%D8%B1%D8%A7-%D8%A8%D8%B4%D9%86%D8%A7%D8%B3%DB%8C%D8%AF/`,
    date: "7 آذر 1401"
  },
  {
    title: "اتاق‌های هتل چه فرقی باهم دارند؟",
    description:
      "لپ‌تاپ را روشن می‌کنیم یا موبایل‌مان را برمی‌داریم. فکر می‌کنیم ۵ دقیقه‌ای هتل رزرو می‌کنیم و کار تمام می‌شود. اما بعد از چند دقیقه گشتن گیج می‌شویم.",
    image: roomOfHotel.src,
    width: roomOfHotel.width,
    height: roomOfHotel.height,
    alt: "room of hotel",
    url: `${baseUrl}/blog/%D8%A7%D9%86%D9%88%D8%A7%D8%B9-%D8%A7%D8%AA%D8%A7%D9%82-%D9%87%D8%A7%DB%8C-%D9%87%D8%AA%D9%84/`,
    date: "15 اسفند 1397"
  }
];

export const snappSpecialContents = [
  {
    title: "قوانین کنسلی رزرو هتل را اینجا بخوانید",
    description:
      "در شرایطی ممکن است شما پس از رزرو هتل، به دلایلی از سفر خود یا حتی اقامت در آن هتل خاص منصرف شده و تصمیم به کنسل کردن هتل داشته باشید.",
    image: hotelCanceling.src,
    width: hotelCanceling.width,
    height: hotelCanceling.height,
    alt: "hotel cancellation policies",
    url: "https://snapp.ir/blog/hotel-cancellation-policies/",
    date: "25 مرداد 1403"
  },
  {
    title: "ارز مسافرتی چیست؟ راهنمای دریافت ارز دولتی (سال ۱۴۰۳)",
    description:
      "تغییرات غیرقابل‌ پیش‌بینی قیمت ارز یکی از بزرگ‌ترین موانع سفر خارجی برای بسیاری از افراد است، البته برای مسافرانی که آشنایی چندانی با ارز مسافرتی ندارند، اختلاف قیمت آن با ارز بازار آزاد را نمی‌دانند",
    image: exchange.src,
    width: exchange.width,
    height: exchange.height,
    alt: "travel foreign exchange",
    url: "https://snapp.ir/blog/travel-foreign-exchange",
    date: "23 تیر 1403"
  },
  {
    title:
      "جاهای دیدنی خرم آباد؛ شهر جاذبه‌های طبیعی و تاریخی بکر و شگفت‌انگیز",
    description:
      "خرم‌آباد از شهرهای باصفای استان لرستان است. هم در داخل شهر و هم در حوالی آن، دیدنی‌های بسیاری وجود دارد. اگر قصد سفرکردن به شهری زیبا و خوش‌آب‌وهوا را دارید،",
    image: khorramabad.src,
    width: khorramabad.width,
    height: khorramabad.height,
    alt: "sightseeing places in khorramabad",
    url: "https://snapp.ir/blog/sightseeing-places-in-khorramabad/",
    date: "23 تیر 1403"
  },
  {
    title: "این راهنمای سفر به لاهیجان را از دست ندهید!",
    description:
      "میشه شهر‌های شمالی کشور به دلیل آب‌وهوایی که دارند، اولین مکان برای سفر‌های کوتاه است. در میان تمام شهر‌ها، گیلان سرسبز و زیبا بیشترین میزان مسافران را به خود اختصاص داده است.",
    image: lahijan.src,
    width: lahijan.width,
    height: lahijan.height,
    alt: "travel to lahijan",
    url: "https://snapp.ir/blog/travel-to-lahijan/",
    date: "23 تیر 1403"
  },
  {
    title: "لیست هتل های نزدیک فرودگاه امام خمینی",
    description:
      "برای آن دسته از مسافرانی که به هر دلیل مایل به اقامت در هتل های نزدیک فرودگاه امام خمینی هستند، دانستن لیست هتل‌های نزدیک به فرودگاه امام خمینی لازم است.",
    image: hotelsImam.src,
    width: hotelsImam.width,
    height: hotelsImam.height,
    alt: "hotels near to imam khomeini airport",
    url: "https://snapp.ir/blog/hotels-near-to-imam-khomeini-airport/",
    date: "22 تیر 1403"
  }
];
