import { Checkbox } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelDraftPrepaymentFreeCabType } from "./hotel-draft-prepayment-free-cab.types";

const Component: FC<HotelDraftPrepaymentFreeCabType> = ({ onChange }) => (
  <div className="lg:border-outline-thin bg-white px-6 py-4 lg:rounded-lg lg:border lg:p-6">
    <div className="lg:text-headline-6 text-subtitle-1 mb-3 py-1 lg:mb-6 lg:p-0">
      خدمات سفر
    </div>
    <div className="flex flex-col lg:flex-row lg:justify-between">
      <Checkbox
        color="secondary"
        helperText="دریافت یک کد تخفیف اسنپ رایگان از مبدا شما به فرودگاه و یا بالعکس"
        label="درخواست کد تخفیف ۱۰۰٪ اسنپ"
        onChange={(event) => onChange(event.target.checked)}
      />
      <span className="text-caption mr-4 mt-2 lg:mr-0 lg:mt-0">رایگان</span>
    </div>
  </div>
);

export const HotelDraftPrepaymentFreeCab = memo(Component, deepCompare);
