import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelSearchSummaryContent } from "./hotel-search-summary-content/hotel-search-summary-content";
import { useHotelSearchSummary } from "./hotel-search-summary.hook";
import { HotelSearchSummaryPropertiesType } from "./hotel-search-summary.types";
const Component: FC<HotelSearchSummaryPropertiesType> = (properties) => {
  const viewProperties = useHotelSearchSummary(properties);
  return <HotelSearchSummaryContent {...viewProperties} />;
};

export const HotelSearchSummary = memo(Component, deepCompare);
