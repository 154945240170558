import { useTranslation } from "@website/locale";
import { MiniChips } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelCardHeaderPropertiesType } from "./hotel-card-header.types";

const Component: FC<HotelCardHeaderPropertiesType> = (properties) => {
  const { isAvailable, primaryBadge } = properties;
  const { t } = useTranslation();
  if (primaryBadge?.description) {
    return (
      <div
        className="bg-on-surface-high-emphasis flex h-12 items-center justify-between px-4"
        style={
          primaryBadge?.color?.startsWith("#")
            ? {
                color: "white",
                backgroundColor: primaryBadge?.color
              }
            : {}
        }
      >
        <span className="block w-full text-ellipsis whitespace-nowrap text-sm">
          {primaryBadge?.description}
        </span>
      </div>
    );
  }
  if (!isAvailable) {
    return (
      <div className="bg-on-surface-high-emphasis flex h-12 items-center justify-between px-4">
        <MiniChips
          color="error"
          text={t("global.booked_up")}
          variant="round-contained"
        />
      </div>
    );
  }
  return null;
};

export const HotelCardHeader = memo(Component, deepCompare);
