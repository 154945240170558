import { Skeleton } from "@website/ui";
import { deepCompare } from "@website/utils";
import { memo } from "react";
import { DraftCardHeader } from "../draft-card-header";
import { HotelDraftCardFooter } from "./hotel-draft-card-footer";
import { HotelDraftCardPropertiesTypes } from "./hotel-draft-card.types";
const Component = (properties: HotelDraftCardPropertiesTypes) => {
  const {
    img,
    step,
    title,
    stars,
    price,
    rooms,
    submit,
    checkin,
    checkout,
    nightCount,
    reserveCode,
    isRefetching,
    infantReserved,
    childrenReserved,
    nonInstantStepData,
    bankName,
    discountLoading,
    onSnappPayUserTrack,
    isEligible
  } = properties;
  const hotelDraftFooterProperties = {
    step,
    price,
    rooms,
    submit,
    nightCount,
    isRefetching,
    infantReserved,
    childrenReserved,
    nonInstantStepData,
    bankName,
    discountLoading,
    onSnappPayUserTrack,
    isEligible
  };

  const draftCardHeaderProperties = {
    img,
    title,
    stars,
    checkin,
    checkout,
    reserveCode
  };
  return (
    <div className="lg:border-outline-thin box-content overflow-hidden bg-white lg:rounded-lg lg:border xl:w-[360px]">
      {rooms && !isRefetching ? (
        <DraftCardHeader {...draftCardHeaderProperties} />
      ) : (
        <Skeleton height={300} />
      )}
      <HotelDraftCardFooter {...hotelDraftFooterProperties} />
    </div>
  );
};
export const HotelDraftCard = memo(Component, deepCompare);
