import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, CounterButton, Datepicker, MiniChips } from "@website/ui";
import { deepCompare, numberConvert, numberSeparator } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { HotelReserveBookingCardType } from "../hotel-reserve-booking-card.type";

const Component: FC<HotelReserveBookingCardType> = (properties) => {
  const {
    isLoading,
    pricePerNight,
    extraCapacity = 0,
    nightCount,
    capacity = 0,
    passengersCount = 0,
    price,
    priceOfExtraPassengers,
    discount,
    total_price,
    bookingCalendarIntitalShowDates,
    calendarDate,
    increaseDisabled,
    decreaseDisabled,
    submitDisabled,
    submitDisabledMessage,
    reviewRate,
    disableLimit,
    onChangeDatePicker,
    createBook,
    onChangePassengersCount
  } = properties;
  const router = useRouter();

  const { t } = useTranslation();
  return (
    <div className="flex h-full flex-col justify-between px-6 pt-6">
      <div>
        {!submitDisabled && (
          <div className="mb-6 flex w-full flex-row items-center justify-between">
            <div className="text-caption text-on-surface-high-emphasis">
              {t("villa.price_per_night")}
            </div>
            <div className="text-on-surface-high-emphasis text-subtitle-1">
              {t("global.price_with_tooman_currency", {
                price: numberConvert(numberSeparator(pricePerNight), {
                  locale: router.locale
                })
              })}
            </div>
            {reviewRate ? (
              <MiniChips
                StartIcon={<MaterialIcon name="star" />}
                color="success"
                text={reviewRate}
                variant="accent"
              />
            ) : null}
          </div>
        )}
        <Datepicker
          disableLimit={disableLimit}
          fromTitle={t("villa.checkin_date")}
          fullWidth
          initialShowDates={bookingCalendarIntitalShowDates}
          mode="range"
          onChange={onChangeDatePicker}
          toTitle={t("villa.checkout_date")}
          value={calendarDate}
        />
        <div className="mt-4 flex items-center justify-between">
          <div className="text-subtitle-1 text-on-surface-high-emphasis">
            {t("villa.number_of_passengers")}
          </div>
          <CounterButton
            color="secondary"
            decreaseDisabled={decreaseDisabled}
            increaseDisabled={increaseDisabled}
            locale={router.locale}
            onChange={onChangePassengersCount}
            value={passengersCount}
            variant="outline"
          />
        </div>
      </div>

      <div>
        {!submitDisabled && (
          <>
            <div className="my-6">
              <div className="mb-4 flex justify-between">
                <div className="text-body-2 text-on-surface-medium-emphasis">
                  {!submitDisabledMessage &&
                    t("villa.price_with_passangers_count", {
                      night: nightCount,
                      count:
                        passengersCount > capacity ? capacity : passengersCount
                    })}
                </div>
                <div className="text-on-surface-medium-emphasis text-body-2">
                  {t("global.price_with_tooman_currency", {
                    price: numberConvert(numberSeparator(price), {
                      locale: router.locale
                    })
                  })}
                </div>
              </div>

              {extraCapacity ? (
                <div className="mb-4 flex justify-between">
                  <div className="text-body-2 text-on-surface-medium-emphasis">
                    {t("villa.extra_person", {
                      count: extraCapacity
                    })}
                  </div>
                  <div className="text-on-surface-medium-emphasis text-body-2">
                    {t("global.price_with_tooman_currency", {
                      price: numberConvert(
                        numberSeparator(priceOfExtraPassengers),
                        {
                          locale: router.locale
                        }
                      )
                    })}
                  </div>
                </div>
              ) : null}

              {discount ? (
                <div className="mb-4 flex justify-between">
                  <div className="text-body-2 text-on-surface-medium-emphasis">
                    {t("global.discount")}
                  </div>
                  <div className="text-on-surface-medium-emphasis text-body-2">
                    {t("global.price_with_tooman_currency", {
                      price: numberConvert(numberSeparator(discount), {
                        locale: router.locale
                      })
                    })}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="bg-outline-thin h-px w-full" />

            <div className="mt-6 flex justify-between">
              <div className="text-subtitle-2 text-on-surface-high-emphasis">
                {t("villa.total_amount_payable")}
              </div>
              <div className="text-on-surface-high-emphasis text-headline-6">
                {t("global.price_with_tooman_currency", {
                  price: numberConvert(numberSeparator(total_price), {
                    locale: router.locale
                  })
                })}
              </div>
            </div>
          </>
        )}
        <div className="flex flex-col gap-2 py-6">
          <Button
            disabled={!isLoading && submitDisabled}
            fullWidth
            loading={isLoading}
            onClick={createBook}
            size="large"
          >
            {t("booking.request")}
          </Button>
          {submitDisabledMessage && (
            <p className="text-error text-caption">{submitDisabledMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};
export const HotelReserveBookingCardDetail = memo(Component, deepCompare);
