import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { MiniChips } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { isBoolean, isEmpty } from "lodash";
import { useRouter } from "next/router";
import { memo, useState } from "react";
import { HotelReserveCardCancellationModal } from "../hotel-reserve-card-cancellation-modal";
import { HotelReservedCardContentPropertiesType } from "./hotel-reserved-card-content.types";

const Component = (properties: HotelReservedCardContentPropertiesType) => {
  const {
    id,
    title,
    availableRooms,
    rackIncluded,
    extraBed,
    hasBreakfast,
    adults,
    facilities,
    onClick,
    description,
    hasMorContentButton = true,
    freeCancellation,
    cancellationPolicies,
    regularBadges
  } = properties;
  const router = useRouter();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const modalProperties = {
    open,
    onClose: () => setOpen(false),
    cancellationPolicies
  };
  return (
    <div className="flex flex-col">
      {/*Boolean(img) && (
        <div className={"h-[80px] lg:hidden"}>
          <img alt="room" src={img} />
      )*/}
      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-row justify-between gap-2">
          <div className="flex flex-col gap-2 lg:flex-row lg:items-center">
            {!availableRooms && !rackIncluded && (
              <MiniChips
                color="error"
                text={t("global.booked_up")}
                variant="round-contained"
              />
            )}
            <h3 className="text-on-surface-high-emphasis text-subtitle-2 lg:text-subtitle-1">
              {title}
            </h3>
          </div>
          {Boolean(hasMorContentButton) && (
            <div
              className="text-body-2 text-secondary flex cursor-pointer flex-row gap-1"
              hidden={rackIncluded}
              onClick={() =>
                onClick?.({
                  roomId: id,
                  tabSelected:
                    facilities?.length && availableRooms
                      ? "facilities"
                      : "roomCalendar",
                  availableRooms: availableRooms
                })
              }
            >
              <span className="whitespace-nowrap">
                {t("global.more_detail")}
              </span>
              <MaterialIcon name="arrow_back_ios" />
            </div>
          )}
        </div>
        <div className="flex w-full flex-row flex-wrap gap-1">
          <MiniChips
            StartIcon={<MaterialIcon name="person" />}
            text={`${numberConvert(adults, { locale: router.locale })} نفر`}
            variant="accent"
          />
          {Boolean(hasBreakfast) && (
            <MiniChips
              StartIcon={<MaterialIcon name="local_cafe" />}
              text={t("global.breakfast_included")}
              variant="accent"
            />
          )}
          {Boolean(extraBed) && (
            <MiniChips
              StartIcon={<MaterialIcon name="hotel" />}
              text={t("global.extra_bed_included")}
              variant="accent"
            />
          )}
          {isBoolean(freeCancellation) &&
            (freeCancellation ? (
              isEmpty(cancellationPolicies) ? (
                <MiniChips
                  StartIcon={<MaterialIcon name="check" />}
                  color="success"
                  text={t("hotel.room.free_cancellation")}
                  variant="accent"
                />
              ) : (
                <MiniChips
                  className="cursor-pointer"
                  color="secondary"
                  onClick={() => setOpen(true)}
                  text={t("hotel.room.cancellation_policies")}
                  variant="text"
                />
              )
            ) : (
              <MiniChips
                StartIcon={
                  <MaterialIcon name="do_not_disturb" variant="outlined" />
                }
                color="error"
                text={t("hotel.room.non_refundable")}
                variant="accent"
              />
            ))}
        </div>
        {regularBadges?.map(({ color, icon, text, transparent }) => (
          <span
            className="relative flex h-6 w-fit items-center gap-[6px] whitespace-nowrap rounded-[4px] px-[7px] outline-none"
            key={text}
            style={{
              color: transparent ? color : "white",
              backgroundColor: transparent ? `${color}20` : color
            }}
          >
            {icon && (
              <span className="text-sm">
                <MaterialIcon name={icon} variant="outlined" />
              </span>
            )}
            <span className="text-caption">{text}</span>
          </span>
        ))}
        {Boolean(description) && (
          <span className="text-caption text-on-surface-medium-emphasis flex items-center gap-2 text-justify">
            <MaterialIcon className="text-lg" name="bed" variant="outlined" />
            {numberConvert(description, { locale: router.locale })}
          </span>
        )}
      </div>
      <HotelReserveCardCancellationModal {...modalProperties} />
    </div>
  );
};
export const HotelReserveCardContent = memo(Component, deepCompare);
