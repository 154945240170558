import { MaterialIcon, MaterialNameType } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, Popover, Skeleton } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { useHotelAfterPaymentStatus } from "./hotel-after-payment-status.hook";
import { HotelAfterPaymentStatusPropertiesType } from "./hotel-after-payment-status.type";
import { PaymentMessage } from "./payment-message";

const Component: FC<HotelAfterPaymentStatusPropertiesType> = (properties) => {
  const {
    paymentStatus,
    paymentExpired,
    trackingCode,
    className,
    approvedPopoverItems,
    confirmationPaymentTime,
    onTimeEnd
  } = properties;

  const { isPopoverOpen, onTogglePopover, onRetry } =
    useHotelAfterPaymentStatus();
  const { t } = useTranslation();

  const router = useRouter();

  return trackingCode ? (
    <PaymentMessage
      className={className}
      confirmationPaymentTime={confirmationPaymentTime}
      onTimeEnd={onTimeEnd}
      paymentExpired={paymentExpired}
      paymentStatus={paymentStatus}
      trackingCode={trackingCode}
    >
      <>
        {paymentStatus === "failed" && (
          <div className="border-outline-thin fixed inset-x-0 bottom-0 z-[1] flex flex-col gap-4 border-t bg-white px-6 pb-6 pt-4 md:relative md:inset-x-[unset] md:bottom-0 md:flex-row md:border-none md:p-0">
            <Button
              className="mt-auto w-full md:mt-0 md:w-[200px]"
              color="secondary"
              onClick={onRetry}
              size="large"
            >
              {t("global.try_again")}
            </Button>
          </div>
        )}
        {paymentStatus === "availability_rejected" && (
          <div className="border-outline-thin fixed inset-x-0 bottom-0 z-[1] flex flex-col gap-4 border-t bg-white px-6 pb-6 pt-4 md:relative md:inset-x-[unset] md:bottom-0 md:flex-row md:border-none md:p-0">
            <div className="text-button text-secondary flex items-center justify-center gap-2 px-2 py-3">
              <MaterialIcon
                className="flex h-6 items-center text-[24px]"
                name="support_agent"
              />
              <a href="tel: +982192000123">
                رزرو تلفنی (
                {numberConvert("02192000123", {
                  locale: router.locale
                })}
                )
              </a>
            </div>
          </div>
        )}
        {["availability_pending", "approved", "payment_verified"].includes(
          paymentStatus
        ) && (
          <div className="border-outline-thin fixed inset-x-0 bottom-0 mt-auto flex gap-4 border-t bg-white px-6 pb-6 pt-4 md:relative md:inset-x-[unset] md:bottom-0 md:mt-0 md:border-none md:p-0">
            <div
              className="relative w-full md:w-[200px]"
              onClick={onTogglePopover}
            >
              <Button
                className="mt-auto w-full md:mt-0"
                color="secondary"
                disabled={paymentStatus === "availability_pending"}
                size="large"
              >
                {t("payment.download_voucher")}
                <MaterialIcon
                  className="mr-2 text-2xl"
                  name="arrow_drop_down"
                  variant="outlined"
                />
              </Button>
              <div
                className={
                  isPopoverOpen
                    ? "bg-on-surface-disable fixed inset-x-0 top-0 z-30 h-screen w-full md:hidden"
                    : ""
                }
              />
              <Popover
                animate="bottom"
                className="md:shadow-8 bottom-0 top-[unset] h-[unset] rounded-t-2xl py-2 md:absolute md:bottom-[unset] md:top-[56px] md:rounded-md md:p-2"
                headerClassName="mb-2 h-[48px] items-center px-4 md:hidden"
                onClose={onTogglePopover}
                open={isPopoverOpen}
                title={t("payment.download_voucher")}
              >
                <ul>
                  {approvedPopoverItems.map((item) => (
                    <li key={item.title}>
                      <a
                        className="hover:bg-states-secondary-hover flex cursor-pointer items-center gap-4 px-6 py-3 md:gap-2 md:px-2 md:py-3"
                        href={item.link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <MaterialIcon
                          className="text-on-surface-medium-emphasis flex h-6 items-center text-[24px]"
                          name={item.iconName as MaterialNameType}
                          variant="outlined"
                        />
                        <span className="text-subtitle-1 text-on-surface-high-emphasis md:text-body-1">
                          {item.title}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </Popover>
            </div>
            {paymentStatus === "approved" && (
              <Button
                className="hidden md:inline-flex"
                color="secondary"
                href={process.env.NEXT_PUBLIC_BASE_URL_B2C}
                role="link"
                size="large"
                variant="outline"
              >
                {t("payment.back_to_origin_page")}
              </Button>
            )}
          </div>
        )}
        {paymentStatus === "pending" && (
          <div className="border-outline-thin fixed inset-x-0 bottom-0 z-[1] flex flex-col gap-4 border-t bg-white px-6 pb-6 pt-4 md:relative md:inset-x-[unset] md:bottom-0 md:flex-row md:border-none md:p-0">
            <Button
              className="mt-auto w-full md:mt-0 md:w-[200px]"
              color="secondary"
              href={process.env.NEXT_PUBLIC_BASE_URL_B2C}
              role="link"
              size="large"
              variant="outline"
            >
              متوجه شدم
            </Button>
          </div>
        )}
        {paymentStatus === "availability_rejected" &&
          process.env.NEXT_PUBLIC_APP_NAME === "hotel-international" && (
            <div className="border-outline-thin fixed inset-x-0 bottom-0 z-[1] flex flex-col gap-4 border-t bg-white px-6 pb-6 pt-4 md:relative md:inset-x-[unset] md:bottom-0 md:flex-row md:border-none md:p-0">
              <Button
                className="mt-auto w-full md:mt-0 md:w-[200px]"
                color="secondary"
                href={process.env.NEXT_PUBLIC_BASE_URL_B2C}
                role="link"
                size="large"
                variant="outline"
              >
                متوجه شدم
              </Button>
            </div>
          )}
      </>
    </PaymentMessage>
  ) : (
    <Skeleton height={256} />
  );
};
export const HotelAfterPaymentStatus = memo(Component, deepCompare);
