type SchemaHotelPagePropertiesType = {
  image: string;
  name: string;
  starRating: number;
  address: string;
  priceRange: string;
};

export const SchemaHotelPage = ({
  image,
  name,
  starRating,
  address,
  priceRange
}: SchemaHotelPagePropertiesType) => (
  <script
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "Hotel",
        image,
        name,
        starRating,
        address,
        priceRange
      })
    }}
    type="application/ld+json"
  />
);
