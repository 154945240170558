import { useTranslation } from "@website/locale";
import { Chips } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { omit, pick } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { BaseSyntheticEvent, memo, useCallback } from "react";
import {
  HotelReserveCategoriesPropertiesType,
  HotelReserveCategoryType
} from "./hotel-reserve-categories.types";

const ItemComponent = ({ id, name }: HotelReserveCategoryType) => {
  const router = useRouter();
  const onClick = useCallback(
    (event: BaseSyntheticEvent) => {
      event.preventDefault();
      return router.push({
        pathname: "/[cityTitle]",
        query: {
          ...omit(router.query, "hotelTitle"),
          category_ids: id
        }
      });
    },
    [id, router]
  );
  return (
    <Link
      href={{
        pathname: "/[cityTitle]",
        query: pick(router.query, "cityTitle")
      }}
      onClick={onClick}
    >
      <Chips
        text={numberConvert(name, { locale: router.locale })}
        variant="outline"
      />
    </Link>
  );
};

const Component = ({ categories }: HotelReserveCategoriesPropertiesType) => {
  const { t } = useTranslation();
  if (!categories?.length) return null;
  return (
    <div className="flex flex-col gap-4">
      <div className="text-subtitle-1 md:text-headline-6 mb-3">
        {t("categories.residence_labels")}
      </div>
      <div className="flex flex-row flex-wrap gap-2">
        {categories.map((category) => (
          <ItemComponent {...category} key={category.id} />
        ))}
      </div>
    </div>
  );
};
export const HotelReserveCategories = memo(Component, deepCompare);
