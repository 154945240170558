import dynamic from "next/dynamic";

export * from "./hotel-after-payment-status/hotel-after-payment-status.type";
export * from "./hotel-booking-voucher";
export * from "./hotel-booking-voucher.types";

export const HotelBookingVoucher = dynamic(() =>
  import("./hotel-booking-voucher").then(
    ({ HotelBookingVoucher }) => HotelBookingVoucher
  )
);
