import { MaterialIcon } from "@website/icons";
import { Button, Skeleton } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo, useCallback, useRef, useState } from "react";
import { HotelDraftPrepaymentPopup } from "../hotel-draft-prepayment-popup";
import { HotelDraftPrepaymentDetailsPropertiesType } from "./hotel-draft-prepayment-details.types";

const Item = (properties: {
  name: string;
  value: string;
  labelStyle?: string;
}) => {
  const { name, value, labelStyle } = properties;
  return (
    <div className="text-on-surface-high-emphasis lg:text-body-2 text-caption flex flex-wrap items-center">
      <span className={labelStyle}> {name}</span>
      <div className="border-outline-thin mx-2 flex-1 border-t border-dashed" />
      <span className={labelStyle}>{value} </span>
    </div>
  );
};

const Component: FC<HotelDraftPrepaymentDetailsPropertiesType> = (
  properties
) => {
  const router = useRouter();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const reference = useRef<HTMLDivElement>(null);

  const modal_key = "modal";
  const open = router.query[modal_key] === "draft_details";

  const toggleModal = useCallback(
    (statue: boolean) =>
      statue
        ? router.push({
            pathname: router.pathname,
            query: { ...router.query, [modal_key]: "draft_details" }
          })
        : router.back(),
    [router]
  );

  const {
    rooms,
    nightCount,
    isRefetching,
    adultReserved = 0,
    infantReserved = 0,
    childrenReserved = 0,
    user
  } = properties;
  const countDetailsText = (adults: number, children: number) =>
    `${numberConvert(adults, { locale: router.locale })}
     بزرگسال
      ${
        children > 0
          ? `،    ${numberConvert(children, { locale: router.locale })} کودک  `
          : ""
      }`;
  const purchaserInfoContent = (
    <div className="mb-4 flex flex-col gap-2 lg:mb-0">
      {Boolean(user) && (
        <>
          <Item name="خریدار" value={`${user?.name} ${user?.family}`} />
          <Item
            name="شماره موبایل"
            value={numberConvert(
              String(user?.mobile).replace(/^(\+98|98)/, "0"),
              {
                locale: router.locale
              }
            )}
          />
        </>
      )}
      <Item
        name="تعداد اتاق"
        value={`${numberConvert(rooms?.length, {
          locale: router.locale
        })} اتاق`}
      />
      <Item
        name="مدت اقامت"
        value={`${numberConvert(nightCount, { locale: router.locale })} شب`}
      />
      <Item
        labelStyle={
          open ? "lg:text-on-surface-high-emphasis text-secondary" : ""
        }
        name="مجموع ظرفیت رزرو شده"
        value={countDetailsText(
          adultReserved,
          childrenReserved + infantReserved
        )}
      />
    </div>
  );
  const roomDetailsContent = (
    <div>
      <div
        className="h-0 overflow-hidden transition-all"
        ref={reference}
        style={
          collapsed
            ? { height: reference.current?.scrollHeight }
            : open
              ? { height: "auto" }
              : {}
        }
      >
        <div className="flex flex-col gap-6">
          {rooms?.map((room, index) => (
            <div key={room.id}>
              <div className="border-outline-thin mb-4 flex flex-wrap items-center border-t pt-6 lg:justify-between">
                <div className="lg:text-on-surface-high-emphasis lg:text-headline-6 text-on-surface-medium-emphasis text-caption border-on-surface-medium-emphasis ml-2 rounded-3xl border px-1 lg:mb-2 lg:ml-0 lg:min-w-full lg:border-0">
                  {`اتاق ${numberConvert(index + 1, {
                    locale: router.locale
                  })}`}
                </div>
                <span className="text-on-surface-high-emphasis text-subtitle-2">
                  {room?.title}
                </span>
                <div className="text-on-surface-medium-emphasis text-caption mt-1 flex min-w-full items-center gap-4 lg:mt-0 lg:min-w-0">
                  <span>
                    <MaterialIcon name="person" />
                    {`ظرفیت : ${countDetailsText(room?.adult, room?.child)}`}
                  </span>
                  {room?.breakfastIncluded && (
                    <span>
                      <MaterialIcon name="free_breakfast" />
                      با صبحانه
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <Item
                  name={`مسافر ${numberConvert(1, {
                    locale: router.locale
                  })} - سرپرست`}
                  value={String(room?.guest?.[0]?.full_name)}
                />
                {room?.guest
                  ?.filter?.((_, index) => index !== 0)
                  ?.map?.((item, itemIndex) => (
                    <Item
                      key={item.full_name}
                      name={`مسافر ${numberConvert(itemIndex + 2, {
                        locale: router.locale
                      })}`}
                      value={String(item.full_name)}
                    />
                  ))}
                {Number(room.extraBedReserved) > 0 && (
                  <Item
                    name="تخت اضافه"
                    value={`${numberConvert(room.extraBedReserved, {
                      locale: router.locale
                    })} عدد`}
                  />
                )}
                {Number(room.childReserved) > 0 && (
                  <Item
                    name="کودک"
                    value={`${numberConvert(room.childReserved, {
                      locale: router.locale
                    })} نفر`}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="lg:border-outline-thin flex flex-col gap-4 bg-white px-6 py-4 lg:gap-6 lg:rounded-lg lg:border lg:p-6">
      {rooms && !isRefetching ? (
        <>
          <div className="lg:text-headline-6 text-subtitle-1 flex items-center justify-between">
            اطلاعات اتاق و مسافران
            <Button
              EndIcon={<MaterialIcon name="chevron_left" />}
              children="جزئیات"
              className="flex hover:before:bg-white lg:hidden"
              color="secondary"
              onClick={toggleModal.bind(null, true)}
              size="medium"
              variant="text"
            />
          </div>
          {purchaserInfoContent}
          <div className="hidden lg:block">{roomDetailsContent}</div>
          <div className="-mt-3 hidden justify-center lg:flex">
            {collapsed ? (
              <Button
                EndIcon={<MaterialIcon name="expand_less" />}
                children="بستن"
                color="secondary"
                onClick={() => setCollapsed(false)}
                size="medium"
                variant="text"
              />
            ) : (
              <Button
                EndIcon={<MaterialIcon name="expand_more" />}
                children="جزئیات بیشتر"
                color="secondary"
                onClick={() => setCollapsed(true)}
                size="medium"
                variant="text"
              />
            )}
          </div>
          <HotelDraftPrepaymentPopup
            content={
              <>
                {purchaserInfoContent}
                {roomDetailsContent}
              </>
            }
            onClose={toggleModal.bind(null, false)}
            open={open}
            title="اطلاعات اتاق و مسافران"
          />
        </>
      ) : (
        <Skeleton height={220} />
      )}
    </div>
  );
};

export const HotelDraftPrepaymentDetails = memo(Component, deepCompare);
