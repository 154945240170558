import { Meta, StoryObj } from "@storybook/react";
import { DatepickerSelectedDateType } from "@website/ui";
import { dateFormat } from "@website/utils";
import { toString } from "lodash";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useDate } from "../hotel-search/hotel-search-panel/hooks";
import { HotelCalendar } from "./hotel-calendar";
import { HotelCalendarType } from "./hotel-calendar.type";

const meta: Meta<HotelCalendarType> = {
  title: "hotel-ui/Hotel Calendar/Calendar",
  component: HotelCalendar,

  render: function Component(arguments_) {
    const router = useRouter();
    const { date_to, date_from } = router.query as Record<string, string>;

    const datePickerInitialValues: DatepickerSelectedDateType = useMemo(
      () => [
        {
          year: Number(
            dateFormat(date_from, {
              locale: router.locale,
              numberConvert: false,
              template: "yyyy"
            })
          ),
          month: Number(
            dateFormat(date_from, {
              locale: router.locale,
              numberConvert: false,
              template: "MM"
            })
          ),
          day: Number(
            dateFormat(date_from, {
              locale: router.locale,
              numberConvert: false,
              template: "dd"
            })
          ),
          dateTime: toString(
            dateFormat(date_from, {
              locale: "en",
              template: "yyyy-MM-dd"
            })
          )
        },
        {
          year: Number(
            dateFormat(date_to, {
              locale: router.locale,
              numberConvert: false,
              template: "yyyy"
            })
          ),
          month: Number(
            dateFormat(date_to, {
              locale: router.locale,
              numberConvert: false,
              template: "MM"
            })
          ),
          day: Number(
            dateFormat(date_to, {
              locale: router.locale,
              numberConvert: false,
              template: "dd"
            })
          ),
          dateTime: toString(
            dateFormat(date_to, {
              locale: "en",
              template: "yyyy-MM-dd"
            })
          )
        }
      ],
      [date_from, date_to, router]
    );

    const { date, onChangeDate } = useDate(datePickerInitialValues);
    return (
      <div dir="rtl" style={{ width: "680px" }}>
        <HotelCalendar
          {...arguments_}
          calendarDate={date}
          onCalendarChange={onChangeDate}
        />
      </div>
    );
  }
};

export default meta;

type Story = StoryObj<HotelCalendarType>;

export const calendar: Story = {
  args: {
    calnedarData: {
      "2024-03-12": {
        content: "55000"
      },
      "2024-03-17": {
        content: "45000",
        state: "disabled"
      },
      "2024-04-5": {
        content: "23000"
      },
      "2024-04-15": {
        content: "56000",
        state: "disabled"
      }
    }
  }
};
