import { useTranslation } from "@website/locale";
import { Button, Modal } from "@website/ui";
import { deepCompare, numberConvert, numberSeparator } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { HotelReserveBookingCardDetail } from "./hotel-reserve-booking-card-detail";
import { HotelReserveBookingCardWrapper } from "./hotel-reserve-booking-card-wrapper";
import { HotelReserveBookingCardType } from "./hotel-reserve-booking-card.type";
import useReverseBookingModal from "./use-reverse-booking-modal";
const Component: FC<HotelReserveBookingCardType> = (properties) => {
  const {
    isLoading,
    nightCount,
    capacity = 0,
    passengersCount = 0,
    price,
    submitDisabled,
    submitDisabledMessage
  } = properties;
  const { onModalClose, isReservePageOpen, onModalOpen } =
    useReverseBookingModal();

  const router = useRouter();

  const { t } = useTranslation();
  const submitDisabledMessageError = (
    <p className="text-error text-caption">{submitDisabledMessage}</p>
  );

  return (
    <>
      <div className="border-outline-thin bg-on-color-high-emphasis shadow-6 fixed inset-x-0 bottom-0 z-[5] grid w-full grid-cols-2 gap-2 border px-6 pb-6 pt-4 lg:hidden lg:min-h-[97px]">
        <div className="flex flex-col gap-2">
          <div className="text-caption text-on-surface-medium-emphasis">
            {submitDisabledMessage
              ? submitDisabledMessageError
              : t("villa.price_with_passangers_count", {
                  night: nightCount,
                  count: passengersCount > capacity ? capacity : passengersCount
                })}
          </div>
          <div className="text-body-1 text-on-surface-high-emphasis">
            {price
              ? t("global.price_with_tooman_currency", {
                  price: numberConvert(numberSeparator(price), {
                    locale: router.locale
                  })
                })
              : null}
          </div>
        </div>
        <Button
          disabled={!isLoading && submitDisabled}
          fullWidth
          loading={isLoading}
          onClick={onModalOpen}
          size="large"
        >
          {t("booking.request")}
        </Button>
      </div>
      <div className="hidden lg:block">
        <HotelReserveBookingCardWrapper>
          <HotelReserveBookingCardDetail {...properties} />
        </HotelReserveBookingCardWrapper>
      </div>
      <div className="lg:hidden">
        <Modal
          closeIcon="arrow_forward"
          headerStyle="block h-[64px]"
          onClose={onModalClose}
          open={isReservePageOpen}
          showCloseButton
        >
          <HotelReserveBookingCardWrapper classes="flex flex-col justify-between">
            <HotelReserveBookingCardDetail {...properties} />
          </HotelReserveBookingCardWrapper>
        </Modal>
      </div>
    </>
  );
};

export const HotelReserveBookingCard = memo(Component, deepCompare);
