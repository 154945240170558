import { useTranslation } from "@website/locale";
import { Button } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelReservedCardContentPropertiesType } from "../hotel-reserve-card-content/hotel-reserved-card-content.types";

const Component: FC<
  Pick<HotelReservedCardContentPropertiesType, "onClick" | "id">
> = (properties) => {
  const { onClick, id } = properties;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center gap-4 p-4 lg:max-h-[64px] lg:flex-row lg:justify-end lg:gap-6">
      <div className="text-on-surface-high-emphasis text-caption">
        {t("hotel.room.check_availability")}
      </div>
      <Button
        className="w-full lg:w-[118px]"
        color="secondary"
        onClick={() =>
          onClick
            ? onClick({ roomId: id, tabSelected: "roomCalendar" })
            : undefined
        }
        size="small"
        variant="outline"
      >
        {t("hotel.room.available_calendar")}
      </Button>
    </div>
  );
};
export const HotelReserveCardFullCapacity = memo(Component, deepCompare);
