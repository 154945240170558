import { isInvalidCheckin } from "@website/utils";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { HotelReservedBundleFooterPropertiesType } from "./hotel-reserved-bundle-footer.types";

export const useHotelReserveCardFooter = ({
  onClick,
  cancellationPolicies
}: HotelReservedBundleFooterPropertiesType) => {
  const router = useRouter();

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const isInvalid = isInvalidCheckin(
    router.query.date_from,
    router.query.date_to,
    false
  );

  const openDatePicker = useCallback(() => {
    const search_date_range = document.querySelector<HTMLDivElement>(
      `[data-testid="search_date_range"]`
    );
    search_date_range?.scrollIntoView({ block: "center" });
    search_date_range?.click();
    setTimeout(
      () =>
        document
          .querySelector<HTMLInputElement>(
            `[data-testid="date_picker_start_input"]`
          )
          ?.click(),
      200
    );
  }, []);

  const onHandleClick = useCallback(() => {
    if (isInvalid) {
      openDatePicker();
    } else {
      setLoading(true);
      onClick?.()?.finally?.(() => setLoading(false));
    }
  }, [isInvalid, onClick, openDatePicker]);

  return {
    onHandleClick,
    loading,
    openDatePicker,
    modalProperties: {
      open,
      onClose: () => setOpen(false),
      cancellationPolicies
    },
    setOpen
  };
};
