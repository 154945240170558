import { useIsVisible } from "@website/hooks";
import { addEventToTracking, USER_TRACKING_EVENTS } from "@website/tracking";
import type { NextPage } from "next";
import { useCallback, useEffect, useState } from "react";
import styles from "./popular-city.module.scss";
import { PopularCityPropertiesType } from "./popular-city.types";

const PopularCity: NextPage<PopularCityPropertiesType> = (properties) => {
  const { item } = properties;
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const { reference, inView } = useIsVisible({});
  const [viewed, setViewd] = useState(false);

  const onClickCity = useCallback(
    (item: any) => {
      addEventToTracking(USER_TRACKING_EVENTS.GENERAL_SEARCH, {
        city: item?.state,
        product: "dom-hotel",
        search_source: "popular-cities"
      });
      window?.webengage?.track("hotel-search-clicked", {
        ...item,
        channel,
        source: "popularCity"
      });
    },
    [channel]
  );

  useEffect(() => {
    if (inView && !viewed) {
      setViewd(true);
      typeof window !== "undefined" &&
        window.dataLayer?.push?.({
          event: "view_item_list",
          ecommerce: {
            items: [
              {
                item_name: item.state,
                item_list_name: "popular cities hotels",
                index: item.id
              }
            ]
          }
        });
    }
  }, [inView, item.id, item.state, viewed]);

  const renderHref = useCallback((item: any) => item.href, []);

  return (
    <a
      className={styles["item-wrapper"]}
      href={renderHref(item)}
      onClick={onClickCity && (() => onClickCity(item))}
    >
      <div
        className={styles["item"]}
        ref={reference as React.RefObject<HTMLImageElement>}
      >
        <img
          alt={item.alt}
          className={styles["item-image"]}
          height={item.height}
          src={`${process.env.NEXT_PUBLIC_BASE_URL_CDN}/${item.image}`}
          width={item.width}
        />
        <div className={styles["item-state"]}>هتل های {item.state}</div>
      </div>
    </a>
  );
};

export default PopularCity;
