import { CollapsibleText } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { orderBy } from "lodash";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { useSeoContent } from "./hotel-seo-content.hook";
import { HotelSeoContentPropertiesType } from "./hotel-seo-content.types";
import { HotelVideoPlayer } from "./hotel-video-player";

const Component: FC<HotelSeoContentPropertiesType> = (properties) => {
  const router = useRouter();
  const { list } = useSeoContent(properties);

  if (!list?.length) return null;

  return (
    <div className="my-12 flex flex-col space-y-12">
      {orderBy(list, [(item) => [item.videoUrl, item.imageUrl]], ["desc"]).map(
        (item) => (
          <div
            className={`${
              item.imageUrl || item.videoUrl
                ? "border-outline-thin bg-background flex flex-col gap-6 rounded-[16px] border p-4"
                : ""
            } ${item.imageUrl && !item.videoUrl ? "md:flex-row" : ""}`}
          >
            {item.imageUrl && !item.videoUrl && (
              <div className="relative h-[144px] overflow-hidden rounded-[8px] md:h-[unset] md:max-h-[288px] md:w-[288px] md:min-w-[288px] md:max-w-[288px]">
                <img
                  alt=""
                  className="block size-full object-cover md:absolute"
                  src={`${process.env.NEXT_PUBLIC_BASE_URL_CDN}/${item.imageUrl}`}
                />
              </div>
            )}

            {item.videoUrl && (
              <HotelVideoPlayer
                className="bg-on-surface-high-emphasis relative overflow-hidden rounded-[8px] pt-[56.25%] md:min-h-[238px] lg:h-[400px] lg:pt-0"
                poster={item.imageUrl}
                src={`${process.env.NEXT_PUBLIC_BASE_URL_CDN}/${item.videoUrl}`}
              />
            )}

            <CollapsibleText
              ariaLabel={`seo-content_${item.title}`}
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: numberConvert(item.content, {
                      locale: router.locale
                    })
                  }}
                />
              }
              key={item.title}
              title={numberConvert(item.title, { locale: router.locale })}
              withMedia={Boolean(item.imageUrl || item.videoUrl)}
            />
          </div>
        )
      )}
    </div>
  );
};

export const HotelSeoContent = memo(Component, deepCompare);
