import { useTranslation } from "@website/locale";
import { deepCompare, numberConvert } from "@website/utils";
import { isNumber } from "lodash";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { useHotelCardStars } from "./hotel-card-stars.hook";
import { HotelCardStarsPropertiesType } from "./hotel-card-stars.types";

const Component: FC<HotelCardStarsPropertiesType> = (properties) => {
  const router = useRouter();
  const {
    stars,
    showText = true,
    color = "default",
    size = "responsive",
    starSize = "small"
  } = properties;
  const { hotelStars } = useHotelCardStars(properties);
  const { t } = useTranslation();

  return isNumber(stars) && stars > 0 ? (
    <div
      className={
        size === "xSmall"
          ? "flex items-center"
          : size === "small"
            ? "flex h-4 items-center"
            : "flex h-4 items-center lg:h-6"
      }
      itemProp="starRating"
      itemScope
      itemType="http://schema.org/Rating"
    >
      <meta content={`${stars}`} itemProp="ratingValue" />
      {hotelStars}
      {showText && (
        <div
          className={`mx-1 ${
            color === "neutral"
              ? "text-on-surface-medium-emphasis"
              : "text-on-surface-high-emphasis"
          }`}
        >
          {size !== "xSmall" && (
            <span
              className={
                starSize === "medium"
                  ? "text-caption lg:text-body-1"
                  : "text-overline lg:text-caption"
              }
            >
              {t("hotel_card.star_count", {
                count: numberConvert(stars, {
                  locale: router.locale
                })
              })}
            </span>
          )}
        </div>
      )}
    </div>
  ) : null;
};

export const HotelCardStars = memo(Component, deepCompare);
