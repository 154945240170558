import { useTranslation } from "@website/locale";
import { Button, CounterButton, MiniChips } from "@website/ui";
import { deepCompare, numberConvert, numberSeparator } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { useHotelReserveCardFooter } from "./hotel-reserve-card-footer.hooks";
import { HotelReservedCardFooterTypesPropertiesType } from "./hotel-reserved-card-footer.types";

const Component = (properties: HotelReservedCardFooterTypesPropertiesType) => {
  const {
    availableRooms,
    price,
    id,
    nightCount,
    minStay = 1,
    priceOff,
    rackIncluded,
    onRacksClick,
    isRoomRacksLoading,
    hotelType,
    view = "default"
  } = properties;
  const router = useRouter();
  const {
    roomCount,
    setRoomCount,
    roomSelect,
    onHandleClick,
    loading,
    openDatePicker,
    handleRecommendedHotel
  } = useHotelReserveCardFooter(properties);
  const { t } = useTranslation();

  return (
    <div
      className={`flex items-center justify-between pb-4 ${
        view === "mobile"
          ? "flex-col pt-3"
          : "flex-col pt-3 lg:min-h-[68px] lg:flex-row lg:px-4 lg:pt-4"
      }`}
    >
      <div className="flex w-full flex-row flex-wrap items-center justify-between">
        <div
          className={`${
            view === "mobile"
              ? "hidden"
              : "text-caption hidden lg:order-1 lg:me-4 lg:block"
          }`}
        >
          {t("global.rooms_count")}
        </div>
        <div
          className={`text-caption mr-4 ltr:ml-4 ${
            view === "mobile" ? "" : "lg:order-3 lg:m-0"
          }`}
        >
          {t("hotel.room.price_for_night", {
            count: numberConvert(nightCount, {
              locale: router.locale
            })
          })}
        </div>
        <div
          className={`text-subtitle-1 ml-4 ltr:mr-4 ${
            view === "mobile" ? "" : "lg:order-4 lg:mr-4 lg:ltr:ml-0"
          }`}
        >
          {price > priceOff && (
            <div className="flex flex-row items-center justify-end gap-2">
              <span className="text-body-2 text-on-surface-medium-emphasis line-through">
                {numberConvert(numberSeparator(price), {
                  locale: router.locale
                })}
              </span>
              <MiniChips
                color="primary"
                text={`${numberConvert(
                  (((price - priceOff) / price) * 100).toFixed(0),
                  {
                    locale: router.locale
                  }
                )}%`}
                variant="contained"
              />
            </div>
          )}
          <div>
            {numberConvert(
              numberSeparator(price - priceOff ? priceOff : price),
              {
                locale: router.locale
              }
            )}
            <span className="text-subtitle-2 px-1">
              {t("global.tooman_currency")}
            </span>
          </div>
        </div>
        <div
          className={`bg-outline-thin mb-4 mt-3 block h-px w-full ${
            view === "mobile" ? "" : "lg:hidden"
          }`}
        />
        <CounterButton
          className={`ms-4 !h-[36px] gap-0 ${
            view === "mobile" ? "" : "lg:order-2 lg:m-0 lg:flex-auto"
          }`}
          color="secondary"
          decreaseDisabled={roomCount <= 1}
          increaseDisabled={roomCount >= availableRooms}
          locale="fa"
          onChange={setRoomCount}
          size="small"
          value={roomCount}
          variant="outline"
        />
        {Number(nightCount) >= Number(minStay) ? (
          rackIncluded ? (
            <Button
              className={
                view === "mobile"
                  ? "ml-4 w-[114px] ltr:mr-4"
                  : "ml-4 w-[114px] lg:order-5 lg:col-span-2 lg:m-0 ltr:mr-4"
              }
              loading={isRoomRacksLoading}
              onClick={onRacksClick}
            >
              {t("hotel.room.select_package")}
            </Button>
          ) : (
            <Button
              className={
                view === "mobile"
                  ? "ml-4 w-[133px] ltr:mr-4"
                  : "ml-4 w-[133px] lg:order-5 lg:col-span-2 lg:m-0 lg:w-[94px] ltr:mr-4"
              }
              loading={id === roomSelect ? loading : false}
              onClick={onHandleClick}
              size="small"
            >
              {t("hotel.room.reserve_room", {
                count: numberConvert(roomCount, {
                  locale: router.locale
                })
              })}
            </Button>
          )
        ) : (
          <div
            className={`order-5 mt-2 flex w-full flex-col px-4 ${
              view === "mobile" ? "" : "lg:flex-row lg:items-center lg:px-0"
            }`}
          >
            <div
              className={`text-body-2 text-primary mb-2 flex-auto ${
                view === "mobile" ? "" : "lg:m-0"
              }`}
            >
              {minStay === 20
                ? t("hotel.room.min_room_count_twenty", {
                    hotelType
                  })
                : t("hotel.room.min_room_count", {
                    count: numberConvert(minStay, {
                      locale: router.locale
                    })
                  })}
            </div>
            <div className="flex flex-row gap-2">
              <Button
                className={view === "mobile" ? "w-full" : "w-full lg:w-[94px]"}
                onClick={openDatePicker}
                size="small"
              >
                {t("hotel.room.change_data")}
              </Button>
              <Button
                className={view === "mobile" ? "w-full" : "w-full lg:w-[122px]"}
                onClick={handleRecommendedHotel}
                size="small"
              >
                {t("global.similar_hotels")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export const HotelReserveCardFooter = memo(Component, deepCompare);
