import { useCallback, useState } from "react";

export const useHotelDraftPrepaymentPopup = () => {
  const [inView, setInView] = useState(false);
  const handleInView = useCallback((entry: boolean) => setInView(entry), []);
  return {
    inView,
    handleInView
  };
};
