import { useIsVisible } from "@website/hooks";
import { useState } from "react";
import { HotelSearchWrapperViewPropertiesType } from "./hotel-search-wrapper.types";

export const useHotelSearchWrapperContent = (
  properties: HotelSearchWrapperViewPropertiesType
) => {
  const { isSearchPanelOpen, setIsSearchPanelOpen } = properties;
  const [isSearchContainerScrolled, setIsSearchContainerScrolled] =
    useState(false);

  const { reference: scrolledReference } = useIsVisible({
    onChange: (inView) => setIsSearchContainerScrolled(!inView)
  });

  return {
    scrolledReference,
    isSearchPanelOpen,
    isSearchContainerScrolled,
    openSearchPanel: setIsSearchPanelOpen.bind(null, true),
    closeSearchPanel: setIsSearchPanelOpen.bind(null, false)
  };
};
