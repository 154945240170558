import { LazyImage } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import SearchNoResult from "../images/search-no-result.png";
import { useVillaSearchNoResult } from "./villa-search-no-result.hook";
import { VillaSearchNoResultPropertiesType } from "./villa-search-no-result.types";

const VillaSearchNoResult: FC<VillaSearchNoResultPropertiesType> = (
  properties
) => {
  const { resultTitle, notFoundtitle, firstDescription, secondDescription } =
    useVillaSearchNoResult(properties);

  return (
    <div className="my-6 flex flex-col items-center px-6">
      <div className="text-subtitle-2 text-on-surface-medium-emphasis w-full text-right">
        {resultTitle}
      </div>
      <LazyImage alt="no result" loading="eager" src={SearchNoResult.src} />
      <div className="text-subtitle-1 text-on-surface-high-emphasis mt-6">
        {notFoundtitle}
      </div>
      <div className="text-caption text-on-surface-medium-emphasis mt-2">
        {firstDescription}
      </div>
      <div className="text-caption text-on-surface-medium-emphasis mt-2">
        {secondDescription}
      </div>
    </div>
  );
};

const VillaSearchNoResultMemoized = memo(VillaSearchNoResult, deepCompare);
export { VillaSearchNoResultMemoized as VillaSearchNoResult };
