import { MaterialIcon } from "@website/icons";
import { MiniChips } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { ReserveCommentRating } from "@website/widget";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { useHotelCardRating } from "./hotel-card-rating.hook";
import { HotelCardRatingPropertiesType } from "./hotel-card-rating.types";

const Component: FC<HotelCardRatingPropertiesType> = (properties) => {
  const router = useRouter();
  const { size, rating } = properties;
  const { shouldRender, reviewCount, badgeColor, badgeText } =
    useHotelCardRating(properties);

  return shouldRender ? (
    <span className="flex items-center gap-1">
      {size === "xSmall" ? (
        <ReserveCommentRating rate={Number(rating)} size="small" />
      ) : (
        <MiniChips
          StartIcon={<MaterialIcon className="text-base" name="stars" />}
          color={badgeColor}
          size="medium"
          text={badgeText}
          variant="accent"
        />
      )}

      {size !== "xSmall" && (
        <span className="text-overline lg:text-caption text-on-surface-medium-emphasis">
          ({numberConvert(reviewCount, { locale: router.locale })})
        </span>
      )}
    </span>
  ) : null;
};

export const HotelCardRating = memo(Component, deepCompare);
