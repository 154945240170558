import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, Skeleton } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { HotelListHeaderPropertiesType } from "./hotel-list-header.types";
import { HotelSortView } from "./hotel-sort-view";

const Component: FC<HotelListHeaderPropertiesType> = (properties) => {
  const router = useRouter();
  const {
    loading,
    totalItem,
    city,
    sortProperties,
    selectedFiltersCount,
    setFilterOpen,
    hasFilter = true,
    hasCityPrefix = true
  } = properties;
  const { t } = useTranslation();
  const buttonProperties = {
    variant: "round-outline" as const,
    color: "neutral" as const,
    size: "small" as const,
    StartIcon: <MaterialIcon className="text-sm" name="tune" />,
    children: t("global.filters_count", {
      count: selectedFiltersCount
        ? numberConvert(selectedFiltersCount, {
            locale: router.locale
          })
        : ""
    }),
    onClick: () => setFilterOpen && setFilterOpen((state) => !state)
  };

  return (
    <div className="border-outline-thin bg-on-color-high-emphasis before:bg-on-color-high-emphasis relative -mt-5 mb-10 flex flex-wrap items-center justify-start gap-2 border border-b-0 p-3 px-0 before:absolute before:-right-6 before:top-0 before:block before:h-full before:w-screen lg:m-0 lg:justify-between lg:rounded-lg lg:border-b lg:px-3 lg:before:hidden lg:before:bg-transparent xl:justify-start">
      <h2
        className="text-on-surface-high-emphasis absolute bottom-[-45px] right-0 w-full flex-1 font-medium md:w-auto lg:static"
        data-testid="header_title"
      >
        {city ? (
          loading ? (
            t<string>(
              `hotel_cities.${process.env.NEXT_PUBLIC_APP_NAME}.searching_in_city_title`,
              { cityTitle: city }
            )
          ) : (
            t<string>(
              hasCityPrefix
                ? `hotel_cities.${process.env.NEXT_PUBLIC_APP_NAME}.residence_count_with_city_title`
                : `hotel_cities${process.env.NEXT_PUBLIC_APP_NAME}.residence_count_without_city_title`,
              {
                cityTitle: city,
                count: numberConvert(totalItem, {
                  locale: router.locale
                })
              }
            )
          )
        ) : (
          <div className="h-6">
            <Skeleton shape="rectangle" />
          </div>
        )}
      </h2>

      {hasFilter && <Button {...buttonProperties} className="xl:hidden" />}
      <HotelSortView {...sortProperties} />
    </div>
  );
};

export const HotelListHeader = memo(Component, deepCompare);
