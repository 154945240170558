import { useIsVisible } from "@website/hooks";
import { deepCompare } from "@website/utils";
import { debounce, omit } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, Ref, memo, useCallback, useRef, useState } from "react";
import { HotelCardAction } from "./hotel-card-action";
import { HotelCardPricing } from "./hotel-card-action/hotel-card-pricing";
import { HotelCardHeader } from "./hotel-card-availability";
import { HotelCardInfo } from "./hotel-card-info";
import { HotelCardThumbnail } from "./hotel-card-thumbnail";
import { HotelCardPropertiesType } from "./hotel-card.types";

const HotelCardDivider: FC<Pick<HotelCardPropertiesType, "size">> = memo(
  ({ size }) => (
    <div
      className={
        size === "small"
          ? "bg-outline-thin h-px w-full"
          : "bg-outline-thin h-px w-full md:h-full md:w-px"
      }
    />
  )
);

const Component: FC<HotelCardPropertiesType> = (properties) => {
  const startTime = useRef(Date.now());
  const [routeLoading, setRouteLoading] = useState(false);
  const {
    className,
    loading,
    hotelInfo,
    sliderFallback,
    isLazyImage,
    onVisibilityChange,
    size = "responsive",
    index,
    onClick,
    replace
  } = properties;

  const {
    hotelDetail,
    hotelOffers,
    thumbnail,
    pricing,
    reservationInfo,
    primaryBadge,
    regularBadges,
    advertisementBadge
  } = hotelInfo;
  const router = useRouter();

  const getOnChange = debounce((inView: boolean) => {
    if (inView) {
      startTime.current = Date.now();

      onVisibilityChange?.({ hotelInfo }, index || 0, true);
    } else {
      const duration = Date.now() - startTime.current;
      if (duration > 250) {
        onVisibilityChange?.({
          duration,
          hotelID: hotelInfo.id,
          cityID: hotelInfo.hotelDetail?.cityId
        });
      }
      startTime.current = 0;
    }
  }, 1000);

  const { reference } = useIsVisible({
    onChange: getOnChange
  });

  const wrappedOnClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!router.isReady) {
        return event.preventDefault();
      }
      setRouteLoading(true);
      onClick?.();
    },
    [router, onClick]
  );

  return (
    <Link
      className={`bg-on-color-high-emphasis shadow-1 flex size-full cursor-pointer overflow-hidden md:flex-row ${
        size === "small" ? "md:flex-col" : ""
      } ${
        size === "xSmall"
          ? "h-[112px] w-[261.33331px] gap-2 rounded-[8px] p-2"
          : "min-w-[180px] flex-col rounded-xl md:min-h-[234px] md:max-w-full"
      } ${className}`}
      href={{
        pathname: hotelDetail?.hotelPageUrl?.pathname,
        query: {
          ...omit(router.query, "categoryTitle"),
          ...(hotelDetail?.hotelPageUrl?.query as object)
        }
      }}
      itemScope
      itemType="http://schema.org/Hotel"
      onClick={wrappedOnClick}
      prefetch={false}
      ref={reference as unknown as Ref<HTMLAnchorElement>}
      replace={replace}
      role="link"
      style={
        primaryBadge?.color && size !== "xSmall"
          ? { border: `2px solid ${primaryBadge?.color}` }
          : {}
      }
      title={hotelDetail?.hotelTitle}
    >
      <HotelCardThumbnail
        fallback={sliderFallback}
        isLazyImage={isLazyImage}
        primaryBadge={primaryBadge}
        size={size}
        specialOffer={hotelOffers?.specialOffer}
        thumbnail={thumbnail}
        title={hotelDetail?.hotelTitle}
      />
      <div
        className={`flex min-w-0 shrink grow flex-col ${
          size === "xSmall" ? "h-full" : ""
        }`}
      >
        {size !== "xSmall" && (
          <HotelCardHeader
            isAvailable={hotelDetail?.isAvailable}
            primaryBadge={primaryBadge}
          />
        )}
        <div
          className={
            size === "xSmall"
              ? "flex h-full flex-col"
              : size === "small"
                ? "flex h-full flex-col"
                : "flex h-full flex-col md:flex-row"
          }
        >
          <HotelCardInfo
            {...hotelDetail}
            advertisementBadge={advertisementBadge}
            offers={hotelOffers?.offers || []}
            regularBadges={regularBadges}
            size={size}
          />
          <HotelCardDivider size={size} />
          {size === "xSmall" ? (
            <HotelCardPricing {...pricing} size={size} />
          ) : (
            <HotelCardAction
              clickLoading={routeLoading}
              loading={loading}
              pricingInfo={pricing}
              reservationInfo={reservationInfo}
              size={size}
            />
          )}
        </div>
      </div>
    </Link>
  );
};

export const HotelCard = memo(Component, deepCompare);
