import { useTranslation } from "@website/locale";
import { Button, LazyImage, Modal } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { toString } from "lodash";
import { useRouter } from "next/router";
import { FC, memo, useMemo } from "react";
import { useCountDownTimer } from "../../../hotel-last-second/count-down-timer/count-down-timer.hook";
import { paymentStatusConstant } from "./payment-message.constant";
import { PaymentMessagePropertiesType } from "./payment-message.type";
const Component: FC<PaymentMessagePropertiesType> = (properties) => {
  const {
    paymentStatus,
    paymentExpired,
    trackingCode,
    className = "",
    children,
    confirmationPaymentTime,
    onTimeEnd
  } = properties;

  const paymentStatusInfo = paymentStatusConstant[paymentStatus];

  const router = useRouter();
  const { t } = useTranslation();

  const differenceFrom = useMemo(() => {
    if (!confirmationPaymentTime) return;

    return (
      confirmationPaymentTime * 1000 +
      new Date().getTimezoneOffset() * 60 * 1000 +
      30 * 60 * 1000
    );
  }, [confirmationPaymentTime]);

  const { timeLeft } = useCountDownTimer({
    onTimeEnd,
    differenceFrom,
    stopTimer: false,
    resetOnEnd: true
  });

  if (!paymentStatusInfo) return;

  return (
    <>
      <div
        className={`md:border-outline-thin flex w-full flex-col gap-6 rounded-lg md:flex-row md:border md:py-6 ${className}`}
      >
        <LazyImage
          alt={paymentStatusInfo.alt}
          className="h-[124px] w-[159px] self-center px-6 md:self-start md:pl-0"
          loading="eager"
          src={paymentStatusInfo.src}
        />
        <div className="flex h-full flex-col justify-between">
          <div className="w-full px-6 md:pr-0">
            <div
              className={`text-error text-subtitle-1 mb-2 text-center md:text-start ${
                paymentStatus === "failed" ||
                paymentStatus === "availability_rejected"
                  ? "text-error"
                  : "text-success"
              }`}
            >
              {paymentStatusInfo.title}
            </div>
            <p
              className="text-on-surface-medium-emphasis text-body-2 mb-2 text-center md:text-start"
              dangerouslySetInnerHTML={{
                __html: paymentStatusInfo.description
              }}
            />
            {paymentStatus === "availability_pending" && timeLeft && (
              <div className="flex flex-col gap-4 pb-[62px] pt-8 md:gap-2 md:pb-6 md:pt-3">
                <div className="flex flex-col gap-3">
                  <div className="flex justify-center gap-2 md:justify-start">
                    <div
                      className="text-button text-on-surface-high-emphasis"
                      dir="ltr"
                    >
                      <span>{timeLeft?.minutes}</span>
                      <span>:</span>
                      <span>{timeLeft?.seconds}</span>
                    </div>
                    <div className="text-extra-caption text-on-surface-high-emphasis">
                      دقیقه مانده تا تعیین وضعیت رزرو
                    </div>
                  </div>
                  <div className="bg-states-neutral-hover mx-auto h-[15px] w-[260px] overflow-hidden rounded-[100px] md:mx-0">
                    <div
                      className="bg-on-surface-secondary-medium-emphasis h-full rounded-[100px] transition-all duration-1000 ease-in"
                      style={{
                        width: `${
                          100 -
                          ((timeLeft?.originalTime || 0) / (30 * 60)) * 100
                        }%`
                      }}
                    />
                  </div>
                </div>
                <p className="text-body-2 text-on-surface-medium-emphasis text-center">
                  درصورت رزرو ناموفق هزینه کسر شده، حداکثر طی ۳۰ دقیقه آینده به
                  کارت شما واریز میشود.
                </p>
              </div>
            )}

            <div className="text-on-surface-high-emphasis text-subtitle-2 mb-6 whitespace-pre-line text-center md:text-start">
              {t("payment.tracking_code", {
                code: numberConvert(trackingCode, {
                  locale: router.locale
                })
              })}
            </div>
          </div>
          {children}
        </div>
      </div>
      {paymentExpired && paymentStatus === "failed" && (
        <Modal
          className="flex gap-6"
          dialogClassName="lg:max-w-[448px]"
          mobileHeight="auto"
          open
          title={t("payment.expiration_popup_title")}
        >
          <div className="text-body-2 text-on-surface-high-emphasis">
            {t("payment.expiration_popup_text")}
          </div>
          <Button
            className="mt-4"
            color="secondary"
            onClick={() =>
              (window.location.href = toString(
                process.env.NEXT_PUBLIC_BASE_URL_B2C
              ))
            }
            size="large"
          >
            {t("payment.expiration_popup_button")}
          </Button>
        </Modal>
      )}
    </>
  );
};

export const PaymentMessage = memo(Component, deepCompare);
