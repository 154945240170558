import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, MiniChips } from "@website/ui";
import { deepCompare, numberConvert, numberSeparator } from "@website/utils";
import { isBoolean, isEmpty } from "lodash";
import { useRouter } from "next/router";
import { memo } from "react";
import { HotelReserveCardCancellationModal } from "../../../hotel-reserve-rooms-layout/hotel-reserve-card/hotel-reserve-card-cancellation-modal";
import { useHotelReserveCardFooter } from "./hotel-reserve-bundle-footer.hooks";
import { HotelReservedBundleFooterPropertiesType } from "./hotel-reserved-bundle-footer.types";

const Component = (properties: HotelReservedBundleFooterPropertiesType) => {
  const {
    price,
    nightCount,
    priceOff,
    roomsCount,
    freeCancellation,
    cancellationPolicies
  } = properties;
  const router = useRouter();
  const { onHandleClick, loading, modalProperties, setOpen } =
    useHotelReserveCardFooter(properties);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-between pb-4 pt-3 lg:min-h-[68px] lg:flex-row lg:px-4 lg:pt-4">
      <div className="flex w-full flex-row flex-wrap items-center justify-between lg:justify-end">
        <div className="order-2 mr-4 lg:order-1 lg:ml-auto lg:mr-0">
          {isBoolean(freeCancellation) &&
            (freeCancellation ? (
              isEmpty(cancellationPolicies) ? (
                <MiniChips
                  StartIcon={<MaterialIcon name="check" />}
                  color="success"
                  text={t("hotel.room.free_cancellation")}
                  variant="accent"
                />
              ) : (
                <MiniChips
                  className="cursor-pointer"
                  color="secondary"
                  onClick={() => setOpen(true)}
                  text={t("hotel.room.cancellation_policies")}
                  variant="text"
                />
              )
            ) : (
              <MiniChips
                StartIcon={
                  <MaterialIcon name="do_not_disturb" variant="outlined" />
                }
                color="error"
                text={t("hotel.room.non_refundable")}
                variant="accent"
              />
            ))}
        </div>
        <div className="text-caption mr-4 lg:order-3 lg:m-0 ltr:ml-4">
          {t("hotel.room.price_for_night", {
            count: numberConvert(nightCount, {
              locale: router.locale
            })
          })}
        </div>
        <div className="text-subtitle-1 ml-4 lg:order-4 lg:mr-4 ltr:mr-4 lg:ltr:ml-0">
          {price > priceOff && (
            <div className="flex flex-row items-center gap-2">
              <span className="text-body-2 text-on-surface-medium-emphasis line-through">
                {numberConvert(numberSeparator(price), {
                  locale: router.locale
                })}
              </span>
              <MiniChips
                color="primary"
                text={`${numberConvert(
                  (((price - priceOff) / price) * 100).toFixed(0),
                  {
                    locale: router.locale
                  }
                )}%`}
                variant="contained"
              />
            </div>
          )}
          <div>
            {numberConvert(
              numberSeparator(price - priceOff ? priceOff : price),
              {
                locale: router.locale
              }
            )}
            <span className="text-subtitle-2 px-1">
              {t("global.tooman_currency")}
            </span>
          </div>
        </div>
        <div className="bg-outline-thin mb-4 mt-3 block h-px w-full lg:hidden" />
        <Button
          className="order-2 ml-4 w-[133px] lg:order-5 lg:col-span-2 lg:m-0 lg:w-[94px] ltr:mr-4"
          loading={loading}
          onClick={onHandleClick}
          size="small"
        >
          {t("hotel.room.reserve_room", {
            count: numberConvert(roomsCount, {
              locale: router.locale
            })
          })}
        </Button>
      </div>
      <HotelReserveCardCancellationModal {...modalProperties} />
    </div>
  );
};
export const HotelReserveBundleFooter = memo(Component, deepCompare);
