import { useIsVisible } from "@website/hooks";
import { last, orderBy, toNumber, uniq } from "lodash";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { HotelPaginationPropertiesType } from "./hotel-pagination.types";

export const useLoadMore = (properties: HotelPaginationPropertiesType) => {
  const { totalCount, pages, setPages } = properties;
  const { reference } = useIsVisible({
    onChange: (inView) => {
      const currentPage = toNumber(last(pages) || 1);
      const hasMorePage = toNumber(totalCount) > currentPage;
      if (inView && hasMorePage) {
        setPages((state) => orderBy(uniq([...state, currentPage + 1])));
      }
    }
  });

  return { reference };
};

export const usePagination = (properties: HotelPaginationPropertiesType) => {
  const router = useRouter();
  const { loading, setPages } = properties;

  useEffect(() => {
    if (!router.isReady) return;
    setPages([1]);
  }, [router, setPages]); // TODO: use filters instead of router

  return { loading };
};
