import { MaterialIcon } from "@website/icons";
import { Button, Modal } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useSearchUpdateModal } from "./hotel-update-search-modal.hook";
import { HotelUpdateSearchModalType } from "./hotel-update-search-modal.types";

const Component: FC<HotelUpdateSearchModalType> = (properties) => {
  const { title, message, buttonProps, modalProps } =
    useSearchUpdateModal(properties);
  return (
    <Modal {...modalProps}>
      <div className="lg:w-[400px]">
        <div className="flex">
          <MaterialIcon
            className="text-on-surface-medium-emphasis text-xl"
            name="history"
          />
          <h3 className="text-headline-6 pr-4">{title}</h3>
        </div>
        <div className="text-body-2 text-on-surface-high-emphasis pb-10 pt-6">
          {message}
        </div>
        <Button {...buttonProps} />
      </div>
    </Modal>
  );
};

export const HotelUpdateSearchModal = memo(Component, deepCompare);
