import { useViewPort } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import {
  Button,
  Chips,
  Dropdown,
  ListItem,
  MiniPopover,
  TextField
} from "@website/ui";
import { numberConvert } from "@website/utils";
import { toNumber } from "lodash";
import { FC, useMemo } from "react";
import { useHotelSearchPassengerCount } from "./hotel-search-passenger-count.hook";
import { HotelSearchPassengerCountPropertiesType } from "./hotel-search-passenger-count.types";

export const HotelSearchPassengerCount: FC<
  HotelSearchPassengerCountPropertiesType
> = (properties) => {
  const { t, locale } = useTranslation();
  const {
    searchType,
    size,
    passengerCount,
    onChangeChildAge,
    onAddRoom,
    onRemoveRoom,
    validCount
  } = properties;
  const { list, chipsProps, popoverProps, submitButtonProps } =
    useHotelSearchPassengerCount(properties);
  const { isMobile } = useViewPort();
  const ageList = useMemo(
    () => [
      {
        title: t("search.under_year", { year: 1 }),
        value: "1"
      },
      {
        title: t("search.between_m_and_n_years", { age_one: 1, age_two: 2 }),
        value: "2"
      },
      {
        title: t("search.between_m_and_n_years", { age_one: 2, age_two: 3 }),
        value: "3"
      },
      {
        title: t("search.between_m_and_n_years", { age_one: 3, age_two: 4 }),
        value: "4"
      },
      {
        title: t("search.between_m_and_n_years", { age_one: 4, age_two: 5 }),
        value: "5"
      },
      {
        title: t("search.between_m_and_n_years", { age_one: 5, age_two: 6 }),
        value: "6"
      },
      {
        title: t("search.between_m_and_n_years", { age_one: 6, age_two: 7 }),
        value: "7"
      },
      {
        title: t("search.between_m_and_n_years", { age_one: 7, age_two: 8 }),
        value: "8"
      },
      {
        title: t("search.between_m_and_n_years", { age_one: 8, age_two: 9 }),
        value: "9"
      },
      {
        title: t("search.between_m_and_n_years", { age_one: 9, age_two: 10 }),
        value: "10"
      },
      {
        title: t("search.between_m_and_n_years", { age_one: 10, age_two: 12 }),
        value: "11"
      }
    ],
    [t]
  );
  return (
    <div className="relative">
      {searchType === "hotel" ? (
        <TextField
          EndIcon={<MaterialIcon name="arrow_drop_down" />}
          StartIcon={<MaterialIcon name="group" variant="outlined" />}
          className="text-on-surface-medium-emphasis"
          fullWidth
          hasFocusStyle={!isMobile}
          inputMode="none"
          label={t("global.passenger_count")}
          onClick={chipsProps.onClick}
          size={size}
          value={chipsProps.text}
        />
      ) : (
        <Chips {...chipsProps} />
      )}
      <MiniPopover {...popoverProps}>
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="flex flex-1 overflow-hidden">
            <div className="flex-1 overflow-y-auto">
              {list.map((room, roomIndex) => (
                <div className="mb-4" key={room?.id}>
                  <div className="flex flex-row items-center justify-between">
                    <span className="text-subtitle-1 text-on-surface-high-emphasis mb-1 text-start ltr:pl-6 rtl:pr-6">
                      {`${room.title} ${numberConvert(roomIndex + 1, {
                        locale
                      })}`}
                    </span>
                    {list?.length > 1 && (
                      <Button
                        EndIcon={<MaterialIcon name="delete_outline" />}
                        children={t("search.delete_title")}
                        className="text-error flex justify-start gap-2 px-2 ltr:mr-5 rtl:ml-5"
                        color="neutral"
                        onClick={() => onRemoveRoom?.(roomIndex)}
                        variant="text"
                      />
                    )}
                  </div>
                  <div>
                    {room?.items?.map((item) => (
                      <div key={item.title}>
                        <ListItem key={item.title} {...item} />
                      </div>
                    ))}
                  </div>
                  <div className="mt-3 grid grid-cols-2 gap-3 px-6">
                    {passengerCount[roomIndex].child.map(
                      (age: number, index: number) => (
                        <div className="py-1" key={`child-${index + 1}`}>
                          <Dropdown
                            className="md:hidden"
                            error={age === -1}
                            fullWidth
                            label={t("search.child_age", {
                              num: index + 1
                            })}
                            list={ageList}
                            name="age"
                            onChange={(value: string) =>
                              onChangeChildAge?.(
                                toNumber(value),
                                roomIndex,
                                index
                              )
                            }
                            size="dense"
                            value={String(age) || ""}
                          />
                          <div
                            className={`border-on-surface-disable bg-background relative hidden h-[40px] w-full rounded-lg border-2 md:block ${
                              age === -1 ? "border-error" : ""
                            }`}
                          >
                            <MaterialIcon
                              className={`text-on-surface-medium-emphasis absolute top-[7px] size-6 overflow-hidden text-2xl leading-6 ltr:right-[4px] rtl:left-[4px] ${
                                age === -1 ? "text-error" : ""
                              }`}
                              name="arrow_drop_down"
                              variant="outlined"
                            />
                            <label>
                              {t("search.child_age", {
                                num: index + 1
                              })}
                            </label>
                            <select
                              className="text-on-surface-medium-emphasis text-body-1 relative z-20 size-full appearance-none rounded-lg bg-transparent px-3 py-2 outline-none"
                              name="age"
                              onChange={(event) =>
                                onChangeChildAge?.(
                                  toNumber(event.target.value),
                                  roomIndex,
                                  index
                                )
                              }
                              value={age}
                            >
                              {[
                                {
                                  title: t("search.child_age", {
                                    num: index + 1
                                  }),
                                  value: "-1"
                                },
                                ...ageList
                              ].map((item) => (
                                <option key={item.title} value={item.value}>
                                  {item.title}
                                </option>
                              ))}
                            </select>
                          </div>
                          {age === -1 && (
                            <div className="text-caption text-error px-4 pt-1">
                              {t("search.child_age_is_mandatory")}
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <div className="px-2">
                    {toNumber(room.items[0].value) === validCount?.maxAdult && (
                      <div className="text-caption text-error px-4 pt-1">
                        <MaterialIcon
                          className="text-body-2 ltr:mr-1 rtl:ml-1"
                          name="info"
                          variant="outlined"
                        />
                        {t("search.valid_max_adult_count", {
                          count: validCount?.maxAdult
                        })}
                      </div>
                    )}
                    {toNumber(room.items[1].value) === validCount?.maxChild && (
                      <div className="text-caption text-error px-4 pt-1">
                        <MaterialIcon
                          className="text-body-2 ltr:mr-1 rtl:ml-1"
                          name="info"
                          variant="outlined"
                        />
                        {t("search.valid_max_child_count", {
                          count: validCount?.maxChild
                        })}
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {validCount?.maxRoom && list.length < validCount.maxRoom && (
                <Button
                  StartIcon={<MaterialIcon name="add" />}
                  children={t("search.add_room")}
                  className="mx-6 -mt-2 px-2"
                  color="secondary"
                  onClick={onAddRoom}
                  variant="text"
                />
              )}
            </div>
          </div>
          <div className="px-6 pb-6 pt-5">
            <Button {...submitButtonProps}>
              {submitButtonProps.submitText}
            </Button>
          </div>
        </div>
      </MiniPopover>
    </div>
  );
};
