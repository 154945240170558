import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelSearchInternationalPropertiesType } from "./hotel-search-international.types";
import { HotelSearchPanel } from "./hotel-search-panel";
import { HotelSearchSummary } from "./hotel-search-summary";
import { HotelSearchWrapper } from "./hotel-search-wrapper";

const Component: FC<HotelSearchInternationalPropertiesType> = (properties) => {
  const {
    isSearchPanelOpen,
    setIsSearchPanelOpen,
    searchPanelProperties,
    searchSummaryProperties,
    searchType = "city"
  } = properties;
  return (
    <HotelSearchWrapper
      PanelElement={
        <HotelSearchPanel {...searchPanelProperties} searchType={searchType} />
      }
      SummaryElement={
        <HotelSearchSummary
          {...searchSummaryProperties}
          searchType={searchType}
        />
      }
      isSearchPanelOpen={isSearchPanelOpen}
      searchType={searchType}
      setIsSearchPanelOpen={setIsSearchPanelOpen}
    />
  );
};

export const HotelSearchInternational = memo(Component, deepCompare);
