import { DatepickerSelectedDateType } from "@website/ui";
import { useCallback, useState } from "react";
import { CityDataType } from "../../../hotel-search/hotel-search.types";
import {
  HandleSearchSubmitType,
  OccupantsInitialValuesType
} from "../hotel-search-panel.types";

export const useSubmit = (
  selectedDestinationItem: any,
  date: DatepickerSelectedDateType,
  passengerCount: OccupantsInitialValuesType,
  addToHistory: (
    destination: any,
    startDate: any,
    endDate: any,
    passengerNumber: any
  ) => void,
  submitValidationCheck: () => boolean,
  handleSearchSubmit?: HandleSearchSubmitType,
  initialDestinationItem?: CityDataType
) => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const onSubmitClick = useCallback(async () => {
    setSubmitLoading(true);

    if (submitValidationCheck()) {
      const startDate = date[0]?.dateTime;
      const endDate = date[1]?.dateTime;
      const destinationItem = selectedDestinationItem || initialDestinationItem;
      if (destinationItem && startDate && endDate) {
        addToHistory(destinationItem, startDate, endDate, passengerCount);

        await handleSearchSubmit?.(
          destinationItem.url,
          startDate,
          endDate,
          passengerCount
            .map((item) =>
              item.child.length > 0
                ? `${item.adult}-${item.child.join(",")}`
                : item.adult
            )
            ?.join("_"),
          destinationItem.isHotel
            ? destinationItem.cityName
            : destinationItem.name,
          destinationItem.isHotel,
          destinationItem.title,
          destinationItem.id
        );

        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } else {
      setSubmitLoading(false);
    }
  }, [
    submitValidationCheck,
    date,
    selectedDestinationItem,
    initialDestinationItem,
    addToHistory,
    passengerCount,
    handleSearchSubmit
  ]);

  return { onSubmitClick, submitLoading };
};
