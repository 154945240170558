import { deepCompare } from "@website/utils";
import { memo } from "react";
import { useHotelReserveBundelCard } from "./hotel-reserve-bundle-card.hook";
import { HotelReservedBundleCardPropertiesType } from "./hotel-reserve-bundle-card.types";
import { HotelReserveBundleContent } from "./hotel-reserve-bundle-content";
import { HotelReserveBundleFooter } from "./hotel-reserve-bundle-footer";

const Component = (properties: HotelReservedBundleCardPropertiesType) => {
  const {
    hotelReserveCardContentProps,
    hotelReserveCardFooterProps,
    reference
  } = useHotelReserveBundelCard(properties);

  return (
    <div
      className="bg-on-color-high-emphasis shadow-1 flex min-w-[218px] flex-col overflow-hidden rounded-xl xl:max-w-[1104px]"
      ref={reference}
    >
      <div className="flex flex-row">
        <div className="w-min grow">
          <HotelReserveBundleContent {...hotelReserveCardContentProps} />
          <div className="bg-outline-thin h-px w-full" />
          <HotelReserveBundleFooter {...hotelReserveCardFooterProps} />
        </div>
      </div>
    </div>
  );
};
export const HotelReserveBundleCard = memo(Component, deepCompare);
