import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { CollapsibleText } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { HotelReserveCancellationPoliciesPropertiesTypes } from "./hotel-reserve-cancellation-policies.types";

const Component = ({
  cancellationRules,
  hotelRules,
  title,
  direction
}: HotelReserveCancellationPoliciesPropertiesTypes) => {
  const router = useRouter();
  const { t } = useTranslation();
  if (!cancellationRules?.length && !hotelRules?.length) return null;

  return (
    <div className="flex flex-col gap-10">
      {(hotelRules?.length ?? 0) > 0 && (
        <div className="text-body-2 text-on-surface-high-emphasis">
          <CollapsibleText
            ariaLabel="rules"
            content={hotelRules?.map((item) => {
              const html = numberConvert(item, {
                locale: router.locale
              }).replaceAll("&nbsp;", "");
              return (
                <div
                  className="text-body-2 text-on-surface-high-emphasis flex flex-row items-start gap-1 pb-1"
                  hidden={!html}
                  key={html}
                >
                  <div className="text-on-surface-medium-emphasis">
                    <MaterialIcon name="check" />
                  </div>
                  <span dangerouslySetInnerHTML={{ __html: `${html}.` }} />
                </div>
              );
            })}
            direction={direction}
            itemsToShow={3}
            lessText={t("global.close")}
            lessTextIcon={<MaterialIcon name="arrow_drop_up" />}
            moreText={t("global.view_more")}
            moreTextIcon={<MaterialIcon name="arrow_drop_down" />}
            title={title || ""}
            titleStyle="text-subtitle-1 md:text-headline-6 mb-3 block text-right"
          />
        </div>
      )}
      {Boolean(cancellationRules?.length) && (
        <div className="text-body-2 text-on-surface-high-emphasis">
          <CollapsibleText
            ariaLabel="cancellation-rules"
            content={cancellationRules?.map((item) => {
              const html = numberConvert(item, {
                locale: router.locale
              }).replaceAll("&nbsp;", "");
              return (
                <div
                  className="text-body-2 text-on-surface-high-emphasis flex flex-row items-start gap-1 pb-1"
                  hidden={!html}
                  key={html}
                >
                  <div className="text-on-surface-medium-emphasis">
                    <MaterialIcon name="check" />
                  </div>
                  <span dangerouslySetInnerHTML={{ __html: `${html}.` }} />
                </div>
              );
            })}
            itemsToShow={3}
            lessText={t("global.close")}
            lessTextIcon={<MaterialIcon name="arrow_drop_up" />}
            moreText={t("global.view_more")}
            moreTextIcon={<MaterialIcon name="arrow_drop_down" />}
            title={t("footer.features.cancellation")}
            titleStyle="text-subtitle-1 md:text-headline-6 mb-3 block text-right"
          />
        </div>
      )}
    </div>
  );
};
export const HotelReserveCancellationPolicies = memo(Component, deepCompare);
