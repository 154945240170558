import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { VillaSearchPanel } from "./villa-search-panel";
import { VillaSearchSummary } from "./villa-search-summary";
import { VillaSearchWrapper } from "./villa-search-wrapper";
import { VillaSearchPropertiesType } from "./villa-search.types";

const Component: FC<VillaSearchPropertiesType> = (properties) => {
  const {
    isSearchPanelOpen,
    setIsSearchPanelOpen,
    searchPanelProperties,
    searchSummaryProperties,
    searchType = "city"
  } = properties;

  return (
    <VillaSearchWrapper
      PanelElement={
        <VillaSearchPanel {...searchPanelProperties} searchType={searchType} />
      }
      SummaryElement={
        <VillaSearchSummary
          {...searchSummaryProperties}
          searchType={searchType}
        />
      }
      isSearchPanelOpen={isSearchPanelOpen}
      searchType={searchType}
      setIsSearchPanelOpen={setIsSearchPanelOpen}
    />
  );
};

export const VillaSearch = memo(Component, deepCompare);
