import { MaterialIcon } from "@website/icons";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { HotelReservedCardType } from "../../hotel-reserve-room-layout.types";

const Component = ({
  facilities
}: Pick<HotelReservedCardType, "facilities">) => {
  const router = useRouter();
  return (
    <div className="p-6">
      {facilities?.map((facility) => (
        <div
          className="text-subtitle-2 flex flex-row items-center gap-4 py-2"
          key={facility.title}
        >
          <div>
            <MaterialIcon name="check_box" variant="outlined" />
          </div>
          <span>
            {numberConvert(facility.title, { locale: router.locale })}
          </span>
        </div>
      ))}
    </div>
  );
};
export const HotelReserveCardDetailFacilities = memo(Component, deepCompare);
