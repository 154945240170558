import { AxiosResponse } from "axios";
import { AxiosNoAuth } from "../../../../../../utils";
import { SuggestionResponseType } from "./top-hotels.types";

export const SuggestionUrl = `${process.env.NEXT_PUBLIC_BASE_URL_HAPI}/service2/hotel/v1/suggest/top-hotels`;

export const SuggestionService = (): Promise<SuggestionResponseType> =>
  AxiosNoAuth.get(SuggestionUrl)
    .then(({ data }: AxiosResponse<SuggestionResponseType>) => data)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("SuggestionService", SuggestionUrl, error);
      return [];
    });
