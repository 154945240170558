import { useTranslation } from "@website/locale";
import { HotelReserveCardCancellationModalType } from "./hotel-reserve-card-cancellation-modal.types";

export const useHotelreservecardCancellationModal = (
  properties: HotelReserveCardCancellationModalType
) => {
  const { onClose, open } = properties;
  const { t } = useTranslation();
  return {
    modalProps: {
      open: open,
      onClose: onClose,
      showCloseButton: false,
      mobileHeight: "auto" as const,
      animate: "bottom" as const
    },
    title: t("hotel.room.cancellation_policies"),
    buttonProps: {
      size: "large" as const,
      children: t("hotel.room.cancellation_modal_btn"),
      color: "secondary" as const,
      fullWidth: true,
      onClick: onClose
    }
  };
};
