import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { MiniChips, Skeleton } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useCountDownTimer } from "./count-down-timer.hook";
import { CountDownTimerType } from "./count-down-timer.type";

const Component: FC<CountDownTimerType> = ({
  wrapperClassName,
  textWrapperClassName,
  className,
  chipClassName,
  showTimer = true,
  onTimeEnd,
  resetOnEnd
}) => {
  const { timeLeft } = useCountDownTimer({ onTimeEnd, resetOnEnd });
  const { t } = useTranslation();

  if (timeLeft && showTimer) {
    return (
      <div className={`w-full ${wrapperClassName ?? ""}`}>
        <MiniChips
          StartIcon={
            <MaterialIcon
              className="text-2xl"
              name="timer"
              variant="outlined"
            />
          }
          children={
            <span
              className={`flex gap-2 md:justify-between ${
                textWrapperClassName ?? ""
              }`}
            >
              <span className={`flex ${className ?? ""}`} dir="ltr">
                <span>{timeLeft?.hours}</span>
                <span>:</span>
                <span>{timeLeft?.minutes}</span>
                <span>:</span>
                <span>{timeLeft?.seconds}</span>
              </span>
              <span>{t("last_second.until_end")}</span>
            </span>
          }
          className={`text-on-color-high-emphasis flex w-full items-center justify-center gap-3 md:justify-start md:gap-2 md:p-1 ${
            chipClassName ?? ""
          }`}
          color="primary"
          size="large"
          variant="round-contained"
        />
      </div>
    );
  }
  return (
    <div className="h-[32px] w-full overflow-hidden rounded-full md:h-[28px] md:w-[145px]">
      <Skeleton height={32} />
    </div>
  );
};
export const CountDownTimer = memo(Component, deepCompare);
