import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { LazyImage, MiniChips } from "@website/ui";
import { dateFormat, numberConvert } from "@website/utils";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useMemo } from "react";
import { HotelLastSecondPropertiesType } from "./hotel-last-second-item.types";

export const HotelLastSecondItem: FC<HotelLastSecondPropertiesType> = (
  properties
) => {
  const {
    cover_media,
    title,
    hotel,
    price,
    price_off,
    showMoreItem,
    adults,
    breakfast_included,
    cityUrl
  } = properties;
  const { t, locale } = useTranslation();
  const finalPrice = price_off < price ? price_off : price;
  const percentOff = ((price - price_off) * 100) / price;
  const router = useRouter();

  const date = new Date();

  const dateFrom = dateFormat(date, {
    locale: "en",
    template: "yyyy-MM-dd",
    numberConvert: false
  });

  const dateTo = dateFormat(date.setDate(date.getDate() + 1), {
    locale: "en",
    template: "yyyy-MM-dd",
    numberConvert: false
  });

  const linkToCityPage = useMemo(
    () => ({
      pathname: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/رزرو-هتل/${cityUrl}`,
      query: {
        ...router.query,
        date_from: dateFrom,
        date_to: router.query.date_to || dateTo
      }
    }),
    [cityUrl, dateFrom, dateTo, router.query]
  );

  const linkToHotelPage = useMemo(
    () => ({
      pathname: `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/رزرو-هتل/${cityUrl}/${hotel.fa_url}`,
      query: {
        ...router.query,
        date_from: dateFrom,
        date_to: router.query.date_to || dateTo
      }
    }),
    [cityUrl, dateFrom, dateTo, hotel.fa_url, router.query]
  );

  return (
    <div className="block h-full w-[288px] px-[2px] pb-1 pt-[2px]">
      <div className="elevation-1 h-full overflow-hidden rounded-xl">
        {showMoreItem ? (
          <Link
            className="flex h-full flex-col items-center justify-center"
            href={linkToCityPage}
          >
            <div className="border-secondary flex size-[36px] items-center justify-center rounded-full border-2">
              <MaterialIcon
                className="text-secondary text-[24px]"
                name="chevron_left"
              />
            </div>
            <div className="text-button text-secondary px-2 py-[10px]">
              {t("global.view_more")}
            </div>
          </Link>
        ) : (
          <Link href={linkToHotelPage}>
            <div className="flex max-h-[169px] items-center overflow-hidden">
              <LazyImage
                alt={cover_media?.title || t("hotel_card.pictures")}
                className="h-[169px] object-cover"
                fallback={cover_media.media}
                loading="eager"
                src={cover_media?.media}
                title={title}
              />
            </div>
            <div className="flex max-w-[284px] flex-col justify-between gap-2 px-4 py-3">
              <div className="flex justify-between gap-4 overflow-hidden">
                <h3 className="text-subtitle-2 text-on-surface-medium-emphasis truncate">
                  {hotel?.title}
                </h3>
                <div className="text-caption text-on-surface-medium-emphasis flex flex-col items-end gap-1">
                  <MiniChips
                    EndIcon={
                      <MaterialIcon className="text-[16px]" name="stars" />
                    }
                    color="success"
                    size="medium"
                    text={t("hotel_card.star_count", {
                      count: numberConvert(hotel?.stars, {
                        locale: router.locale
                      })
                    })}
                    textClassName="text-extra-caption"
                    variant="accent"
                  />
                </div>
              </div>
              <h2 className="text-subtitle-1 text-on-surface-high-emphasis">
                {title}
              </h2>
              <div className="flex gap-2">
                {Boolean(adults) && (
                  <MiniChips
                    StartIcon={
                      <MaterialIcon className="text-[16px]" name="person" />
                    }
                    size="medium"
                    text={t("last_second.adults_count", {
                      adults
                    })}
                    textClassName="text-extra-caption"
                    variant="accent"
                  />
                )}

                {breakfast_included && (
                  <MiniChips
                    StartIcon={
                      <MaterialIcon className="text-[16px]" name="local_cafe" />
                    }
                    size="medium"
                    text="با صبحانه"
                    textClassName="text-extra-caption"
                    variant="accent"
                  />
                )}
              </div>
            </div>
            <div className="border-t-outline-thin flex h-16 flex-row items-center justify-between border-t px-4 py-2">
              <span className="text-caption text-on-surface-medium-emphasis">
                {t("last_second.tonight_price")}
              </span>
              <div className="flex flex-col items-end">
                {price_off < price ? (
                  <div className="flex flex-row items-center gap-1">
                    <span className="text-body-2 text-on-surface-disable line-through">
                      {numberConvert(Math.trunc(price || 0)?.toLocaleString(), {
                        locale
                      })}
                    </span>
                    <MiniChips
                      color="primary"
                      text={`٪${numberConvert(Math.trunc(percentOff || 0), {
                        locale
                      })}`}
                      textClassName="text-extra-caption"
                      variant="contained"
                    />
                  </div>
                ) : (
                  ""
                )}
                <span className="text-subtitle-1 text-on-surface-high-emphasis">
                  {numberConvert(Math.trunc(finalPrice || 0).toLocaleString(), {
                    locale
                  })}{" "}
                  {t("global.tooman_currency")}
                </span>
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};
