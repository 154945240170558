import { deepCompare, numberConvert, numberSeparator } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { HotelDraftFooterItemPropertiesTypes } from "./hotel-draft-card-footer-item.types";

const Component = ({
  title,
  count,
  price,
  className
}: HotelDraftFooterItemPropertiesTypes) => {
  const router = useRouter();
  return (
    <div
      className={`text-body-2 text-on-surface-medium-emphasis mb-2 flex w-full flex-row items-center justify-between gap-2 ${
        className ?? ""
      }`}
    >
      <span className="w-auto min-w-[120px] truncate">
        {`${numberConvert(count, {
          locale: router.locale
        })} ${numberConvert(title, {
          locale: router.locale
        })}`}
      </span>
      {Boolean(price) && (
        <>
          <span className="border-outline-thin h-px w-full border-t border-dashed xl:min-w-[20px]" />
          <div className="whitespace-nowrap">{`${numberConvert(
            `${
              count
                ? numberSeparator(Number(price) * count)
                : numberSeparator(Number(price))
            }`,
            {
              locale: router.locale,
              numberOnly: false
            }
          )} تومان`}</div>
        </>
      )}
    </div>
  );
};
export const HotelDraftCardFooterItem = memo(Component, deepCompare);
