import { GetStaticProps } from "next";
import { ElementService } from "../../services/base/seo/page/element";
import { PopularCitiesService } from "../../services/cdn/hotel/popular-cities";
import { SuggestionService } from "../../services/service2/hotel/v1/suggest/top-hotels";

export const getStaticProps: GetStaticProps = async () => {
  try {
    const [seoAdmin, suggestions, popularCities] = await Promise.all([
      ElementService({
        path: process.env.NEXT_PUBLIC_BASE_URL_B2C
      }),
      SuggestionService(),
      PopularCitiesService()
    ]);

    return {
      props: {
        seoAdmin,
        suggestions,
        popularCities
      }
    };
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error?.response?.config?.url);
    throw new Error(error);
    // return {
    //   props: {
    //     marketingBanner: [],
    //     seoAdmin: null,
    //     suggestions: []
    //   }
    // };
  }
};
