import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { numberConvert } from "@website/utils";
import { pick } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";

export const useHistory = (onChangeDestination: (item: any) => void) => {
  const router = useRouter();
  const [history, setHistory] = useState<
    Array<{ destination: any; startDate: string; endDate: string }>
  >([]);
  const { t } = useTranslation();

  const HOTEL_HISTORY_LOCAL_STORAGE_KEY =
    "international-homepage-hotel-history";

  useEffect(() => {
    if (localStorage) {
      const historyString = localStorage.getItem(
        HOTEL_HISTORY_LOCAL_STORAGE_KEY
      );
      const history = historyString ? JSON.parse(historyString) : [];
      setHistory(history);
    }
  }, []);

  const addToHistory = useCallback(
    (destination: any, startDate: any, endDate: any, passengerNumber: any) => {
      if (localStorage) {
        const historyString = localStorage.getItem(
          HOTEL_HISTORY_LOCAL_STORAGE_KEY
        );
        const history = historyString ? JSON.parse(historyString) : [];

        let newHistory = history.filter(
          (item: any) => item.destination.id !== destination.id
        );

        const newHistoryItem = {
          destination,
          startDate,
          endDate,
          passengerNumber
        };
        newHistory = [newHistoryItem, ...newHistory].slice(0, 4);

        localStorage.setItem(
          HOTEL_HISTORY_LOCAL_STORAGE_KEY,
          JSON.stringify(newHistory)
        );
        setHistory(newHistory);
      }
    },
    []
  );

  const onSelectHistory = useCallback(
    (id: string) => {
      if (history) {
        let historyItem = null;
        for (const element of history) {
          const checkKey = `${element.destination.id}`;
          if (`${id}` === checkKey) {
            historyItem = element.destination;
            break;
          }
        }

        if (historyItem) {
          onChangeDestination(historyItem);
        }
      }
    },
    [history, onChangeDestination]
  );

  const historyListNormalized = useMemo(
    () =>
      history
        .filter(({ destination }) => destination?.title)
        .map(({ destination }) => ({
          ...pick(destination, ["id", "title", "subtitle"]),
          StartIcon: <MaterialIcon name="history" variant="outlined" />,
          Meta: (
            <div className="text-on-surface-high-emphasis text-caption">
              {t("global.residence_count", {
                count: numberConvert(destination.hotel_count, {
                  locale: router.locale
                })
              })}
            </div>
          )
        })),
    [history, router, t]
  );

  return {
    historyListNormalized,
    addToHistory,
    onSelectHistory
  };
};
