import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { CounterButton } from "@website/ui";
import { pick, toNumber } from "lodash";
import { useCallback, useState } from "react";
import { HotelSearchPassengerCountPropertiesType } from "./hotel-search-passenger-count.types";

export const useHotelSearchPassengerCount = (
  properties: HotelSearchPassengerCountPropertiesType
) => {
  const { onSubmit, result, title, list, onChangeAdult, onChangeChild } =
    properties;
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const onClickChips = useCallback(() => {
    setOpen(true);
  }, []);

  const onClosePopover = useCallback(() => {
    setOpen(false);
  }, []);

  const onClickSubmit = useCallback(() => {
    if (list.some((room) => room.items[1].error) === false) {
      onSubmit();
      onClosePopover();
    }
  }, [onClosePopover, onSubmit, list]);

  return {
    submitButtonProps: {
      onClick: onClickSubmit,
      color: "secondary" as const,
      size: "large" as const,
      fullWidth: true,
      submitText: t("global.confirm.confirm")
    },
    chipsProps: {
      variant: "outline" as const,
      EndIcon: <MaterialIcon name="arrow_drop_down" variant="fill" />,
      text: result,
      selected: true,
      color: "secondary" as const,
      onClick: onClickChips
    },
    popoverProps: {
      onClose: onClosePopover,
      open,
      title
    },
    list: list.map((room, roomIndex) => ({
      ...room,
      items: room.items.map((item) => ({
        title: item.title,
        subtitle: item.subtitle,
        role: "default",
        EndAction: (
          <CounterButton
            color="secondary"
            variant="outline"
            {...pick(item, "decreaseDisabled", "increaseDisabled")}
            locale="fa"
            onChange={(value) =>
              item.isAdult
                ? onChangeAdult?.(value, roomIndex)
                : onChangeChild?.(value, roomIndex)
            }
            value={toNumber(item.value)}
          />
        ),
        value: String(item.value)
      }))
    }))
  };
};
