import { useViewPort } from "@website/hooks";
import { useCallback, useEffect, useState } from "react";

const UseReverseBookingModal = () => {
  const { isDesktop } = useViewPort();
  const [isReservePageOpen, setIsReservePageOpen] = useState(false);

  const onModalClose = useCallback(() => setIsReservePageOpen(false), []);
  const onModalOpen = useCallback(() => setIsReservePageOpen(true), []);

  useEffect(() => {
    setIsReservePageOpen(false);
  }, [isDesktop]);
  return {
    isReservePageOpen,
    onModalClose,
    onModalOpen
  };
};

export default UseReverseBookingModal;
