import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Skeleton } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { VillaSearchSummaryViewPropertiesType } from "../villa-search-summary.types";

const VillaSearchSummaryContent: FC<VillaSearchSummaryViewPropertiesType> = (
  properties
) => {
  const {
    searchType = "city",
    cityTitle,
    dateFrom,
    dateTo,
    passengersCount,
    roomsCount,
    nightCount
  } = properties;

  const router = useRouter();
  const { t } = useTranslation();
  const isCompleteInfo = dateFrom && dateTo && passengersCount && roomsCount;
  const DateRange =
    dateFrom && dateTo ? (
      <span
        className="lg:text-on-surface-high-emphasis min-w-24 truncate lg:flex lg:flex-row lg:items-center lg:gap-2 lg:text-center"
        data-testid="search_date_range"
      >
        {`${dateFrom} - ${dateTo} ${
          searchType === "villa"
            ? t("global.night_count", {
                count: numberConvert(nightCount, { locale: router.locale })
              })
            : ""
        }`}
      </span>
    ) : (
      <span
        className={`lg:text-on-surface-high-emphasis text-secondary min-w-24 truncate lg:flex lg:flex-row lg:items-center lg:gap-2 lg:text-center ${
          searchType === "villa"
            ? "text-caption lg:text-subtitle-2 text-on-surface-high-emphasis"
            : "text-overline lg:text-subtitle-2 text-on-surface-medium-emphasis lg:text-on-surface-high-emphasis"
        }`}
        data-testid="search_date_range"
      >
        {t("search.period_determination")}
      </span>
    );

  /*
  const PassengerInfo =
    passengersCount && roomsCount ? (
      <span
        className={`lg:text-on-surface-high-emphasis overflow-hidden text-ellipsis whitespace-nowrap lg:flex lg:flex-row lg:items-center lg:gap-2 lg:text-center ${
          searchType === "villa" ? "text-caption lg:text-subtitle-2 text-on-surface-high-emphasis" : "text-overline lg:text-subtitle-2 text-on-surface-medium-emphasis lg:text-on-surface-high-emphasis"
        }`}
      >
        {t("global.passengers_and_rooms_count", {
          roomsCount,
          passengersCount
        })}
      </span>
    ) : (
      <span className={`text-secondary`}>
        {searchType === "villa"
          ? t("global.passengers_and_rooms_count_label")
          : t("search.rooms_and_passengers_determination")}
      </span>
    );
   */

  return (
    <div
      className={`bg-outline-thick border-outline-thin hover:shadow-8 flex w-full items-center justify-between gap-4 rounded-full border px-4 py-px transition-shadow duration-200 ease-in-out lg:pl-px ${
        searchType === "villa"
          ? "h-[48px] bg-white lg:h-auto lg:max-w-max"
          : "bg-outline-thick lg:mx-auto lg:max-w-max"
      } ${isCompleteInfo ? "" : "border-secondary border-2"}`}
    >
      <div className="grid grow py-1.5 lg:flex lg:flex-row lg:items-center lg:gap-4">
        {(searchType === "city" || searchType === "category") && (
          <>
            <h1
              className="lg:text-on-surface-high-emphasis text-body-2 text-on-surface-high-emphasis lg:text-subtitle-2 truncate lg:flex lg:flex-row lg:items-center lg:gap-2 lg:text-center"
              data-testid="page_title"
            >
              {searchType === "city"
                ? t("global.city_villa", { title: cityTitle })
                : cityTitle ?? <Skeleton height={20} shape="rectangle" />}
            </h1>
            <div className="bg-outline-thin hidden h-[24px] w-px lg:block" />
          </>
        )}
        <div
          className={`lg:text-on-surface-high-emphasis truncate lg:flex lg:flex-row lg:items-center lg:gap-2 lg:text-center ${
            searchType === "villa"
              ? "text-caption lg:text-subtitle-2 text-on-surface-high-emphasis"
              : "text-overline lg:text-subtitle-2 text-on-surface-medium-emphasis lg:text-on-surface-high-emphasis"
          }`}
        >
          {DateRange}
          {/* {DateRange} <div className={"bg-outline-thin hidden h-[24px] w-[1px] lg:block"} />
          <span className={`${isCompleteInfo ? "" : "text-secondary"}`}>
            <span className={"lg:hidden"}>/</span>
          </span>{" "}
          {PassengerInfo}*/}
        </div>
      </div>
      <div
        className={`text-on-surface-medium-emphasis lg:text-on-surface-high-emphasis flex size-6 shrink-0 grow-0 items-center justify-center rounded-full text-2xl lg:size-9 ${
          isCompleteInfo
            ? "lg:text-on-color-high-emphasis text-on-surface-medium-emphasis bg-auto"
            : ""
        } ${
          isCompleteInfo && searchType === "villa"
            ? "lg:bg-secondary"
            : isCompleteInfo && searchType === "city"
              ? "lg:bg-primary"
              : ""
        } `}
      >
        <MaterialIcon
          className={`${isCompleteInfo ? "text-white" : ""}`}
          name="search"
        />
      </div>
    </div>
  );
};

const HotelSearchSummaryContentMemoized = memo(
  VillaSearchSummaryContent,
  deepCompare
);
export { HotelSearchSummaryContentMemoized as VillaSearchSummaryContent };
