import { Button, LazyImage } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { PageMessagePropertiesType } from "./page-message.types";

const Component: FC<PageMessagePropertiesType> = (properties) => {
  const {
    CustomAction,
    message,
    caption,
    artSrc,
    actionTitle,
    actionIcon,
    onActionClick
  } = properties;

  return (
    <div
      className="flex w-[312px] flex-col items-center gap-6 self-center justify-self-center py-4"
      itemScope
    >
      <LazyImage alt={message} loading="eager" src={artSrc} />
      <div className="flex flex-col items-center gap-2">
        <div className="text-subtitle-1 text-on-surface-high-emphasis">
          {message}
        </div>
        <div className="text-caption text-on-surface-medium-emphasis text-center">
          {caption}
        </div>
      </div>
      {CustomAction ||
        (actionTitle && (
          <Button
            StartIcon={actionIcon}
            color="secondary"
            onClick={onActionClick}
            variant="outline"
          >
            {actionTitle}
          </Button>
        ))}
    </div>
  );
};

export const PageMessage = memo(Component, deepCompare);
