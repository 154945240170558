import { USER_TRACKING_EVENTS } from "@website/tracking";
import { throttle } from "lodash";
import { useCallback, useState } from "react";
import { HotelDraftCardFooterPropertiesTypes } from "./hotel-draft-card-footer.types";

export const useHotelDraftCardFooter = ({
  step,
  bankName,
  discountLoading,
  isRefetching,
  submit,
  onSnappPayUserTrack
}: HotelDraftCardFooterPropertiesTypes) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = throttle(() => setIsExpanded(!isExpanded), 500);

  const handleNextStep = useCallback(() => {
    if (bankName === "snapppay" && step === "payment") {
      onSnappPayUserTrack?.(USER_TRACKING_EVENTS.SNAPPPAY_TERMS_VIEWED);
      return setOpenDialog(true);
    }
    setLoading(true);
    submit().finally(setLoading.bind(null, false));
  }, [bankName, step, submit, onSnappPayUserTrack]);

  const onClose = useCallback(() => {
    onSnappPayUserTrack?.(USER_TRACKING_EVENTS.SNAPPPAY_TERMS_DECLINED);
    setOpenDialog(false);
  }, [onSnappPayUserTrack]);

  const handleDialogSubmit = useCallback(() => {
    onSnappPayUserTrack?.(USER_TRACKING_EVENTS.SNAPPPAY_TERMS_ACCEPTED);
    submit();
  }, [onSnappPayUserTrack, submit]);

  return {
    loading: loading || discountLoading || isRefetching,
    openDialog,
    onClose,
    isExpanded,
    handleClick,
    handleNextStep,
    handleDialogSubmit
  };
};
