import { useIsVisible } from "@website/hooks";
import { deepCompare } from "@website/utils";
import { FC, memo, useEffect } from "react";
import { HotelDraftPrepaymentPopupContentPropertiesType } from "./hotel-draft-prepayment-popup-content.types";

const Component: FC<HotelDraftPrepaymentPopupContentPropertiesType> = (
  properties
) => {
  const { content, handleInView } = properties;
  const { inView, reference } = useIsVisible();
  useEffect(() => handleInView(inView), [handleInView, inView]);

  return (
    <>
      <div ref={reference} />
      {content}
    </>
  );
};
export const HotelDraftPrepaymentPopupContent = memo(Component, deepCompare);
