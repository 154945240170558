import { useMemo } from "react";
import { HotelInboundLinksPropertiesType } from "./hotel-inbound-links.types";
export const useHotelInboundLinks = (
  properties: HotelInboundLinksPropertiesType
) => {
  const { list } = properties;

  const masonryList = useMemo(() => {
    const result = [[], [], []];

    if (list) {
      for (const [index, element] of list.entries()) {
        const columnIndex = index % 3;
        result[columnIndex].push(element);
      }
    }
    return result;
  }, [list]);

  return {
    masonryList
  };
};
