import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelSearchPanel } from "./hotel-search-panel";
import { HotelSearchSummary } from "./hotel-search-summary";
import { HotelSearchWrapper } from "./hotel-search-wrapper";
import { HotelSearchPropertiesType } from "./hotel-search.types";

const Component: FC<HotelSearchPropertiesType> = (properties) => {
  const {
    isSearchPanelOpen,
    setIsSearchPanelOpen,
    searchPanelProperties,
    searchSummaryProperties,
    searchType = "city"
  } = properties;

  return (
    <HotelSearchWrapper
      PanelElement={
        <HotelSearchPanel {...searchPanelProperties} searchType={searchType} />
      }
      SummaryElement={
        <HotelSearchSummary
          {...searchSummaryProperties}
          searchType={searchType}
        />
      }
      isSearchPanelOpen={isSearchPanelOpen}
      searchType={searchType}
      setIsSearchPanelOpen={setIsSearchPanelOpen}
    />
  );
};

export const HotelSearch = memo(Component, deepCompare);
