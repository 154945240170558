import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApplyDiscountResultType } from "../../hotel-booking-step-payment.types";
import { HotelDraftPrepaymentDiscountPropertiesType } from "./hotel-draft-prepayment-discount.types";

export const useHotelDraftPrepaymentDiscount = (
  properties: HotelDraftPrepaymentDiscountPropertiesType
) => {
  const {
    fetchApplyDiscount,
    deleteDiscount,
    bookId,
    loading,
    refetchShoppingDetails,
    refetchPaymentGateways,
    discountable,
    initialDiscountCode,
    discount,
    value,
    bankName,
    isFreeCabRequested = false,
    onChange
  } = properties;

  const [result, setResult] = useState<ApplyDiscountResultType | undefined>(
    undefined
  );

  useEffect(() => {
    if (initialDiscountCode && discount) {
      setTimeout(() => onChange(initialDiscountCode), 0);
      setResult({
        discount,
        message: ""
      });
    }
  }, [initialDiscountCode, discount, onChange]);

  const handleClick = useCallback(() => {
    fetchApplyDiscount?.(bookId, value)
      ?.then((data) => {
        refetchShoppingDetails?.();
        setResult(data);
      })
      .then(() => {
        refetchPaymentGateways?.();
      });
  }, [
    fetchApplyDiscount,
    bookId,
    value,
    refetchShoppingDetails,
    refetchPaymentGateways
  ]);

  useEffect(() => {
    if (value.length === 0 && initialDiscountCode?.length === 0) {
      setResult(undefined);
    }
  }, [value, initialDiscountCode]);

  const removeDiscountCode = useCallback(() => {
    onChange("");
    setResult(undefined);
    deleteDiscount?.(bookId)
      ?.then(() => {
        refetchShoppingDetails?.();
      })
      .then(() => {
        refetchPaymentGateways?.();
      });
  }, [
    bookId,
    deleteDiscount,
    onChange,
    refetchPaymentGateways,
    refetchShoppingDetails
  ]);

  const handleApplyDiscountable = useMemo(
    () => ({
      discountable:
        discountable?.discountable === undefined
          ? true
          : discountable?.discountable,
      discountDisableReason: discountable?.discountDisableReason?.length
        ? discountable?.discountDisableReason
        : undefined
    }),
    [discountable?.discountDisableReason, discountable?.discountable]
  );

  const isSnappPayGateway = bankName === "snapppay";

  useEffect(() => {
    if (isSnappPayGateway || isFreeCabRequested) {
      removeDiscountCode();
    }
  }, [removeDiscountCode, isSnappPayGateway, isFreeCabRequested]);

  return {
    discountCode: value,
    setDiscountCode: onChange,
    loading,
    result,
    handleClick,
    removeDiscountCode,
    buttonDisabled: value.length === 0,
    textInputDisabled:
      isSnappPayGateway || isFreeCabRequested || !isEmpty(result),
    isApplyButton: !result || value.length === 0,
    discountable: handleApplyDiscountable,
    isSnappPayGateway,
    isFreeCabRequested
  };
};
