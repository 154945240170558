import { LazyImage } from "@website/ui";
import { dateFormat, deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { HotelCardStars } from "../hotel-card/hotel-card-stars";
import { DraftCardHeaderType } from "./draft-card-header.type";

const Component = (properties: DraftCardHeaderType) => {
  const router = useRouter();
  const { img, title, stars, checkin, checkout, reserveCode } = properties;
  return (
    <div className="box-content flex flex-col gap-4 overflow-hidden bg-white px-6 py-3 lg:flex-row lg:gap-0 lg:rounded-lg lg:p-0 xl:w-[360px] xl:flex-col">
      {reserveCode && (
        <div className="border-outline-thin bg-dim-background text-subtitle-2 flex min-w-[164px] flex-row items-center justify-between gap-6 rounded border px-2 py-1 lg:hidden xl:order-3 xl:mx-6 xl:flex">
          <span>کد پیگیری</span>
          <span className="cursor-pointer">
            {numberConvert(reserveCode, { locale: router.locale })}
          </span>
        </div>
      )}
      <LazyImage
        alt={img?.alt}
        className="hidden lg:block lg:h-auto lg:w-[160px] xl:h-[140px] xl:w-full"
        loading="eager"
        src={img?.src}
        title={title}
      />
      <div className="lg:block lg:w-full lg:p-4 xl:order-2 xl:px-6 xl:py-3">
        <div className="flex flex-row items-start gap-2 lg:justify-between">
          <LazyImage
            alt={img?.alt}
            className="block size-[48px] rounded lg:hidden"
            loading="eager"
            src={img?.src}
            title={title}
          />
          <div className="min-w-0 flex-row lg:max-w-[340px]">
            <div className="text-subtitle-2 lg:text-subtitle-1 truncate pb-1 lg:whitespace-pre-wrap xl:whitespace-nowrap">
              {title}
            </div>
            <HotelCardStars color="neutral" stars={stars} />
          </div>
          {reserveCode && (
            <div className="border-outline-thin bg-dim-background text-subtitle-2 hidden min-w-[164px] flex-row items-center justify-between gap-6 rounded border px-2 py-1 lg:flex xl:order-3 xl:mx-6 xl:hidden">
              <span>کد پیگیری</span>
              <span className="cursor-pointer">
                {numberConvert(reserveCode, { locale: router.locale })}
              </span>
            </div>
          )}
        </div>
        <div className="col-span-2 flex flex-row gap-4 pt-2">
          <div className="border-outline-thin flex w-full min-w-[148px] flex-col items-start rounded-lg border px-3 py-2">
            <div className="text-caption text-on-surface-medium-emphasis pb-1">
              زمان ورود
            </div>
            <div className="text-caption lg:text-subtitle-2">
              {dateFormat(checkin, {
                locale: router.locale,
                template: "dd MMMM yyyy"
              })}
            </div>
          </div>
          <div className="border-outline-thin flex w-full min-w-[148px] flex-col items-start rounded-lg border px-3 py-2">
            <div className="text-caption text-on-surface-medium-emphasis pb-1">
              زمان خروج
            </div>
            <div className="text-caption lg:text-subtitle-2">
              {dateFormat(checkout, {
                locale: router.locale,
                template: "dd MMMM yyyy"
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DraftCardHeader = memo(Component, deepCompare);
