import { deepCompare } from "@website/utils";
import { range } from "lodash";
import { FC, memo } from "react";
import { HotelCardSkeleton } from "./hotel-card-skeleton";
import { useLoadMore, usePagination } from "./hotel-pagination.hook";
import { HotelPaginationPropertiesType } from "./hotel-pagination.types";

const LoadMore: FC<HotelPaginationPropertiesType> = (properties) => {
  const { reference } = useLoadMore(properties);
  return <div className="flex h-1 w-full justify-center" ref={reference} />;
};

const Component: FC<HotelPaginationPropertiesType> = (properties) => {
  const { children } = properties;
  const { loading } = usePagination(properties);

  return (
    <div className="flex w-full flex-col gap-4">
      {children}
      {loading ? (
        range(3).map((indexKey) => <HotelCardSkeleton key={indexKey} />)
      ) : (
        <LoadMore {...properties} />
      )}
    </div>
  );
};

export const HotelPagination = memo(Component, deepCompare);
