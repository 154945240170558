import {
  dateFormat,
  daysBetweenDates,
  isFilled,
  numberConvert
} from "@website/utils";
import { toNumber } from "lodash";
import { useRouter } from "next/router";
import { VillaSearchSummaryPropertiesType } from "./villa-search-summary.types";

const parseQueryToNumber = (query?: string) => {
  const convertedNumber = toNumber(query);
  return Number.isNaN(convertedNumber) ? 0 : convertedNumber;
};

export const useVillaSearchSummary = (
  properties: VillaSearchSummaryPropertiesType
) => {
  const router = useRouter();
  const { searchType, cityTitle, dateFrom, dateTo, adults, children, noRooms } =
    properties;

  return {
    searchType,
    cityTitle,
    dateFrom: dateFormat(dateFrom, {
      locale: router.locale,
      template: "dd MMMM"
    }) as string,
    dateTo: dateFormat(dateTo, {
      locale: router.locale,
      template: "dd MMMM"
    }) as string,
    passengersCount:
      !isFilled(adults) && !isFilled(children)
        ? undefined
        : numberConvert(
            parseQueryToNumber(adults) + parseQueryToNumber(children),
            {
              locale: router.locale
            }
          ),
    roomsCount: isFilled(noRooms)
      ? numberConvert(parseQueryToNumber(noRooms), {
          locale: router.locale
        })
      : undefined,
    nightCount: daysBetweenDates(dateFrom, dateTo)
  };
};
