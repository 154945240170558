import { useTranslation } from "@website/locale";
import { numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { PassengerCountInitialValuesType } from "../villa-search-panel.types";

export const usePassengerCount = (
  initialValues?: PassengerCountInitialValuesType
) => {
  const router = useRouter();
  const [temporaryPassengerCount, setTemporaryPassengerCount] = useState<{
    adult: number;
    child: number;
    room: number;
  }>({
    adult: initialValues?.adult || 1,
    child: initialValues?.child || 0,
    room: initialValues?.room || 1
  });
  const [passengerCount, setPassengerCount] = useState(temporaryPassengerCount);

  const { adult, child, room } = temporaryPassengerCount;
  const { t } = useTranslation();

  const roomSpace = 15;
  const total = adult + child;
  const remain = roomSpace * room - total;
  const remainNotAdult = Math.min(remain, adult * 12 - child);

  const adultMax = adult + remain;
  const adultMin = Math.max(Math.ceil(child / 12), 1);
  const childMax = child + remainNotAdult;
  const childMin = 0;
  const roomMax =
    process.env.NEXT_PUBLIC_APP_NAME === "hotel-international" ? 1 : 15;
  const roomMin = 1;

  const onSubmitPassengerCount = useCallback(() => {
    setTemporaryPassengerCount((state) => {
      setPassengerCount(state);
      return state;
    });
  }, []);

  const onChangeAdult = useCallback((value: number) => {
    setTemporaryPassengerCount((state) => ({
      ...state,
      adult: value
    }));
  }, []);

  const onChangeChild = useCallback((value: number) => {
    setTemporaryPassengerCount((state) => ({
      ...state,
      child: value
    }));
  }, []);

  const onChangeRoom = useCallback((value: number) => {
    setTemporaryPassengerCount((state) => ({
      ...state,
      room: value
    }));
  }, []);

  const list = [
    {
      increaseDisabled: adult >= adultMax,
      decreaseDisabled: adult <= adultMin,
      title: t("global.adults_count"),
      subtitle: t("global.adult_definition"),
      value: adult,
      onChange: onChangeAdult
    },
    {
      increaseDisabled: child >= childMax,
      decreaseDisabled: child <= childMin,
      title: t("global.children_count"),
      subtitle: t("global.child_definition"),
      value: child,
      onChange: onChangeChild
    },
    {
      increaseDisabled: room >= roomMax,
      decreaseDisabled: room <= roomMin,
      title: t("global.rooms_count"),
      value: room,
      onChange: onChangeRoom
    }
  ];

  return {
    passengerCountTitle: t("global.passengers_and_rooms_count_label"),
    passengerCountResult: t("global.passengers_and_rooms_count", {
      passengersCount: numberConvert(
        passengerCount.adult + passengerCount.child,
        { locale: router.locale }
      ),
      roomsCount: numberConvert(passengerCount.room, {
        locale: router.locale
      })
    }),

    passengerCountList: list,
    passengerCount,
    onSubmitPassengerCount,
    passengerCountValidation: {
      isPassengerDisabled: adult >= adultMax,
      isChildrenDisabled: child >= childMax,
      isSubmitDisabled: !(room * roomSpace >= total)
    }
  };
};
