import { useTranslation } from "@website/locale";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { HotelCardReservationInfoPropertiesType } from "./hotel-card-reservation-info.types";

const Component: FC<HotelCardReservationInfoPropertiesType> = (properties) => {
  const router = useRouter();
  const { nightCount, size = "responsive" } = properties;
  const { t } = useTranslation();
  return (
    <div
      className={
        size === "small"
          ? "text-caption text-on-surface-high-emphasis flex items-center gap-1"
          : "text-caption text-on-surface-high-emphasis flex items-center gap-1 md:justify-center"
      }
    >
      {t("global.night_count", {
        count: numberConvert(nightCount, { locale: router.locale })
      })}
    </div>
  );
};

export const HotelCardReservationInfo = memo(Component, deepCompare);
