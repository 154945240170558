import { DatepickerSelectedDateType } from "@website/ui";
import { every, isNaN } from "lodash";
import { useCallback, useEffect, useState } from "react";

export const useDate = (initialValues: DatepickerSelectedDateType) => {
  const [date, setDate] = useState<DatepickerSelectedDateType>([]);

  const onEmptyDate = useCallback(() => {
    setDate([]);
  }, []);

  useEffect(() => {
    every(
      initialValues,
      (date) => !isNaN(date.year) && !isNaN(date.month) && !isNaN(date.day)
    )
      ? setDate(initialValues)
      : setDate([]);
  }, [initialValues]);

  return { date, onChangeDate: setDate, onEmptyDate };
};
