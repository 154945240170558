import { Modal } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelDraftPrepaymentPopupContent } from "./hotel-draft-prepayment-popup-content/hotel-draft-prepayment-popup-content";
import { useHotelDraftPrepaymentPopup } from "./hotel-draft-prepayment-popup.hook";
import { HotelDraftPrepaymentPopupPropertiesType } from "./hotel-draft-prepayment-popup.types";

const Component: FC<HotelDraftPrepaymentPopupPropertiesType> = (properties) => {
  const { title, content, open, onClose } = properties;
  const { inView, handleInView } = useHotelDraftPrepaymentPopup();

  return (
    <Modal
      className="flex h-full p-0 lg:hidden"
      headerStyle={`border-outline-thin sticky left-0 top-0 h-16 w-full border-b bg-white p-6 ${
        !inView && "shadow-4"
      }`}
      onClose={onClose}
      open={open}
      showCloseButton
      title={title}
    >
      <div className="overflow-auto p-6">
        <HotelDraftPrepaymentPopupContent
          content={content}
          handleInView={handleInView}
        />
      </div>
    </Modal>
  );
};

export const HotelDraftPrepaymentPopup = memo(Component, deepCompare);
