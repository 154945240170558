import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { applicationStore } from "@website/store";
import { Button, Skeleton } from "@website/ui";
import { deepCompare, numberConvert, numberSeparator } from "@website/utils";
import { sum, uniq } from "lodash";
import { useRouter } from "next/router";
import { memo } from "react";
import { HotelDraftCardRoomTypes } from "../hotel-draft-card.types";
import { HotelDraftCardFooterDialog } from "./hotel-draft-card-footer-dialog";
import { HotelDraftCardFooterItem } from "./hotel-draft-card-footer-item";
import { useHotelDraftCardFooter } from "./hotel-draft-card-footer.hook";
import { HotelDraftCardFooterPropertiesTypes } from "./hotel-draft-card-footer.types";

const countedRooms = (array: Array<HotelDraftCardRoomTypes>) => {
  const uniqueItems = uniq(array?.map((item) => item.title)).map((title) =>
    array.find((item) => item.title === title)
  );
  return uniqueItems.map((item) => ({
    value: item,
    count: array.filter((element) => element.title === item?.title).length
  }));
};

const Component = (properties: HotelDraftCardFooterPropertiesTypes) => {
  const {
    step,
    price,
    rooms,
    childrenReserved = 0,
    infantReserved = 0,
    nightCount,
    isRefetching,
    nonInstantStepData,
    discountLoading,
    isEligible
  } = properties;
  const router = useRouter();

  const {
    loading = false,
    handleNextStep,
    handleClick,
    isExpanded,
    openDialog,
    onClose,
    handleDialogSubmit
  } = useHotelDraftCardFooter(properties);
  const extraBedReserved = sum(rooms?.map((item) => item?.extraBedReserved));
  const { t } = useTranslation();
  const isSnappPayEligible =
    isEligible && applicationStore.bankName.get() === "snapppay";

  const buttonElement = (
    <div className="lg:absolute lg:bottom-6 lg:end-[24px] lg:mt-3 xl:static xl:bottom-[35px]">
      <Button
        EndIcon={
          step === "detail" ? (
            <MaterialIcon className="pe-4 ltr:ps-4" name="arrow_back_ios" />
          ) : undefined
        }
        children={
          nonInstantStepData?.state ? (
            <span>
              <span className="lg:hidden">
                {t<string>(
                  `booking[${nonInstantStepData.state}].buttonText.text`
                )}
              </span>
              <span className="hidden lg:inline">
                {t<string>(
                  `booking[${nonInstantStepData.state}].buttonText.shortText`
                )}
              </span>
              <span>
                {nonInstantStepData.time &&
                nonInstantStepData.state !== "availability_rejected"
                  ? `: ${nonInstantStepData.time}`
                  : ""}
              </span>
            </span>
          ) : step === "detail" ? (
            "تایید و ادامه"
          ) : process.env.NEXT_PUBLIC_APP_NAME === "villa" ? (
            "تایید"
          ) : step === "payment" &&
            applicationStore.bankName.get() === "snapppay" ? (
            "تایید و پرداخت با اسنپ‌پی"
          ) : (
            "تایید و پرداخت"
          )
        }
        className={`w-full lg:min-w-[180px] xl:w-full ${
          ["availability_expired", "availability_rejected"].includes(
            nonInstantStepData?.state ?? ""
          )
            ? "bg-secondary lg:border-on-surface-disable lg:text-on-surface-disable lg:pointer-events-none lg:border lg:bg-transparent"
            : ["availability_confirmed", "availability_pending"].includes(
                  nonInstantStepData?.state ?? ""
                )
              ? "border-on-surface-high-emphasis text-on-surface-high-emphasis pointer-events-none border-2 bg-transparent"
              : ""
        }`}
        loading={openDialog ? false : loading}
        loadingWithText={nonInstantStepData?.state === "availability_confirmed"}
        onClick={handleNextStep}
      />
    </div>
  );
  const priceInfoElement = (
    <div className="text-subtitle-1 flex flex-auto flex-col justify-between gap-4 md:flex-row">
      <div
        className={`${
          isSnappPayEligible
            ? "text-caption text-on-surface-medium-emphasis md:text-subtitle-2 xl:text-on-surface-high-emphasis"
            : "text-subtitle-2 hidden lg:block"
        }`}
      >
        {`قیمت برای ${numberConvert(nightCount, {
          locale: router.locale
        })} شب${
          !isEligible && applicationStore.bankName.get() === "snapppay"
            ? " (قسطی) "
            : ""
        }`}
      </div>
      <div className="text-subtitle-1 block lg:hidden xl:block">
        {`${numberConvert(`${numberSeparator(price?.finalPrice)}`, {
          locale: router.locale,
          numberOnly: false
        })} تومان`}
      </div>
    </div>
  );
  const roomsInfoElement = (
    <div>
      {countedRooms(rooms)?.map((item) => (
        <HotelDraftCardFooterItem
          count={item?.count}
          key={item?.value?.id}
          price={Number(item?.value?.price)}
          title={`اتاق ${String(item?.value?.title)}`}
        />
      ))}
      {Boolean(extraBedReserved) && Boolean(price.extraBedPrice) && (
        <HotelDraftCardFooterItem
          count={extraBedReserved}
          price={price.extraBedPrice ?? 0}
          title="تخت اضافه"
        />
      )}
      {Boolean(childrenReserved) && Boolean(price.childPrice) ? (
        <HotelDraftCardFooterItem
          count={childrenReserved}
          price={price.childPrice ?? 0}
          title="کودک با تخت اضافه"
        />
      ) : null}
      {infantReserved ? (
        <HotelDraftCardFooterItem
          count={infantReserved}
          price={0}
          title="کودک بدون تخت اضافه"
        />
      ) : null}
      {price?.markupPrice ? (
        <HotelDraftCardFooterItem
          price={price.markupPrice}
          title="قیمت قبل از تخفیف"
        />
      ) : null}
      {price?.discount ? (
        <HotelDraftCardFooterItem price={price.discount} title="تخفیف سایت" />
      ) : null}
      {price?.discountCodeValue ? (
        <HotelDraftCardFooterItem
          className="text-body-2 text-success"
          price={price.discountCodeValue}
          title="کد تخفیف"
        />
      ) : null}
    </div>
  );

  const footerElement =
    rooms && !isRefetching ? (
      <div
        className={`hidden flex-col gap-1 py-5 xl:order-4 xl:flex xl:px-6 ${
          nonInstantStepData ? "pt-4 lg:pt-6" : ""
        }`}
      >
        {discountLoading ? (
          <div className="mb-2 grid grid-cols-2 gap-2">
            <Skeleton height={24} shape="rectangle" />
            <Skeleton height={24} shape="rectangle" />
            <Skeleton height={24} shape="rectangle" />
            <Skeleton height={24} shape="rectangle" />
          </div>
        ) : (
          <>
            {roomsInfoElement}
            {priceInfoElement}
          </>
        )}
        {buttonElement}
        <HotelDraftCardFooterDialog
          loading={loading}
          onClose={onClose}
          open={openDialog}
          submit={handleDialogSubmit}
        />
      </div>
    ) : (
      <Skeleton height={216} />
    );
  return (
    <>
      {footerElement}
      <div
        className={`border-outline-thin bg-on-color-high-emphasis shadow-6 keyboardOpened:shadow-0 md:keyboardOpened:shadow-6 keyboardOpened:absolute md:keyboardOpened:fixed fixed inset-x-0 bottom-0 z-[5] flex w-full flex-col border px-6 pb-6 pt-4 lg:min-h-[97px] xl:hidden ${
          nonInstantStepData ? "pt-4 lg:pt-6" : ""
        }`}
      >
        {rooms && !isRefetching ? (
          <>
            <div
              className={`transition-all ${
                isExpanded ? "block h-auto" : "hidden h-0"
              }`}
            >
              <div className="text-subtitle-1 flex flex-row items-center justify-between pb-2">
                <span>جزییات قیمت</span>
                <Button
                  StartIcon={<MaterialIcon name="close" />}
                  color="neutral"
                  onClick={handleClick}
                  variant="text"
                />
              </div>
              {roomsInfoElement}
            </div>
            {discountLoading ? (
              <div className="my-4">
                <Skeleton height={24} shape="rectangle" />
              </div>
            ) : (
              <div className="my-2 flex flex-row items-center justify-between lg:justify-start lg:gap-2 lg:pb-1">
                <span className="text-subtitle-1">{priceInfoElement}</span>
                <Button
                  EndIcon={
                    <MaterialIcon
                      name={isExpanded ? "expand_more" : "expand_less"}
                    />
                  }
                  children={isExpanded ? "بستن" : "جزییات قیمت"}
                  className="text-subtitle-2"
                  color="secondary"
                  onClick={handleClick}
                  variant="text"
                />
              </div>
            )}
            <div>
              {buttonElement}
              <div className="text-subtitle-1 flex flex-auto flex-row justify-between gap-4">
                <div className="hidden lg:block xl:hidden">
                  {`${numberConvert(`${numberSeparator(price?.finalPrice)}`, {
                    locale: router.locale,
                    numberOnly: false
                  })} تومان`}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Skeleton height={112} />
        )}
      </div>
    </>
  );
};
export const HotelDraftCardFooter = memo(Component, deepCompare);
