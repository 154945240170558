import { Control } from "@website/form";
export enum TransportTranslate {
  flight = "پرواز",
  train = "قطار"
}
export enum PickupLocationTranslate {
  flight = "فرودگاه",
  train = "راه آهن"
}
export enum PickupLocation {
  FLIGHT = "flight",
  TRAIN = "train"
}

export type HotelDraftFreeTransferFormType = {
  hasFreeTransfer: string;
  freeTransferType: string;
  freeTransferNumber: string;
  freeTransferDeparture: string;
  freeTransferMobileNumber: string;
  freeTransferPassengerCount: string;
};

export type HotelDraftFreeTransferTypes = {
  hasFreeTransfer: boolean;
  freeTransferType: "flight" | "train";
  control: Control<HotelDraftFreeTransferFormType>;
  freeTransferData: {
    freeTransferFlight: boolean;
    freeTransferTrain: boolean;
    freeTransferPolicy: string;
  };
};
