import { numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { HotelSeoContentPropertiesType } from "./hotel-seo-content.types";

export const useSeoContent = (properties: HotelSeoContentPropertiesType) => {
  const router = useRouter();
  const { list } = properties;
  return {
    list:
      list &&
      list.map((item) => ({
        title: item.title,
        imageUrl: item.imageUrl,
        videoUrl: item.videoUrl,
        content: numberConvert(
          item.description?.replaceAll(/style="[^"]*"|<br\s*\/?>/g, ""),
          { locale: router.locale }
        )
      }))
  };
};
