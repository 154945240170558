import { deepCompare } from "@website/utils";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Filters } from "@website/widget";
import { FC, memo } from "react";
import { useHotelFiltersForm } from "./hotel-filter.hook";
import { UseHotelFiltersFormProperties } from "./hotel-filter.types";

const Component: FC<UseHotelFiltersFormProperties> = (properties) => {
  const { filtersSchema } = properties;
  const { resetButtonProps, onResetFilters, title, footerProps } =
    useHotelFiltersForm(properties);

  return (
    <Filters
      onResetFilters={onResetFilters}
      resetButtonProps={resetButtonProps}
      schema={filtersSchema}
      title={title}
      {...footerProps}
    />
  );
};

export const HotelFilter = memo(Component, deepCompare);
