import { Datepicker } from "@website/ui";
import { deepCompare } from "@website/utils";
import { memo } from "react";
import { HotelCalendarType } from "./hotel-calendar.type";

const Component = (properties: HotelCalendarType) => {
  const {
    calendarDate,
    onCalendarChange,
    calnedarData,
    flatCalendarIntitalShowDates,
    disableLimit
  } = properties;

  return (
    <div
      className="border-outline-thin rounded-lg border p-3 md:p-4"
      id="hotel_calendar"
    >
      <Datepicker
        calendarData={calnedarData}
        disableLimit={disableLimit}
        initialShowDates={flatCalendarIntitalShowDates}
        mode="range"
        onChange={onCalendarChange}
        type="flat"
        value={calendarDate}
      />
    </div>
  );
};

export const HotelCalendar = memo(Component, deepCompare);
