import { MaterialIcon } from "@website/icons";
import { Chips } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { HotelCardInfoOptionsPropertiesType } from "./hotel-card-info-options.types";

const Component: FC<HotelCardInfoOptionsPropertiesType> = (properties) => {
  const {
    isFomo,
    address,
    roomTitle,
    roomCount,
    regularBadges,
    size = "responsive"
  } = properties;
  const router = useRouter();

  return (
    <>
      {address && size !== "xSmall" && (
        <div
          className={
            size === "small"
              ? "flex h-4 items-center gap-1"
              : "flex h-4 items-center gap-1 lg:h-6"
          }
        >
          <meta content={address} itemProp="address" />
          <MaterialIcon
            className="shrink-0 text-sm lg:text-base"
            name="location_on"
            variant="outlined"
          />
          <span
            className="text-caption text-on-surface-high-emphasis truncate"
            data-testid="hotel_card_address"
          >
            {numberConvert(address, {
              numberOnly: false,
              locale: router.locale
            })}
          </span>
        </div>
      )}
      {roomTitle && size !== "xSmall" && (
        <div
          className={
            size === "small"
              ? "flex h-4 items-center gap-1"
              : "flex h-4 items-center gap-1 lg:h-6"
          }
        >
          <MaterialIcon
            className="text-sm lg:text-base"
            name="bed"
            variant="outlined"
          />
          <span
            className="text-caption text-on-surface-high-emphasis truncate"
            data-testid="hotel_card_room_title"
          >
            {numberConvert(roomTitle, {
              numberOnly: false,
              locale: router.locale
            })}
          </span>
        </div>
      )}
      {(regularBadges?.length ?? 0) > 0 &&
        size !== "small" &&
        size !== "xSmall" && (
          <div className="scrollbar-none flex items-center gap-1 overflow-x-auto">
            {regularBadges?.map(({ text, color, icon, transparent }) => (
              <Chips
                StartIcon={
                  icon ? (
                    <MaterialIcon name={icon} variant="outlined" />
                  ) : undefined
                }
                color={color}
                key={text}
                text={text ?? ""}
                transparent={transparent}
              />
            ))}
          </div>
        )}
      {isFomo && size !== "xSmall" && (
        <div
          className={
            size === "small"
              ? "flex h-4 items-center gap-1"
              : "flex h-4 items-center gap-1 lg:h-6"
          }
        >
          <MaterialIcon
            className="text-error text-sm lg:text-base"
            name="info"
            variant="outlined"
          />
          <span
            className="text-caption text-on-surface-high-emphasis truncate"
            data-testid="hotel_card_room_count"
          >
            <span className="text-error">{`فقط ${numberConvert(roomCount, {
              numberOnly: false,
              locale: router.locale
            })} اتاق باقی مانده`}</span>
          </span>
        </div>
      )}
    </>
  );
};

export const HotelCardInfoOptions = memo(Component, deepCompare);
