import { dateFormat, numberConvert } from "@website/utils";
import { toNumber } from "lodash";
import { useRouter } from "next/router";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { HotelReserveCardRacksPropertiesTypes } from "./hotel-reserve-card-racks.types";

export const useHotelReserveRacks = ({
  roomRacks,
  open
}: HotelReserveCardRacksPropertiesTypes) => {
  const router = useRouter();
  const [selectedPackageIndex, setSelectedPackageIndex] = useState<
    number | null
  >(null);
  const [roomCount, setRoomCount] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const packageSelect = roomRacks?.[toNumber(selectedPackageIndex)];

  useEffect(() => {
    if (packageSelect)
      setRoomCount(
        Number.isNaN(Number(router.query["no_rooms"]))
          ? 1
          : Number(router.query["no_rooms"]) <= packageSelect?.availableRoom ??
              1
            ? Number(router.query["no_rooms"])
            : packageSelect?.availableRoom
      );
  }, [packageSelect, router]);

  const items = roomRacks?.map((rack, index) => ({
    label: (
      <div className="flex flex-row gap-2">
        <div className="text-caption text-on-surface-medium-emphasis">{`از تاریخ ${dateFormat(
          rack.dateFrom,
          {
            locale: router.locale,
            template: "yyyy/MM/dd"
          }
        )} تا تاریخ ${dateFormat(rack.dateTo, {
          locale: router.locale,
          template: "yyyy/MM/dd"
        })}`}</div>
        <div className="text-caption text-primary">{`${numberConvert(
          rack?.nightCount,
          { locale: router.locale }
        )}شب`}</div>
      </div>
    ),
    value: index
  }));

  useEffect(() => {
    if (!open) setSelectedPackageIndex(null);
  }, [open]);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSelectedPackageIndex(toNumber(event?.target?.value));
  }, []);

  const onHandleClick = useCallback(() => {
    if (packageSelect) {
      setLoading(true);
      packageSelect?.onClick?.()?.finally?.(() => setLoading(false));
    }
  }, [packageSelect]);

  const handleRoomCount = useCallback(
    (count: number) => {
      setRoomCount(count);
      return router.push(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            no_rooms: count
          }
        },
        undefined,
        { scroll: false, shallow: true }
      );
    },
    [router]
  );

  return {
    items,
    onChange,
    packageSelect,
    roomCount,
    setRoomCount: handleRoomCount,
    onHandleClick,
    loading
  };
};
