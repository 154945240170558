import { MaterialIcon } from "@website/icons";
import { deepCompare } from "@website/utils";
import Link from "next/link";
import { FC, memo } from "react";

const Component: FC<any> = () => (
  <div className="lg:border-outline-thin gap-4 rounded-lg bg-white p-4 lg:border">
    <p className="text-on-surface-medium-emphasis text-sm">
      پرداخت به منزله پذیرش تمام
      {process.env.NEXT_PUBLIC_CHANNEL_TYPE === "ads" ? (
        <Link
          href={`${process.env.NEXT_PUBLIC_BASE_URL_B2C}/policy`}
          target="_blank"
        >
          <span className="text-secondary"> قوانین و مقررات </span>
        </Link>
      ) : (
        <span> قوانین و مقررات </span>
      )}
      اسنپ تریپ می باشد.
    </p>
    <p className="text-error my-2 text-xs xl:text-sm">
      <MaterialIcon className="text-base" name="info" variant="fill" />
      قبل از زدن دکمه پرداخت، از خاموش بودن VPN خود مطمئن شوید.
    </p>
  </div>
);

export const HotelDraftPrepaymentRegulationAccept = memo(
  Component,
  deepCompare
);
