import { useTranslation } from "@website/locale";
import { Button, Modal } from "@website/ui";
import { dateFormat, deepCompare, numberSeparator } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { useHotelreservecardCancellationModal } from "./hotel-reserve-card-cancellation-modal.hook";
import { HotelReserveCardCancellationModalType } from "./hotel-reserve-card-cancellation-modal.types";

const Component: FC<HotelReserveCardCancellationModalType> = (properties) => {
  const router = useRouter();
  const { title, buttonProps, modalProps } =
    useHotelreservecardCancellationModal(properties);
  const { t } = useTranslation();
  const { cancellationPolicies } = properties;

  return (
    <Modal {...modalProps}>
      <div className="lg:w-[400px]">
        <div className="flex">
          <h3 className="text-headline-6">{title}</h3>
        </div>
        <div className="my-6 w-full rounded-lg border">
          <table>
            <thead className="bg-dim-background">
              <tr>
                <th
                  className="text-body-2 border px-4 py-3 text-left rtl:text-right"
                  scope="col"
                >
                  {t("hotel.room.cancellation_time")}
                </th>
                <th className="text-body-2 border px-4 py-3" scope="col">
                  {t("hotel.room.cancellation_cost")}
                </th>
              </tr>
            </thead>
            <tbody>
              {cancellationPolicies &&
                cancellationPolicies?.length &&
                cancellationPolicies.map((item) => (
                  <tr key={`${item.from}_${item.amount}`}>
                    <td className="text-caption border p-4 text-left rtl:text-right">
                      {item?.from &&
                        dateFormat(item.from, {
                          locale: router.locale,
                          template: "DDD"
                        })}
                    </td>
                    <td className="text-caption border p-4 text-center rtl:text-right">
                      {t("global.price_with_tooman_currency", {
                        price:
                          Boolean(item?.amount) &&
                          numberSeparator(Math.trunc(item.amount / 10))
                      })}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Button {...buttonProps} />
      </div>
    </Modal>
  );
};

export const HotelReserveCardCancellationModal = memo(Component, deepCompare);
