import { useTranslation } from "@website/locale";
import { HotelUpdateSearchModalType } from "./hotel-update-search-modal.types";

export const useSearchUpdateModal = (
  properties: HotelUpdateSearchModalType
) => {
  const { onUpdateResult, isOpen } = properties;
  const { t } = useTranslation();
  return {
    modalProps: {
      open: isOpen,
      onClose: () => undefined,
      showCloseButton: false,
      mobileHeight: "auto" as const,
      animate: "bottom" as const
    },
    message: t("search.search_update_message"),
    title: t("search.search_update"),
    buttonProps: {
      size: "large" as const,
      children: t("search.search_update"),
      color: "secondary" as const,
      fullWidth: true,
      onClick: onUpdateResult
    }
  };
};
