import dynamic from "next/dynamic";

export * from "./hotel-booking-step-capacity.types";
export * from "./hotel-non-instant-booking/hotel-non-instant-booking.types";

export const HotelBookingStepCapacity = dynamic(() =>
  import("./hotel-booking-step-capacity").then(
    ({ HotelBookingStepCapacity }) => HotelBookingStepCapacity
  )
);
