import { MaterialIcon } from "@website/icons";
import { Button, Dialog } from "@website/ui";
import { deepCompare } from "@website/utils";
import { memo } from "react";
import { HotelDraftCardFooterDialogPropertiesTypes } from "./hotel-draft-card-footer-dialog.types";

const Component = ({
  open,
  onClose,
  submit,
  loading
}: HotelDraftCardFooterDialogPropertiesTypes) => (
  <Dialog onClose={onClose} open={open} title="پرداخت اقساطی">
    <div>
      <p>
        با این روش، در صورت فعال بودن اعتبار اقساطی اسنپ، شما می‌توانید مبلغ
        رزرو را در ۴ قسط پرداخت کنید. جزئیات اقساط در مرحله‌ی پرداخت اسنپ‌پی
        مشخص خواهد شد.
      </p>
      <p>
        <MaterialIcon
          className="text-headline-6 mt-[-2px] w-6"
          name="info"
          variant="outlined"
        />
        کنسلی و تغییر رزرو در صورت انتخاب این روش امکان‌پذیر نیست.
      </p>
      <p>
        <MaterialIcon
          className="text-headline-6 mt-[-2px] w-6"
          name="info"
          variant="outlined"
        />
        در پرداخت قسطی امکان استفاده از کد تخفیف وجود ندارد.
      </p>
    </div>
    {/* Todo ----> confirm-button-onclick */}
    <div className="flex items-center gap-4 pt-4">
      <Button
        children="انصراف"
        color="neutral"
        fullWidth
        onClick={onClose}
        size="large"
        variant="text"
      />
      <Button
        children="تایید و پرداخت"
        color="primary"
        fullWidth
        loading={loading}
        onClick={submit}
        size="large"
        variant="contained"
      />
    </div>
  </Dialog>
);
export const HotelDraftCardFooterDialog = memo(Component, deepCompare);
