import { useTranslation } from "@website/locale";
import { DatepickerSelectedDateType } from "@website/ui";
import { useCallback, useEffect, useState } from "react";
import { HotelSearchPropertiesType } from "../../hotel-search.types";

export const useValidation = ({
  selectedDestinationItem,
  date,
  initialDestinationItem,
  searchType
}: {
  selectedDestinationItem: any;
  date: DatepickerSelectedDateType;
  initialDestinationItem?: any;
} & Pick<HotelSearchPropertiesType, "searchType">) => {
  const [destinationError, setDestinationError] = useState<string>("");
  const [dateError, setDateError] = useState<string>("");
  const { t } = useTranslation();

  useEffect(() => {
    setDestinationError("");
  }, [selectedDestinationItem]);

  useEffect(() => {
    setDateError("");
  }, [date]);

  const submitValidationCheck = useCallback(() => {
    let hasError = false;

    if (
      !(selectedDestinationItem || initialDestinationItem) &&
      searchType !== "hotel"
    ) {
      setDestinationError(t("search.destination_validation"));
      hasError = true;
    }

    if (!date || !date[0] || !date[1]) {
      setDateError(t("search.date_validation"));
      hasError = true;
    }

    return !hasError;
  }, [date, initialDestinationItem, searchType, selectedDestinationItem, t]);

  return { dateError, destinationError, submitValidationCheck };
};
