import {
  getLocalTimeZone,
  parseDateTime,
  today
} from "@internationalized/date";
import { useLocalStorage } from "@website/hooks";
import { useTranslation } from "@website/locale";
import { USER_TRACKING_EVENTS, addEventToTracking } from "@website/tracking";
import { diffDateDays, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { convertDate, stringOfNumbers } from "../../../utils";
import { HOTEL_SEARCH_HISTORY_LOCAL_STORAGE_KEY } from "../hotel.constant";

export const useSearchHistory = () => {
  const [history, setHistory] = useState([]);
  const { getFromLocalStorage, addToLocalStorage } = useLocalStorage();
  const { t } = useTranslation();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const router = useRouter();

  useEffect(() => {
    const historyString = getFromLocalStorage(
      HOTEL_SEARCH_HISTORY_LOCAL_STORAGE_KEY
    );
    const history = historyString ? JSON.parse(historyString) : [];

    // remove outdated histories
    const historyWithoutOutdatedItems = history.filter((item) => {
      const startDateObject = parseDateTime(item.startDateTime);
      const todayDateObject = today(getLocalTimeZone());

      if (startDateObject.compare(todayDateObject) < 0) {
        return false;
      }
      return true;
    });

    if (history.length !== historyWithoutOutdatedItems.length) {
      addToLocalStorage(
        HOTEL_SEARCH_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(historyWithoutOutdatedItems)
      );
    }

    // prepare histories
    const preparedHistory = historyWithoutOutdatedItems.map((item) => {
      const title =
        "accommodation_type_title" in item.destination
          ? `هتل ${item?.destination?.fa_title} ${item?.destination?.fa_city_title}`
          : `هتل‌های ${item?.destination?.fa_title}`;

      const startDateObject = parseDateTime(item?.startDateTime);
      const endDateObject = parseDateTime(item?.endDateTime);

      const startDate = `${startDateObject?.year}-${startDateObject?.month
        .toString()
        .padStart(2, "0")}-${startDateObject?.day.toString().padStart(2, "0")}`;
      const endDate = `${endDateObject.year}-${endDateObject.month
        .toString()
        .padStart(2, "0")}-${endDateObject.day.toString().padStart(2, "0")}`;

      const startDateObjectLocale = convertDate(
        {
          dateTime: item?.startDateTime,
          year: startDateObject?.year,
          month: startDateObject?.month,
          day: startDateObject?.day
        },
        item?.calendarLocale
      );
      const endDateObjectLocale = convertDate(
        {
          dateTime: item?.endDateTime,
          year: endDateObject?.year,
          month: endDateObject?.month,
          day: endDateObject?.day
        },
        item.calendarLocale
      );

      const startDateText = `${startDateObjectLocale?.day
        .toString()
        .padStart(2, "0")} ${t<string>(
        `calendar.${item?.calendarLocale}.monthNames.${
          stringOfNumbers[startDateObjectLocale?.month]
        }`
      )}`;
      const endDateText = `${endDateObjectLocale?.day
        .toString()
        .padStart(2, "0")} ${t<string>(
        `calendar.${item?.calendarLocale}.monthNames.${
          stringOfNumbers[endDateObjectLocale?.month]
        }`
      )}`;

      const description = numberConvert(`${startDateText} - ${endDateText}`, {
        locale: "fa"
      });

      let url = "";
      const isCity = !("accommodation_type_title" in item.destination);
      if (isCity) {
        const parameters = {
          date_from: startDate,
          date_to: endDate,
          city_name: item?.destination?.fa_title,
          // adults: `${item?.passengerNumber?.adult}`,
          // children: `${item?.passengerNumber?.child}`,
          // no_rooms: `${item?.passengerNumber?.room}`,
          source: "recentSearch",
          token: `${router?.query?.token}`
        };
        url = `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/رزرو-هتل/${item
          ?.destination?.fa_url}?${new URLSearchParams(parameters).toString()}`;
      } else {
        const parameters = {
          date_from: startDate,
          date_to: endDate,
          // adults: `${item?.passengerNumber?.adult}`,
          // children: `${item?.passengerNumber?.child}`,
          // no_rooms: `${item?.passengerNumber?.room}`,
          source: "recentSearch",
          hotel_name: `${item?.destination?.hotel_name}`,
          token: `${router?.query?.token}`
        };
        url = `${process.env.NEXT_PUBLIC_BASE_URL_B2C}/رزرو-هتل/${item
          ?.destination?.fa_url}?${new URLSearchParams(parameters).toString()}`;
      }
      return {
        title,
        description,
        url
      };
    });

    setHistory(preparedHistory);
  }, [addToLocalStorage, channel, getFromLocalStorage, router, t]);

  const onHistoryClicked = useCallback(
    (url: string) => {
      const searchParameters = new URLSearchParams(new URL(url).search);
      const parametersObject = {} as any;
      for (const [key, value] of searchParameters.entries()) {
        parametersObject[key] = value;
      }

      const today = new Date();
      const searchWindow = diffDateDays(
        today,
        new Date(searchParameters.get("date_from"))
      );
      window?.webengage?.track("hotel-search-clicked", {
        ...parametersObject,
        channel,
        source: "recentSearch"
      });
      let destination = "";
      if (parametersObject?.city_name) {
        destination = parametersObject?.city_name;
      } else if (parametersObject?.hotel_name) {
        destination = parametersObject?.hotel_name;
      } else {
        destination = parametersObject?.fa_url || "";
      }

      addEventToTracking(USER_TRACKING_EVENTS.GENERAL_SEARCH, {
        product: "dom-hotel",
        city: destination,
        date_from: parametersObject.date_from,
        date_to: parametersObject.date_to
      });

      window?.dataLayer?.push({
        event: "hotel-search",
        ecommerce: {
          items: [
            {
              ...parametersObject,
              source: "recentSearch",
              destination,
              searchWindow
            }
          ]
        }
      });
    },
    [channel]
  );

  return {
    searchHistory: history,
    onHistoryClicked
  };
};
