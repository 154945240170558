import { Skeleton } from "@website/ui";
import { deepCompare } from "@website/utils";
import { range } from "lodash";
import { FC, memo } from "react";
import { HotelCardSkeletonPropertiesType } from "./hotel-card-skeleton.types";

const HotelCardSkeletonDivider: FC<HotelCardSkeletonPropertiesType> = memo(
  ({ size = "responsive" }) => (
    <div
      className={
        size === "small"
          ? "bg-outline-thin h-px w-full"
          : "bg-outline-thin h-px w-full lg:h-full lg:w-px"
      }
    />
  )
);

const Component: FC<HotelCardSkeletonPropertiesType> = ({ size }) => (
  <div
    className={
      size === "small"
        ? "bg-on-color-high-emphasis flex w-full flex-col overflow-hidden rounded-xl"
        : "bg-on-color-high-emphasis flex w-full flex-col overflow-hidden rounded-xl lg:min-h-[234px] lg:flex-row"
    }
  >
    <div
      className={
        size === "small"
          ? "flex h-[160px] shrink-0"
          : "flex h-[160px] shrink-0 lg:h-auto lg:w-[220px] xl:w-[312px]"
      }
    >
      <Skeleton shape="rectangle" />
    </div>
    <div className="flex min-w-0 shrink grow flex-col">
      <div
        className={
          size === "small"
            ? "flex h-full flex-col"
            : "flex h-full flex-col lg:flex-row"
        }
      >
        <div
          className={
            size === "small"
              ? "flex min-w-0 shrink grow flex-col gap-3 px-4 py-3"
              : "flex min-w-0 shrink grow flex-col gap-3 px-4 py-3 lg:py-4"
          }
        >
          <div className="h-4 w-full max-w-[262px]">
            <Skeleton shape="rectangle" />
          </div>
          <div className="flex h-4 gap-1">
            {range(5).map((indexKey) => (
              <div className="size-3" key={indexKey}>
                <Skeleton shape="circle" />
              </div>
            ))}
          </div>
          <div className="h-4 w-full max-w-[144px]">
            <Skeleton shape="rectangle" />
          </div>
          <div className="h-4 w-full max-w-[216px]">
            <Skeleton shape="rectangle" />
          </div>
          <div className="h-4 w-full max-w-[64px]">
            <Skeleton shape="rectangle" />
          </div>
        </div>
        <HotelCardSkeletonDivider size={size} />
        <div
          className={
            size === "small"
              ? "flex h-[64px] shrink-0 flex-row items-center justify-between gap-6 px-4 py-2"
              : "flex h-[64px] shrink-0 flex-row items-center justify-between gap-6 px-4 py-2 lg:h-full lg:min-w-[176px] lg:flex-col lg:items-center lg:justify-end lg:py-4"
          }
        >
          <div
            className={
              size === "small"
                ? "flex grow flex-col gap-1"
                : "flex grow flex-col gap-1 lg:w-full lg:grow-0 lg:items-center lg:gap-2"
            }
          >
            <div className="h-3 w-[80px]">
              <Skeleton shape="rectangle" />
            </div>
            <div className="h-4 w-full">
              <Skeleton shape="rectangle" />
            </div>
          </div>
          <div className="flex justify-center gap-1">
            <div
              className={
                size === "small" ? "h-8 w-[89px]" : "h-8 w-[89px] lg:h-6"
              }
            >
              <Skeleton shape="rectangle" />
            </div>
            <div
              className={
                size === "small" ? "h-8 w-[33px]" : "h-8 w-[33px] lg:h-6"
              }
            >
              <Skeleton shape="rectangle" />
            </div>
          </div>
          <div
            className={
              size === "small"
                ? "hidden h-9 w-full"
                : "hidden h-9 w-full lg:block"
            }
          >
            <Skeleton shape="rectangle" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const HotelCardSkeleton = memo(Component, deepCompare);
