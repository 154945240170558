import { uniqBy } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { HotelCitiesMainContentPropertiesType } from "./hotel-cities-main-content.types";

export const useHotelCitiesMainContent = (
  properties: HotelCitiesMainContentPropertiesType
) => {
  const {
    hotelList,
    loading,
    hasError,
    hotelsSuggested,
    hasFilters,
    clearAllFilters,
    setIsSearchPanelOpen
  } = properties;
  const router = useRouter();

  const handleNotFoundAction = useCallback(() => {
    if (hasFilters) {
      clearAllFilters?.();
    } else {
      setIsSearchPanelOpen(true);
    }
  }, [hasFilters, clearAllFilters, setIsSearchPanelOpen]);

  const suggestionOrder = useMemo(
    () => Math.min(Math.max(hotelList?.length - 1, 1), 3),
    [hotelList]
  );

  return {
    suggestionOrder,
    hotelList: uniqBy(hotelList, "id"),
    loading,
    hasNoResults: !loading && hotelList?.length === 0,
    hasError,
    hasSort:
      router.query?.["order_by"] && router.query?.["order_by"] !== "selling",
    hasFilters,
    hasHotelSuggestion: hotelsSuggested?.length !== 0,
    handleNotFoundAction
  };
};
