import { Button, MiniPopover, Radio } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { useHotelSortView } from "./hotel-sort-view.hook";
import { HotelSortViewPropertiesType } from "./hotel-sort-view.types";
const Component: FC<HotelSortViewPropertiesType> = (properties) => {
  const { buttonProps, popoverProps, radioProps } =
    useHotelSortView(properties);
  return (
    <div className="relative">
      <Button {...buttonProps} />
      <MiniPopover {...popoverProps}>
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="flex flex-1 overflow-hidden px-3.5">
            <div>
              {radioProps.list.map((item) => (
                <Radio
                  key={item.label}
                  {...item}
                  checked={radioProps.checkedRadio === item.value}
                  className="my-3 text-sm"
                  color="secondary"
                  name="radiobutton"
                  onChange={radioProps.onChangeRadio}
                />
              ))}
            </div>
          </div>
        </div>
      </MiniPopover>
    </div>
  );
};

export const HotelSortView = memo(Component, deepCompare);
