import { MaterialIcon } from "@website/icons";
import { deepCompare } from "@website/utils";
import { memo } from "react";
import { hotelSnappayPropertiesType } from "./hotel-snapp-pay.types";
const Component = ({ isEligible }: hotelSnappayPropertiesType) =>
  isEligible ? (
    <div className="border-ventures-fin-tech w-full rounded-lg border p-4 text-white">
      <div className="flex flex-row items-center gap-2">
        <MaterialIcon className="text-ventures-fin-tech" name="check_circle" />
        <div className="text-body-2 text-ventures-fin-tech">
          پرداخت قسطی با اسنپ‌پی برای شما فعال است.
        </div>
      </div>
    </div>
  ) : null;

export const HotelSnappPay = memo(Component, deepCompare);
