import { useTranslation } from "@website/locale";
import { numberConvert } from "@website/utils";
import { toNumber } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { RoomListType } from "../../hotel-search-passenger-count";
import { OccupantsInitialValuesType } from "../hotel-search-panel.types";

export const usePassengerCount = (validCount: {
  maxAdult: number;
  maxChild: number;
  maxRoom: number;
}) => {
  const router = useRouter();

  const [temporaryPassengerCount, setTemporaryPassengerCount] =
    useState<OccupantsInitialValuesType>(
      (router.query?.occupants as string)?.split("_")?.map((item) => ({
        adult: toNumber(item.split("-")[0]),
        child:
          item
            .split("-")[1]
            ?.split(",")
            .map((age) => toNumber(age)) || []
      })) || [{ adult: 1, child: [] }]
    );

  const [passengerCount, setPassengerCount] =
    useState<OccupantsInitialValuesType>(
      (router.query?.occupants as string)?.split("_")?.map((item) => ({
        adult: toNumber(item.split("-")[0]),
        child:
          item
            .split("-")[1]
            ?.split(",")
            .map((age) => toNumber(age)) || []
      })) || [{ adult: 1, child: [] }]
    );

  const { t } = useTranslation();

  const onSubmitPassengerCount = useCallback(() => {
    setPassengerCount(temporaryPassengerCount);
  }, [temporaryPassengerCount]);

  const onChangeAdult = useCallback((value: number, roomIndex: number) => {
    setTemporaryPassengerCount((previous) => {
      const roomList = [...previous];
      roomList[roomIndex].adult = value;
      return roomList;
    });
  }, []);

  const onChangeChild = useCallback((value: number, roomIndex: number) => {
    setTemporaryPassengerCount((previous) => {
      const roomList = [...previous];
      if (roomList[roomIndex]?.child) {
        roomList[roomIndex].child = Array.from({ length: value }, (_, index) =>
          index === value - 1 && value > roomList[roomIndex].child.length
            ? -1
            : roomList[roomIndex].child[index]
        );
      }
      return roomList;
    });
  }, []);

  const onChangeChildAge = useCallback(
    (value: number, roomIndex: number, childIndex: number) => {
      setTemporaryPassengerCount((previous) => {
        const roomList = [...previous];
        roomList[roomIndex].child[childIndex] = value;
        return roomList;
      });
    },
    []
  );

  const onAddRoom = useCallback(
    () =>
      setTemporaryPassengerCount((previous) => [
        ...previous,
        { adult: 1, child: [] }
      ]),
    []
  );

  const onRemoveRoom = useCallback(
    (roomIndex: number) =>
      setTemporaryPassengerCount((previous) =>
        previous.filter((_, index) => index !== roomIndex)
      ),
    []
  );
  const list: Array<RoomListType> = temporaryPassengerCount.map(
    (room, index) => ({
      id: index + 1,
      title: t("search.room"),
      items: [
        {
          increaseDisabled: room.adult >= validCount.maxAdult,
          decreaseDisabled: room.adult <= 1,
          title: t("search.number_of_adult"),
          subtitle: t("search.greather_than_n_years", {
            age: 12
          }),
          value: room.adult,
          isAdult: true,
          onChange: onChangeAdult
        },
        {
          increaseDisabled: room.child.length >= validCount.maxChild,
          decreaseDisabled: room.child.length <= 0,
          title: t("search.number_of_child"),
          subtitle: t("search.to_n_years", {
            age: 12
          }),
          value: room.child.length,
          isAdult: false,
          error: room.child.includes(-1),
          onChange: onChangeChild
        }
      ]
    })
  );
  return {
    passengerCountTitle: t("global.passengers_and_rooms_count_label"),
    passengerCountResult: t("global.passengers_and_rooms_count", {
      passengersCount: numberConvert(
        passengerCount?.reduce(
          (total, item) => (total += item.adult + item.child.length),
          0
        ),
        { locale: router.locale }
      ),
      roomsCount: numberConvert(passengerCount.length, {
        locale: router.locale
      })
    }),
    temporaryPassengerCount,
    passengerCountList: list,
    passengerCount,
    onChangeAdult,
    onChangeChild,
    onChangeChildAge,
    onAddRoom,
    onRemoveRoom,
    onSubmitPassengerCount
  };
};
