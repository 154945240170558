import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { MiniChips } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { HotelReservedCardContentPropertiesType } from "./hotel-reserved-bundle-content.types";

const Component = (properties: HotelReservedCardContentPropertiesType) => {
  const { description, rooms, boardType } = properties;
  const router = useRouter();
  const { t } = useTranslation();

  const roomPrefix: Record<number, string> = {
    1: t("hotel.room.first_room"),
    2: t("hotel.room.second_room"),
    3: t("hotel.room.third_room"),
    4: t("hotel.room.fourth_room"),
    5: t("hotel.room.fifth_room"),
    6: t("hotel.room.sixth_room"),
    7: t("hotel.room.seventh_room"),
    8: t("hotel.room.eighth_room"),
    9: t("hotel.room.ninth_room"),
    10: t("hotel.room.tenth_room")
  };
  return (
    <div className="flex flex-col">
      {rooms.map((room, index) => (
        <div className="flex flex-col gap-4 p-4" key={room.id}>
          <div className="text-on-surface-high-emphasis text-subtitle-2 lg:text-subtitle-1 flex gap-2 lg:flex-row lg:items-center">
            {rooms.length > 1 && <span>{roomPrefix[index + 1]} -</span>}
            <h3 className="text-on-surface-high-emphasis text-subtitle-2 lg:text-subtitle-1">
              {room.title}
            </h3>
          </div>
          <div className="flex w-full flex-row flex-wrap gap-1">
            <MiniChips
              StartIcon={<MaterialIcon name="person" />}
              text={`${numberConvert(room.adults, { locale: router.locale })}
            بزرگسال
             ${
               room.children > 0
                 ? `،    ${numberConvert(room.children, {
                     locale: router.locale
                   })} کودک  `
                 : ""
             }`}
              variant="accent"
            />
            {Boolean(boardType) && (
              <MiniChips text={boardType || ""} variant="accent" />
            )}
          </div>
          {Boolean(description) && (
            <span className="text-caption text-on-surface-medium-emphasis flex items-center gap-2 text-justify">
              <MaterialIcon className="text-lg" name="bed" variant="outlined" />
              {numberConvert(description, { locale: router.locale })}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};
export const HotelReserveBundleContent = memo(Component, deepCompare);
