import { useTranslation } from "@website/locale";
import { HotelSearchNoResultPropertiesType } from "./hotel-search-no-result.types";

export const useHotelSearchNoResult = (
  properties: HotelSearchNoResultPropertiesType
) => {
  const { query } = properties;
  const { t } = useTranslation();
  return {
    resultTitle: t("search.search_result"),
    notFoundtitle: t("search.not_found_result"),
    firstDescription: (
      //TODO
      <>
        نتیجه‌ا‌ی برای ”
        <span className="text-on-surface-high-emphasis">{query}</span>” پیدا
        نکردیم.
      </>
    ),
    secondDescription: t("search.search_with_another_word")
  };
};
