import { Stepper } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelDraftCard } from "../hotel-draft-card";
import { HotelUpdateSearchModal } from "../hotel-update-search-modal";
import { HotelBookingStepPaymentPropertiesType } from "./hotel-booking-step-payment.types";
import { HotelDraftPrepayment } from "./hotel-draft-prepayment";
import { HotelDraftPrepaymentRegulationAccept } from "./hotel-draft-prepayment-regulation-accept";

const Component: FC<HotelBookingStepPaymentPropertiesType> = ({
  steps,
  hotelDraftCardProps,
  hotelDraftPrepaymentProps,
  hotelUpdateSearchModalProps
}) => (
  <>
    <Stepper
      activeStep={1}
      previousButton={
        !["android", "ios", "jek"].includes(
          process.env.NEXT_PUBLIC_CHANNEL_TYPE
        )
      }
      steps={steps}
    />
    <div className="relative flex flex-col gap-6 pb-[168px] pt-6 lg:mx-0 lg:px-6 lg:pb-[166px] xl:mx-auto xl:mb-0 xl:flex-row-reverse xl:items-start xl:px-[40px] xl:pb-6 2xl:max-w-[1200px] 2xl:px-0">
      <div className="flex flex-col gap-6 xl:sticky xl:top-[104px] xl:w-[360px]">
        <HotelDraftCard {...hotelDraftCardProps} />
        {process.env.NEXT_PUBLIC_APP_NAME !== "villa" && (
          <div className="hidden xl:block">
            <HotelDraftPrepaymentRegulationAccept />
          </div>
        )}
      </div>
      <div className="flex grow flex-col gap-6">
        <HotelDraftPrepayment {...hotelDraftPrepaymentProps} />
      </div>
    </div>
    {hotelUpdateSearchModalProps && (
      <HotelUpdateSearchModal {...hotelUpdateSearchModalProps} />
    )}
  </>
);

export const HotelBookingStepPayment = memo(Component, deepCompare);
